import React, { useCallback, useRef, useState } from "react";
import { Image, Title, Text, Input, Button, Field, Loader, Icon } from "components/commons";
import { CreateAccountNotifBg, Kangaroo, KangarooSitting } from "images";
import { useForm, useApi, useRouter, useMount } from "hooks";
import { initialState } from "./sign-in-form.state";
import { signIn, verifyEmail, getVenues } from "apis";
import { redirectTo } from "services";
import lang from "translations";
import Validation from "services/validation.service";
import { Path } from "paths";
import { notification } from "antd";
import { StyleType } from "enums";
import { environment } from "environments/environment";
import { mixpanel, TrackEvent } from "mixpanel";
import { reloadPage } from "services";
import { Toast } from "components/commons";

const SignIn = ({ ...props }) => {
  const {
    location: { state = {} },
  } = props;
  const destinationUrl = state?.next;

  const passwordRef = useRef();
  const [verified, setVerified] = useState();
  const [checkEmail, setCheckEmail] = useState();
  const { fields, modifyField, applyFieldErrors, clearForm, submitForm } = useForm({
    initialState,
  });
  const { query, history } = useRouter();
  const { accessToken } = query || {};
  const { email, password } = fields;
  const [redirecting, setRedirecting] = useState(false);

  const { request: verifyEmailRequest, loading: verifyingEmail } = useApi({
    api: verifyEmail,
    handleOwnError: {
      badrequest: {
        2003: () => {
          applyFieldErrors({
            email: lang.emailDoesntHaveRecord,
          });
          openCreateAccountNotification("bottomLeft");
        },
        3037: () => {
          setCheckEmail(true);
        },
        3066: () => {
          applyFieldErrors({
            email: lang.accountCantSeemToFind,
          });
          openCreateAccountNotification("bottomLeft");
        },
      },
    },
  });

  const { request: signInRequest, loading: signingIn } = useApi({
    api: signIn,
    handleOwnError: {
      unauthorized: {
        1002: () => {
          applyFieldErrors({
            password: lang.enteredPasswordIncorrect,
          });
        },
      },
    },
  });

  useMount(() => {
    handleRedirectToDashboard();
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.signIn,
    });
  });

  const handleVerifyEmail = useCallback(async () => {
    await verifyEmailRequest({ email: fields.email.value });
    await setVerified(true);

    modifyField("password", { validations: [Validation.required()] });
    passwordRef.current.focus();
    // window.location.href = `${
    //   environment.POUCH_ID
    // }auth?email=${fields.email.value.trim()}&type=VENUE`;
  }, [verifyEmailRequest, fields.email.value, modifyField]);

  const verifyEmailCb = useCallback(() => {
    submitForm(handleVerifyEmail);
    !email.error
      ? notification.close("create-acc-notif")
      : openCreateAccountNotification("bottomLeft");
  }, [handleVerifyEmail, submitForm, email.error]);

  const clearEmailCb = useCallback(() => {
    clearForm();
    setVerified(false);
    modifyField("password", { value: "", validations: [] });
    openCreateAccountNotification("bottomLeft");
  }, [clearForm, modifyField]);

  const { request: getVenuesRequest } = useApi({ api: getVenues });

  const handleRedirectToDashboard = useCallback(
    async (ac) => {
      if (accessToken || ac) {
        localStorage.setItem("accessToken", accessToken || ac);
        const venues = await getVenuesRequest();
        if (state && state.next && state.venue) {
          const venueId = state.venue?.slice(state.venue.indexOf("=") + 1, state.venue.length);

          localStorage.setItem("venueId", venueId);
          const filteredVenue = venues.filter((venue) => venue.venueId === parseInt(venueId));
          if (!filteredVenue && filteredVenue?.length === 0) {
            Toast({
              content: lang.theLinkYouAreTryingToAccess,
              error: true,
              onClose: () => {
                localStorage.clear();
                reloadPage();
              },
            }).open();
          } else if (destinationUrl) {
            return history.push(destinationUrl);
          }
        } else {
          redirectTo(Path.VENUE_SELECT);
        }
      }
    },
    [accessToken, destinationUrl, history, getVenuesRequest, state]
  );

  const handleSignIn = useCallback(async () => {
    const res = await signInRequest({ email: email.value, password: password.value });
    setRedirecting(true);
    handleRedirectToDashboard(res?.accessToken);
    mixpanel.track(TrackEvent.ClickedButton, {
      Button: lang.signInButton,
      Page: lang.signIn,
    });
  }, [email.value, password.value, signInRequest, handleRedirectToDashboard]);

  const signInCb = useCallback(async () => {
    submitForm(handleSignIn);
  }, [submitForm, handleSignIn]);

  const openCreateAccountNotification = (placement) => {
    const buttons = (
      <>
        <Button
          type={StyleType.Secondary}
          onClick={() => {
            mixpanel.track(TrackEvent.ClickedButton, {
              Button: lang.createAccount,
              Page: lang.signIn,
            });
            let url = new URL(`${environment.POUCH_ACCOUNTS}register`);
            let parameters = new URLSearchParams(url.search.slice(1));
            parameters.append(
              "redirectUrl",
              `${environment.POUCH_VENUES}redirect?redirectUrl=/create/venue`
            );
            parameters.append("clientId", "VENUE_DASHBOARD");
            parameters.append("lang", localStorage.getItem("locale"));
            redirectTo(`${url}?${parameters.toString()}`);
          }}
        >
          {lang.createAccount}
        </Button>
        <Button
          className="mx-md"
          type={StyleType.Ghost}
          onClick={() => {
            mixpanel.track(TrackEvent.ClickedButton, {
              Button: lang.viewPlansAndPricing,
              Page: lang.signIn,
            });
            redirectTo("https://pouchnation.com/pricing/");
          }}
        >
          {lang.viewPlansAndPricing}
        </Button>
      </>
    );

    notification.info({
      message: <Title className="-ml-xl">{lang.newToPouchNation}</Title>,
      description: (
        <div className="-ml-xl">
          <Text className="pb-sm">
            {lang.subscribeAndRegister} <strong className="text-sm">{lang.freeTrial}</strong>
          </Text>
          {buttons}
        </div>
      ),
      key: "create-acc-notif",
      placement,
      className: "-ml-sm",
      duration: 0,
      closeIcon: window.innerWidth <= 640 ? <Icon name="remove" /> : <div></div>,
      style: {
        width: 400,
        // height: 128,
        backgroundImage: `url(${CreateAccountNotifBg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
        borderRadius: 5,
        bottom: 25,
      },
    });
  };

  if (accessToken) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="mt-lg">
      {checkEmail ? (
        <div className="w-full flex flex-col items-center">
          <Image className="mb-md text-center" src={KangarooSitting} />
          <Title className="mb-lg" xl>
            {lang.checkYourEmail}
          </Title>
          <Text>{lang.looksLikeAlreadyInSystem}</Text>
          <lang.Translate
            className="text-sm"
            text={lang.checkYourEmailAt}
            items={[<span className="font-medium text-sm">{fields.email.value}</span>]}
          />
        </div>
      ) : (
        <div
          className="flex flex-col items-center text-center w-11/12 ml-sm sm:w-96 ml-0"
          onLoad={() => openCreateAccountNotification("bottomLeft")}
        >
          <Image className="mb-md" src={Kangaroo} />
          <Title className="mb-lg" xl>
            {verified ? lang.welcomeBack : lang.letsGetStarted}
          </Title>
          {!verified && <Text className="mb-md">{lang.enterEmailToLogin}</Text>}
          <Field className="w-full mb-md" message={email.message}>
            <Input
              center
              {...email}
              hoverClearable={verified}
              readOnly={verified}
              onChange={modifyField}
              onEnter={verifyEmailCb}
              onClear={clearEmailCb}
            />
          </Field>
          {verified && (
            <Field className="w-full mb-md" message={password.message}>
              <Input
                ref={passwordRef}
                center
                {...password}
                inputType="password"
                onChange={modifyField}
                onEnter={signInCb}
              />
            </Field>
          )}
          <Button
            onClick={verified ? signInCb : verifyEmailCb}
            loading={signingIn || redirecting || verifyingEmail}
            disabled={signingIn || redirecting || verifyingEmail}
          >
            {verified ? lang.logIn : lang.getStarted}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SignIn;
