import React, { useMemo } from "react";
import classnames from "classnames";
import { Text, Title, Tooltip } from "..";
import { v4 as uuidv4 } from "uuid";
import { formatNumberWithComma } from "services";

const BarStackChart = ({ className, data = [], renderValue }) => {
  const total = useMemo(() => {
    let total = 0;
    data.forEach((d) => {
      total = d.value + total;
    });
    return total;
  }, [data]);

  const percentage = useMemo(() => {
    return data.map((d) => {
      return (d.value / total) * 100;
    });
  }, [data, total]);

  return (
    <div className={classnames(className)}>
      <Title xl className="my-sm" fontWeight="font-normal">
        {renderValue ? renderValue(total) : total}
      </Title>
      <div className="flex mt-md">
        {data.map((d, index) => {
          return (
            <Tooltip
              key={uuidv4()}
              title={
                <div className="text-center">
                  <Text color="text-white" size="text-xs">
                    {d.label} · {`${formatNumberWithComma(percentage?.[index])}%`}
                  </Text>
                  <Text color="text-white">{renderValue ? renderValue(d.value) : d.value}</Text>
                </div>
              }
            >
              <div
                style={{
                  width: `${percentage?.[index]}%`,
                }}
                className="text-gray hover:text-black"
              >
                <div
                  className="w-full h-4"
                  style={{
                    backgroundColor: d.color,
                  }}
                ></div>
                {/* <div
                  style={{
                    minWidth: "70px",
                    whiteSpace: "nowrap",
                    marginLeft: "0px",
                  }}
                  className="flex mt-md"
                >
                  <div
                    className="h-4 w-4 rounded mr-sm"
                    style={{
                      backgroundColor: d.color,
                    }}
                  ></div>
                  <div className="text-xs">{d.label}</div>
                </div> */}
              </div>
            </Tooltip>
          );
        })}
      </div>
      <div className="flex mb-md">
        {data.map((d, index) => {
          return (
            <div
              key={uuidv4()}
              style={{
                width: `${percentage?.[index]}%`,
                minWidth: "120px",
              }}
              className="text-gray hover:text-black"
            >
              <Tooltip
                title={
                  <div className="text-center">
                    <Text color="text-white" size="text-xs">
                      {d.label} · {`${formatNumberWithComma(percentage?.[index])}%`}
                    </Text>
                    <Text color="text-white">{renderValue ? renderValue(d.value) : d.value}</Text>
                  </div>
                }
              >
                <div
                  style={{
                    minWidth: "120px",
                    whiteSpace: "nowrap",
                    marginLeft: "0px",
                  }}
                  className="flex mt-md"
                >
                  <div
                    className="h-4 w-4 rounded mr-sm"
                    style={{
                      backgroundColor: d.color,
                    }}
                  ></div>
                  <div className="text-xs">{d.label}</div>
                </div>
              </Tooltip>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BarStackChart;
