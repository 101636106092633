import { AccountName, AccountType, Field } from "enums";
import Validation from "services/validation.service";
import lang from "translations";

const initialFormState = (initialState = {}) => {
  const {
    name,
    percentage,
    isDefault = false,
    isMewsTaxCode = false,
    id = 0,
    accountCodeId,
    taxCode,
  } = initialState;

  return {
    name: {
      name: "name",
      value: name,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
      placeholder: lang.taxEg,
    },
    percentage: {
      name: "percentage",
      value: percentage,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
    },
    isDefault: {
      name: "isDefault",
      value: isDefault,
      type: Field.CHECKBOX,
    },
    isMewsTaxCode: {
      name: "isMewsTaxCode",
      value: isMewsTaxCode,
      type: Field.CHECKBOX,
    },
    editable: {
      name: "editable",
      value: true,
      type: Field.CHECKBOX,
    },
    id: {
      name: "id",
      value: id,
      type: Field.CHECKBOX,
    },
    accountCodeId: {
      name: "accountCodeId",
      value: accountCodeId ? parseInt(accountCodeId) : null,
      type: Field.DROPDOWN,
      placeholder: lang.selectAccount,
      accountType: AccountName.TaxOnSalesLiabilityAccount,
      suggested: AccountType.Liability,
    },
    taxCode: {
      name: "taxCode",
      maxLength: 75,
      value: taxCode,
      placeholder: "",
      type: Field.INPUT,
      altMaxLength: true,
    },
  };
};

export default initialFormState;
