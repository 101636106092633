import { getGlobalDateTimeFilter } from "services";

const dateTime = getGlobalDateTimeFilter();

export const stocktakeFilter = (venueId) => ({
  venueId,
  searchKey: "",
  dateRange: [dateTime[0], dateTime[1]],
  page: 1,
  pageSize: 20,
  pageable: true,
  status: [],
  locations: [],
  sort: { key: "updatedDate", value: "desc" },
});
