import { DateTime, GuestStatus, ProductType, TagStatus, TransactionType } from "enums";
import {
  formatDate,
  formatName,
  formatNumberWithComma,
  formatNumberToOrdinal,
  capitalize,
} from "services";
import lang from "translations";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import { dateTimeRequest, filterRequest, selectToObjectRequest } from "./common.mapper";
import { convertUTCDateToLocalDate } from "services/date.service";
import { multiply } from "services/number.service";

export const guestListResponse = {
  guestId: {
    key: "guestId",
  },
  status: {
    transform: ({ src }) => {
      const guestCheckins = src.guestCheckins;
      if (!guestCheckins || guestCheckins.length === 0) {
        return GuestStatus.CHECKED_OUT;
      }
      const { checkoutDateTime, manualCheckoutDate } = guestCheckins[guestCheckins.length - 1];
      return Boolean(checkoutDateTime || manualCheckoutDate)
        ? GuestStatus.CHECKED_OUT
        : GuestStatus.CHECKED_IN;
    },
  },
  name: {
    transform: ({ src }) => {
      return formatName(src.firstName, src.lastName);
    },
  },
  tagUids: {
    transform: ({ src }) => {
      let guestTagUids = [];
      const guestCheckins = src.guestCheckins;

      guestCheckins.forEach((gc) => {
        gc.guestTags.forEach((gt) => {
          guestTagUids.push({ tagUid: gt.tagUid, useCount: formatNumberToOrdinal(gt.useCount) });
        });
      });

      return guestTagUids;
    },
  },
  transactionCount: { transform: ({ self }) => formatNumberWithComma(self) },
  // useCount: { transform: ({ src }) => {
  //   const guestCheckins = src.guestCheckins;
  //   const { checkinDateTime, manualCheckoutDate } = guestCheckins[guestCheckins.length - 1];
  // } },
  checkInDate: {
    transform: ({ src }) => {
      const guestCheckins = src.guestCheckins;
      if (!guestCheckins || guestCheckins.length === 0) {
        return "";
      }
      const { checkinDateTime, manualCheckoutDate } = guestCheckins[guestCheckins.length - 1];
      return formatDate(checkinDateTime || manualCheckoutDate, DateTime.A);
    },
  },
  checkInTime: {
    transform: ({ src }) => {
      const guestCheckins = src.guestCheckins;
      if (!guestCheckins || guestCheckins.length === 0) {
        return "";
      }
      const { checkinDateTime, manualCheckoutDate } = guestCheckins[guestCheckins.length - 1];
      return formatDate(checkinDateTime || manualCheckoutDate, DateTime.B);
    },
  },
  checkOutDate: {
    transform: ({ src }) => {
      const guestCheckins = src.guestCheckins;
      if (!guestCheckins || guestCheckins.length === 0) {
        return "";
      }
      const { checkoutDateTime, manualCheckoutDate } = guestCheckins[guestCheckins.length - 1];
      return formatDate(checkoutDateTime || manualCheckoutDate, DateTime.A);
    },
  },
  checkOutTime: {
    transform: ({ src }) => {
      const guestCheckins = src.guestCheckins;
      if (!guestCheckins || guestCheckins.length === 0) {
        return "";
      }
      const { checkoutDateTime, manualCheckoutDate } = guestCheckins[guestCheckins.length - 1];
      return formatDate(checkoutDateTime || manualCheckoutDate, DateTime.B);
    },
  },
  currentGuestProfiles: {
    transform: ({ src }) => {
      const guestProfiles = new Set();

      const guestCheckins = src.guestCheckins;
      guestCheckins.forEach((gc) => {
        gc.guestTags.forEach((gt) => {
          guestProfiles.add(gt.guestProfileName);
        });
      });

      return [...guestProfiles];
    },
  },
  currentGuestProfileIds: {
    transform: ({ src }) => {
      const guestProfileIds = new Set();

      const guestCheckins = src.guestCheckins;
      guestCheckins.forEach((gc) => {
        gc.guestTags.forEach((gt) => {
          guestProfileIds.add(gt.guestProfileId);
        });
      });

      return [...guestProfileIds];
    },
  },
};

export const guestListFilterRequest = {
  ...filterRequest,
  ...dateTimeRequest,
  pageable: true,
  staffIds: selectToObjectRequest("staffs"),
  guestProfileIds: selectToObjectRequest("guestProfiles"),
  guestTagStatus: { key: "guestTagStatus" },
  creditStatus: { key: "creditStatus" },
};

export const guestWithTotalSpend = {
  name: {
    transform: ({ src }) => {
      return (
        formatName(src.firstName, src.lastName) ||
        `${src.userTagUid} (${lang.populate(lang.nthUse, [
          formatNumberToOrdinal(src.userTagUseCount),
        ])})`
      );
    },
  },
  total: {
    transform: ({ src }) => {
      return formatNumberToMoneyWithCurrencySymbol(src.totalSpend);
    },
  },
};

export const guestProfileResponse = {
  _keys: [
    "firstName",
    "lastName",
    "birthdate",
    "email",
    "gender",
    "phone",
    "guestQuestions",
    "guestProfileName",
    "imageUrl",
    "guestNotes",
    "averageDurationOfStay",
    "mostPurchasedProduct",
    "mostVisitedLocation",
  ],
  name: {
    transform: ({ src }) => {
      if (!src.firstName && !src.lastName) {
        return null;
      }
      return `${src.firstName ?? ""} ${src.lastName ?? ""}`;
    },
  },
  guestSince: {
    transform: ({ src }) => {
      if (src.guestSince) {
        return formatDate(src.guestSince, DateTime.A);
      } else {
        return "N/A";
      }
    },
  },
  totalVisits: {
    transform: ({ src }) => {
      return src.guestCheckinIds?.length;
    },
  },
  guestCheckinIds: {
    transform: ({ src }) => {
      return src.guestCheckinIds.map((guestCheckinId) => ({
        checkinDate: formatDate(guestCheckinId.checkinDate),
        checkinId: guestCheckinId.checkinId,
        checkoutDate: formatDate(guestCheckinId.checkoutDate),
        formattedCheckinId: guestCheckinId.formattedCheckinId,
      }));
    },
  },
  nextGuestId: {
    transform: ({ src }) => {
      return src.prevGuestId;
    },
  },
  prevGuestId: {
    transform: ({ src }) => {
      return src.nextGuestId;
    },
  },
};

export const guestCheckinInfo = {
  _keys: [
    "firstName",
    "lastName",
    "birthdate",
    "email",
    "gender",
    "phone",
    "guestQuestions",
    "guestProfileName",
    "imageUrl",
    "guestNotes",
    "totalTagsIssued",
    "pairedTagOnCheckin",
    "useCountOfPairedTag",
    "currentCheckin",
    "forceCheckOutReason",
    "forceCheckedOutBy",
    "forceCheckedOutByProfileId",
    "forceCheckoutDate",
  ],
  name: {
    transform: ({ src }) => {
      if (!src.firstName && !src.lastName) {
        return null;
      }
      return `${src.firstName ?? ""} ${src.lastName ?? ""}`;
    },
  },
  totalVisits: {
    transform: ({ src }) => {
      return src.guestCheckinIds?.length;
    },
  },
  guestCheckinIds: {
    transform: ({ src }) => {
      return src.guestCheckinIds.map((guestCheckinId) => ({
        checkinDate: formatDate(guestCheckinId.checkinDate),
        checkinId: guestCheckinId.checkinId,
        checkoutDate: formatDate(guestCheckinId.checkoutDate),
        formattedCheckinId: guestCheckinId.formattedCheckinId,
      }));
    },
  },
  guestSince: {
    transform: ({ src }) => {
      if (src.guestSince) {
        return formatDate(src.guestSince, DateTime.A);
      } else {
        return "N/A";
      }
    },
  },
  nextGuestId: {
    transform: ({ src }) => {
      return src.prevGuestId;
    },
  },
  prevGuestId: {
    transform: ({ src }) => {
      return src.nextGuestId;
    },
  },
};

export const guestCheckin = {
  lastSyncedDate: {
    transform: ({ src }) => {
      return src.lastSyncedDate
        ? formatDate(convertUTCDateToLocalDate(src.lastSyncedDate), DateTime.H)
        : "-";
    },
  },
  dateOfCheckin: {
    transform: ({ src }) => {
      return src.dateOfCheckin
        ? formatDate(convertUTCDateToLocalDate(src.dateOfCheckin), DateTime.H)
        : "-";
    },
  },
  dateOfCheckout: {
    transform: ({ src }) => {
      return src.dateOfCheckout
        ? formatDate(convertUTCDateToLocalDate(src.dateOfCheckout), DateTime.H)
        : "-";
    },
  },
  forceCheckoutDate: {
    transform: ({ src }) => {
      return src.forceCheckoutDate ? formatDate(src.forceCheckoutDate, DateTime.H) : "-";
    },
  },
  totalVisits: {
    transform: ({ src }) => {
      var total = 0;
      src.locationsVisited?.forEach((location) => {
        total = location.locationQuantity;
      });
      return total;
    },
  },
  checkedIn: {
    transform: ({ src }) => {
      return src.dateOfCheckout ? false : true;
    },
  },
  guestCheckinId: { key: "guestCheckinId" },
  checkedInBy: {
    transform: ({ src }) => {
      return src.checkedInBy ?? "-";
    },
  },
  checkedOutBy: {
    transform: ({ src }) => {
      return src.checkedOutBy ?? "-";
    },
  },
  deviceName: {
    transform: ({ self }) => {
      return self ?? "-";
    },
  },
  totalSpent: {
    transform: ({ src }) => {
      return src.movementsAndTransactionSummary.totalSpent !== 0
        ? formatNumberToMoneyWithCurrencySymbol(src.movementsAndTransactionSummary.totalSpent)
        : 0;
    },
  },
  averageOrderValue: {
    transform: ({ src }) => {
      return src.movementsAndTransactionSummary.averageOrderValue !== 0
        ? formatNumberToMoneyWithCurrencySymbol(
            src.movementsAndTransactionSummary.averageOrderValue
          )
        : 0;
    },
  },
  lastPurchasedProduct: {
    transform: ({ src }) => {
      const summary = src.movementsAndTransactionSummary;
      if (summary.lastPurchasedProductType === ProductType.Variant) {
        return summary.lastPurchasedProductName + " · " + summary.lastPurchasedProductValue;
      } else {
        return !summary.lastPurchasedProductName ? "-" : summary.lastPurchasedProductName;
      }
    },
  },
  lastVisitedLocation: {
    transform: ({ src }) => {
      return !src.movementsAndTransactionSummary.lastVisitedLocation
        ? "-"
        : src.movementsAndTransactionSummary.lastVisitedLocation;
    },
  },
  totalTagsIssued: { key: "totalTagsIssued" },
  pairedTagOnCheckin: { key: "pairedTagOnCheckin" },
  useCountOfPairedTag: { key: "useCountOfPairedTag" },
  currentGuestTag: {
    transform: ({ src }) => {
      src.currentGuestTag["checkedInBy"] = src.checkedInBy ?? "-";
      src.currentGuestTag["checkedOutBy"] = src.checkedOutBy ?? "-";
      src.currentGuestTag["dateOfCheckout"] = src.dateOfCheckout
        ? formatDate(convertUTCDateToLocalDate(src.dateOfCheckout), DateTime.H)
        : "-";
      return src.currentGuestTag;
    },
  },
  guestProfileName: { key: "guestProfileName" },
  deviceMacAddress: { key: "deviceMacAddress" },
  wristbandKeptTagUid: {
    transform: ({ src }) => {
      return src.wristbandKeptTagUid ?? "-";
    },
  },
  guestTagUids: { key: "guestTagUids" },
  forceCheckOutReason: { key: "forceCheckOutReason" },
  forceCheckedOutBy: { key: "forceCheckedOutBy" },
  bookingNumber: {
    transform: ({ src }) => {
      return src.bookingNumber ?? "-";
    },
  },
  checkinAmount: {
    transform: ({ src }) => {
      return src.checkinAmount === 0 ? "Free" : src.checkinAmount;
    },
  },
};

export const guestTagInfo = {
  useCount: {
    transform: ({ src }) => {
      return src.useCount ? ` ${formatNumberToOrdinal(src.useCount)}` : "-";
    },
  },
  status: {
    transform: ({ src }) => {
      return src.status;
    },
  },
  vouchers: {
    transform: ({ src }) => {
      const guestVouchers = src.vouchers;
      if (
        (src.status === TagStatus.CHECKED_OUT || src.status === TagStatus.FORCED) &&
        !src.isTagBalanceKept
      ) {
        return [];
      } else if (guestVouchers) {
        return guestVouchers.map(({ voucherName, quantity }) => {
          return {
            voucherName,
            quantity,
          };
        });
      }
      return [];
    },
  },
  credit: {
    transform: ({ src }) => {
      if (src.status === TagStatus.CHECKED_OUT && !src.isTagBalanceKept) {
        return 0;
      }

      return src.totalCreditBalance < 0 ? 0 : src.totalCreditBalance;
    },
  },
  freeCredit: {
    transform: ({ src }) => {
      if (
        (src.status === TagStatus.CHECKED_OUT || src.status === TagStatus.FORCED) &&
        !src.isTagBalanceKept
      ) {
        return 0;
      }
      return src.freeCredit ?? 0;
    },
  },
  creditBalance: {
    transform: ({ src }) => {
      let previousBalance = 0;
      let credits = src.totalCreditBalance;
      let freeCredits = src.freeCredit;

      if (src.status === TagStatus.CHECKED_OUT && !src.isTagBalanceKept) {
        return 0;
      }

      if (credits >= 0) {
        previousBalance = credits + freeCredits;
      } else if (credits === 0 && freeCredits === 0 && src.paidPostpaid > 0) {
        previousBalance = multiply(src.paidPostpaid, -1);
      } else if (credits === 0 && freeCredits > 0) {
        previousBalance = freeCredits;
      } else {
        previousBalance = credits;
      }
      return previousBalance;
    },
  },
  postpaidLimit: {
    transform: ({ src }) => {
      return src.postpaidLimit !== 0 ? src.postpaidLimit : 0;
    },
  },
  postpaidAvailable: {
    transform: ({ src }) => {
      if (src.status === TagStatus.CHECKED_OUT || src.status === TagStatus.FORCED) {
        return 0;
      }
      return src.postpaidAvailable !== 0 ? src.postpaidAvailable : 0;
    },
  },
  paidPostpaid: {
    transform: ({ src }) => {
      return src.paidPostpaid !== 0 ? src.paidPostpaid : 0;
    },
  },
  device: {
    transform: ({ src }) => {
      return src.deviceName === null ? "-" : src.deviceName;
    },
  },
  deviceNameCheckout: {
    transform: ({ src }) => {
      return src.checkoutDeviceName ?? "-";
    },
  },
  issuedBy: {
    transform: ({ src }) => {
      return src.issuedBy ?? "-";
    },
  },
  checkedOutBy: {
    transform: ({ src }) => {
      return src.checkedOutBy ?? "-";
    },
  },
  issuedOn: {
    transform: ({ src }) => {
      return src.issuedOn ? formatDate(convertUTCDateToLocalDate(src.issuedOn), DateTime.H) : "-";
    },
  },
  dateOfCheckout: {
    transform: ({ src }) => {
      return src.checkedOutOn
        ? formatDate(convertUTCDateToLocalDate(src.checkedOutOn), DateTime.H)
        : "-";
    },
  },
  banDate: {
    transform: ({ src }) => {
      return src.banDate ? formatDate(src.banDate, DateTime.H) : "-";
    },
  },
  bannedBy: {
    transform: ({ src }) => {
      return src.checkedOutBy ?? "-";
    },
  },
  banReason: {
    transform: ({ src }) => {
      return src.banReason ?? "-";
    },
  },
  forcedCheckedOutBy: {
    transform: ({ src }) => {
      return src.forcedCheckedOutByName ?? "-";
    },
  },
  forcedCheckoutReason: {
    transform: ({ src }) => {
      return src.forcedCheckoutReason;
    },
  },
  tagUid: {
    transform: ({ src }) => {
      return src.tagUid ?? "-";
    },
  },
};

export const guestRecentTransactions = {
  recentTransactions: {
    key: "recentTransactions",
    transform: ({ src }) => {
      const transactions = src.recentTransactions ?? [];
      return transactions.map((transaction) => {
        return {
          transactionId: transaction.transactionId,
          locationName:
            transaction.transactionType === TransactionType.SALE ||
            transaction.transactionType === TransactionType.REDEEM
              ? transaction.locationName
              : lang.noLocation,
          transactionType: transaction.transactionType,
          deviceCreatedDate: formatDate(transaction.deviceCreatedDate, DateTime.I),
          paidAmount: formatNumberToMoneyWithCurrencySymbol(transaction.paidAmount),
        };
      });
    },
  },
};

export const guestNotesResponse = {
  _keys: ["note", "noteId", "notedBy", "staffId"],
  noteUpdatedDate: {
    transform: ({ src }) => {
      return src.noteUpdatedDate ? convertUTCDateToLocalDate(src.noteUpdatedDate) : null;
    },
  },
  noteCreatedDate: {
    transform: ({ src }) => {
      return src.noteCreatedDate ? convertUTCDateToLocalDate(src.noteCreatedDate) : null;
    },
  },
};

export const guestRecentTransactionResponse = {
  _keys: ["transactionId", "type", "transactionStatus", "transactionItems"],
  locationName: {
    transform: ({ src: transaction }) => {
      return transaction.type === TransactionType.SALE ||
        transaction.type === TransactionType.REDEEM ||
        transaction.type === TransactionType.MIXED
        ? transaction.locationName ?? "No Location"
        : "No Location";
    },
  },
  deviceCreatedDate: {
    transform: ({ src: transaction }) => {
      return formatDate(transaction.deviceCreatedDate, DateTime.J);
    },
  },
  paidAmount: {
    transform: ({ src: transaction }) => {
      return formatNumberToMoneyWithCurrencySymbol(transaction.paidAmount);
    },
  },
  tapStatus: { key: "nfcStatus" },
  items: {
    transform: ({ src }) => {
      if (src.paymentMethod && src.type === TransactionType.TOPUP) {
        return [capitalize(src.paymentMethod)];
      }
      if (src?.transactionItems) {
        const items = [
          ...new Map(src?.transactionItems.map((item) => [item["productName"], item])).values(),
        ];
        return items.map((item) => {
          return item.productName;
        });
      }
      return "";
    },
  },
};
export const guestProfile = {};
