import React, { useMemo } from "react";
import { Image, Title, Text, Button, Icon, Preview } from "components/commons/index";
import { Card as AntCard } from "antd";
import classNames from "classnames";
import { useForm, useModal } from "hooks";
import AllowAccessModal from "../integration-modal.js/allow-access-modal";
import ConnectModal from "../integration-modal.js/connect-modal";
import { Field as FieldType } from "enums";
import IntegrationInfoModal from "../integration-modal.js/integration-info-modal";
import { isValidURL } from "services/url.service";
import lang from "translations";
import { Skeleton } from "components";
import { useFlags } from "launchdarkly-react-client-sdk";

const IntegrationCard = ({ data, className, refreshList, fetching = false }) => {
  const {
    name,
    request,
    imageLink,
    type,
    description,
    isIntegrated,
    isConnected = false,
    colors = "",
    authorizationLink,
  } = data || {};

  const allowAccessModal = useModal();
  const connectModal = useModal();
  const integrationInfoModal = useModal();
  const { loading } = data || {};
  const background = colors.split(",")[0] || "#7a7984";
  const { cloudbedsAllowAccessModal, purchaseplusAllowAccessModal } = useFlags();

  const initialState = useMemo(() => {
    return {
      isConnected: {
        name: "isConnected",
        value: isConnected,
        type: FieldType.ANY,
      },
    };
  }, [isConnected]);

  const { fields, modifyField } = useForm({
    initialState,
  });

  const renderConnectionStatus = useMemo(() => {
    if (loading) {
      return <Button className="mt-md" loading />;
    }
    if (isIntegrated) {
      switch (name) {
        case lang.cloudbeds:
          if (fields.isConnected.value) {
            return (
              <div className="flex items-center">
                <div className="flex items-center mr-md">
                  <Icon className="text-green mr-sm" name="check" />
                  <Text fontWeight="font-bold">{lang.connected}</Text>
                </div>
                <button
                  className="px-md py-sm border rounded border-solid text-pelorous bg-white 
                  border-gray-200 transition delay-100 duration-500 text-pelorous font-semibold text-sm 
                  hover:border-pelorous"
                  onClick={() => {
                    integrationInfoModal.show();
                  }}
                >
                  <Text color="text-pelorous" fontWeight="font-semibold">
                    {lang.viewInfo}
                  </Text>
                </button>
              </div>
            );
          } else {
            return cloudbedsAllowAccessModal ? (
              <Button className="mt-md" onClick={() => allowAccessModal.show()}>
                {lang.connect}
              </Button>
            ) : (
              <>
                <Button className="mt-md" onClick={() => window.open(authorizationLink)}>
                {lang.connect}
              </Button>
              </>
            )
          }

        case lang.purchaseplus:
          if (fields.isConnected.value) {
            return (
              <div className="flex items-center">
                <div className="flex items-center mr-md">
                  <Icon className="text-green mr-sm" name="check" />
                  <Text fontWeight="font-bold">{lang.connected}</Text>
                </div>
                <button
                  className="px-md py-sm border rounded border-solid text-pelorous bg-white 
                  border-gray-200 transition delay-100 duration-500 text-pelorous font-semibold text-sm 
                  hover:border-pelorous"
                  onClick={() => {
                    integrationInfoModal.show();
                  }}
                >
                  {lang.viewInfo}
                </button>
              </div>
            );
          } else {
            return purchaseplusAllowAccessModal ? (
              <Button className="mt-md" onClick={() => allowAccessModal.show()}>
                {lang.connect}
              </Button>
            ) : (
              <>
                <Button
                  className="mt-md"
                  onClick={() =>
                    connectModal.show({
                      ...data,
                      title: name,
                      request,
                      name: name,
                    })
                  }
                >
                  {lang.connect}
                </Button>
                <ConnectModal {...connectModal} modifyField={modifyField} />
              </>
            );
          }
        default:
          if (fields.isConnected.value) {
            return (
              <div className="flex items-center">
                <div className="flex items-center mr-md">
                  <Icon className="text-green mr-sm" name="check" />
                  <Text fontWeight="font-bold">{lang.connected}</Text>
                </div>
                <button
                  className="px-md py-sm border rounded border-solid text-pelorous bg-white 
                  border-gray-200 transition delay-100 duration-500 text-pelorous font-semibold text-sm 
                  hover:border-pelorous"
                  onClick={() => {
                    integrationInfoModal.show();
                  }}
                >
                  {lang.viewInfo}
                </button>
              </div>
            );
          } else {
            return (
              <Button className="mt-md" onClick={() => allowAccessModal.show()}>
                {lang.connect}
              </Button>
            );
          }
      }
    }

    return !fetching ? (
      <Text fontWeight="font-semibold" className="text-gray mt-lg">
        {lang.comingSoon}
      </Text>
    ) : (
      <Skeleton single />
    );
  }, [
    loading,
    isIntegrated,
    fetching,
    name,
    cloudbedsAllowAccessModal,
    fields.isConnected.value,
    integrationInfoModal,
    authorizationLink,
    purchaseplusAllowAccessModal,
    connectModal,
    modifyField,
    allowAccessModal,
    data,
    request,
  ]);

  return (
    <>
      <AntCard className="rounded-md">
        <div
          className={classNames(
            "grid grid-cols-5 md:grid-cols-4 gap-4",
            { "h-28": fetching },
            className
          )}
        >
          <div className="col-span-1 md:col-span-1">
            {isValidURL(imageLink) ? (
              <Image src={imageLink} className="max-h-40 max-2-40 rounded-md" />
            ) : !fetching ? (
              <Preview
                image={imageLink}
                fontColor={background || "#7a7984"}
                title={name}
                useInitials
                className="max-h-20 md:max-h-30 md:max-2-30 xl:max-h-40 xl:max-2-40"
              />
            ) : (
              <Skeleton avatar />
            )}
          </div>
          <div className="col-span-4 md:col-span-3">
            <Title>{name}</Title>
            <Text label className="mt-xs mb-sm">
              {type}
            </Text>
            <Text className="mb-md">{description}</Text>
            {renderConnectionStatus}
          </div>
        </div>
      </AntCard>
      <AllowAccessModal
        {...allowAccessModal}
        data={data || {}}
        modifyField={modifyField}
        refreshList={refreshList}
        background={background}
      />
      <IntegrationInfoModal {...integrationInfoModal} data={data || {}} modifyField={modifyField} />
    </>
  );
};

export default IntegrationCard;
