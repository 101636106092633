const bookingPaths = {
  BOOKING: "",
  SEARCH_BOOKING: "search",
  BOOKING_ID: (bookingId) => bookingId,
  BOOKING_SUMMARY: (bookingId) => `${bookingId}/summary`,
  SEARCH_BOOKING_GUESTS: (bookingId) => `${bookingId}/guest`,
  SEARCH_BOOKING_ACCOMMODATION: (bookingId) => `${bookingId}/accommodation`,
};

export default bookingPaths;
