export const environment = {
  id: "production",
  production: true,
  API_V3: "https://gateway.services.pouchnation.com",
  FIREBASE: {
    apiKey: "AIzaSyBwTQaxZOqLXOCDe0Aytj8vw9qPB1UkJVo",
    authDomain: "ms-communication-ae098.firebaseapp.com",
    databaseURL: "https://ms-communication-ae098.firebaseio.com",
    projectId: "ms-communication-ae098",
    storageBucket: "ms-communication-ae098.appspot.com",
    messagingSenderId: "139918626078",
    appId: "1:139918626078:web:c8900ad0dffdb794538d0f",
    measurementId: "G-BG2Z7LL2BK",
  },
  MIXPANEL_TOKEN: "b72fe85559a8cde7546af0e158409301",
  FEATURE_FLAG: {
    clientSideID: "6143dd4da31a22242e7e04ec",
  },
  SENTRY_DSN: "https://136c174163aa48e5865678da5ada8253@o431184.ingest.sentry.io/6147379",
  ACTIVE_MQ_HOST:
    "wss://b-02a26402-ef02-4539-8267-bd7552996a10-1.mq.ap-southeast-1.amazonaws.com:61619",
  ACTIVE_MQ_USER: "pnprod",
  ACTIVE_MQ_PW: "pouchprod123!Xd1jd",
  POUCH_VENUES: "https://test.venue.pouchnation.com/",
  POUCH_ACCOUNTS: "https://accounts.pouchnation.com/",
  PABBLY_SUBSCRIPTION_WITH_FREE_TRIAL:
    "https://subscribe.pouchnation.com/checkout/61a6fd859acd507d7e486302",
  PABBLY_SUBSCRIPTION_WITHOUT_FREE_TRIAL:
    "https://subscribe.pouchnation.com/checkout/609a60d32b44794799f33910",
  MEWS_URL: "https://www.mews.li/Commander/Home/SignIn",
  CONNECTOR_URL: "https://connect.pouchnation.com",
  CLOUDBEDS_CONNECT_URL:
    "https://hotels.cloudbeds.com/api/v1.1/oauth?client_id=pouch_nation&redirect_uri=https%3A%2F%2Fvenue.pouchnation.com%2Fsetting%2Fintegration&response_type=code&scope=read%3Aadjustment+write%3Aadjustment+delete%3Aadjustment+read%3Aitem+write%3Aitem+delete%3Aitem+read%3Aguest+write%3Aguest+read%3Ahotel+read%3AhouseAccount+write%3AhouseAccount+read%3Apayment+write%3Apayment+read%3Areservation+write%3Areservation+read%3Aroom+read%3AtaxesAndFees+read%3Auser",
};
