import React from "react";
// import React, { useMemo } from "react";
import ChartContainer from "../chart-container";
import { BarStackChart, Line, SimpleTable, Text } from "components/commons";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import lang from "translations";

const TotalSalesChart = ({ ...props }) => {
  const data = props.mappedData;
  const barStackChart = props.mappedData.barChartData;
  return (
    <ChartContainer title={lang.totalSales} 
    // {...props} empty={!data.sales} 
    // emptyText={lang.noSalesInDateRange}
    >
      <BarStackChart
        data={barStackChart}
        renderValue={(v) => {
          return formatNumberToMoneyWithCurrencySymbol(v);
        }}
      />
      <SimpleTable
        className="mt-md"
        showHeader={false}
        columns={[{ key: "text" }, { key: "value", className: "text-right md" }]}
        data={[lang.grossProfit].map((text) => {
          return {
            text: <Text color="text-gray-light">{text}</Text>,
            value: <Text color="font-bold">{data.grossProfit ? formatNumberToMoneyWithCurrencySymbol(data.grossProfit) : 0}</Text>,
          };
        })}
      />
      <Line></Line>
      <SimpleTable
        className="mt-md"
        showHeader={false}
        columns={[{ key: "text" }, { key: "value", className: "text-right md" }]}
        data={[lang.supplyCost].map((text) => {
          return {
            text: <Text color="text-gray-light">{text}</Text>,
            value: <Text color="font-bold">{data.supplyPrice ? formatNumberToMoneyWithCurrencySymbol(data.supplyPrice) : 0}</Text>,
          };
        })}
      />
      <SimpleTable
        className="mt-md"
        showHeader={false}
        columns={[{ key: "text" }, { key: "value", className: "text-right md" }]}
        data={[lang.taxes].map((text) => {
          return {
            text: <Text color="text-gray-light">{text}</Text>,
            value: <Text color="font-bold">{data.taxes ? formatNumberToMoneyWithCurrencySymbol(data.taxes) : 0}</Text>,
          };
        })}
      />
      <SimpleTable
        className="mt-md"
        showHeader={false}
        columns={[{ key: "text" }, { key: "value", className: "text-right md" }]}
        data={[lang.discounts].map((text) => {
          return {
            text: <Text color="text-gray-light">{text}</Text>,
            value: <Text color="font-bold">{data.discounts ? formatNumberToMoneyWithCurrencySymbol(data.discounts) : 0}</Text>,
          };
        })}
      />
      <Line></Line>
      <SimpleTable
        className="mt-md"
        showHeader={false}
        columns={[{ key: "text" }, { key: "value", className: "text-right md" }]}
        data={[lang.voidedItems].map((text) => {
          return {
            text: <Text color="text-gray-light">{text}</Text>,
            value: <Text color="font-bold">{data.voidedItems ? data.voidedItems : 0}</Text>,
          };
        })}
      />
      <SimpleTable
        className="mt-md"
        showHeader={false}
        columns={[{ key: "text" }, { key: "value", className: "text-right md" }]}
        data={[lang.voidedValue].map((text) => {
          return {
            text: <Text color="text-gray-light">{text}</Text>,
            value: <Text color="font-bold">{data.voidedValue ? formatNumberToMoneyWithCurrencySymbol(data.voidedValue) : 0}</Text>,
          };
        })}
      />
      <SimpleTable
        className="mt-md"
        showHeader={false}
        columns={[{ key: "text" }, { key: "value", className: "text-right md" }]}
        data={[lang.itemsSold].map((text) => {
          return {
            text: <Text color="text-gray-light">{text}</Text>,
            value: <Text color="font-bold">{data.itemsSold ? data.itemsSold : 0}</Text>,
          };
        })}
      />
    </ChartContainer>
  );
};

export default TotalSalesChart;
