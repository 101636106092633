export const locale = {
  venueDashboard: "Venue Dashboard",
  hiHeresWhatsHappening: "Hi {0}, here's what's happening with {1}.",
  dashboard: "Dashboard",
  selling: "Selling",
  location: "Location",
  locations: "Locations",
  staff: "Staff",
  guests: "Guests",
  voucher: "Voucher",
  vouchers: "Vouchers",
  discounts: "Discounts",
  transaction: "Transaction",
  transactions: "Transactions",
  reports: "Reports",
  device: "Device",
  devices: "Devices",
  settings: "Settings",
  products: "Products",
  productCategories: "Product Categories",
  createNewLocation: "Create New Location",
  createLocation: "Create Location",
  managePlacesYouStock: "Manage places you stock inventory and sell products.",
  basicInfo: "Basic Info",
  product: "Product",
  locationName: "Location Name",
  save: "Save",
  savedChanges: "Saved Changes",
  cancel: "Cancel",
  delete: "Delete",
  discard: "Discard",
  nonRefundable: "Non-Refundable",
  locationNameCreated: "Location “{0}” created.",
  locationNameUpdated: "Location “{0}” updated.",
  locationNameDeleted: "Location “{0}” deleted.",
  locationNameAlreadyExist: "This location already exists. Choose a different name.",
  youHaveUnsaveChanges: "You have unsaved changes on this page.",
  ifYouLeaveThisPage:
    "If you leave this page, all unsaved changes will be lost. Are you sure you want to discard changes?",
  discardChanges: "Discard Changes",
  editLocation: "Edit Location",
  youveReachedTheEndOfTheList: "You’ve reached the end of the list.",
  eitherYouHavePoorConnection:
    "Either you have poor network connectivity, your access has been denied or something went wrong on our side.",
  try: "Try:",
  yes: "Yes",
  no: "No",
  setAsNonRefundable: "Set as non-refundable",
  checkingYourInternet: "Checking your internet or data connectivity.",
  refreshingThisPage: "Refreshing this page",
  deleteLocation: "Delete Location",
  deletingName: "Deleting {0}",
  productsForDeletion:
    "{0} product(s) with this location are also up for deletion. This cannot be undone.",
  back: "Back",
  deletingLoading: "Deleting...",
  allSafeToDeleteLocation: "All safe! There are no products with this location.",
  standard: "Standard",
  variant: "Variant",
  composite: "Composite",
  productsToBeDeleted: "Products to be deleted",
  theseNthOtherProducts:
    "{0} {1} other product(s) with this location and other locations will remain in your inventory.",
  dontWorry: "Don’t worry!",
  deleteLocationNote: "Note: Your previous transactions with this location will be retained.",
  dateRange: "Date Range",
  timeRange: "Time Range",
  dateAndTime: "Date and Time",
  allLocation: "All Location",
  allRoles: "All Roles",
  noLocationAvailable: "No Location Available",
  selectLocation: "Select Location",
  pleaseSelectLoction: "Please select a location.",
  areYouSureYouWantToDeleteLocation:
    "Are you sure you want to delete {0}? This location currently has an inventory value of {1}.",
  areYouSureYouWantToDeleteProduct: `Are you sure you want to delete {0}? This supply item currently has {1} {2} in stocks.`,
  deletingThisLocation:
    "Deleting this location would also remove the stock balances of all supply items. This action cannot be undone.",
  nTon: "{0} to {1}",
  allTransactions: "All Transactions",
  registerReports: "Register Reports",
  category: "Category",
  categories: "Categories",
  createNewCategory: "Create New Category",
  editNewCategory: "Edit New Category",
  categoryName: "Category Name",
  description: "Description",
  categoryCreated: `Category "{0}" created.`,
  categoryUpdated: `Category "{0}" updated.`,
  categoryDeleted: `Category "{0}" deleted.`,
  categoriesDeleted: `{0} categories deleted.`,
  categoryNameAlreadyExist: "Category name already exists.",
  deleteCategory: "Delete Category",
  deleteCategories: `Delete {0} categories`,
  deleteThis: `Delete this {0}?`,
  deleteThisMultiple: `Delete {0} {1}?`,
  youAreAboutToDelete: `You are about to delete the {0} "{1}". This cannot be undone.`,
  youAreAboutToDeleteMultiple: `You are about to delete {0} {1}. This cannot be undone.`,
  deleteSelectedCategory: "Delete selected category",
  deleteSelectedCategories: "Delete selected categories",
  totalSales: "Total Sales",
  topLocations: "Top Locations",
  totalTopUps: "Total Topups",
  totalReturnCredits: "Total Return Credits",
  checkIns: "Check-ins",
  topProducts: "Top Products",
  averageOrderValue: "Average Order Value",
  totalTransactions: "Total Transactions",
  topSpendingGuests: "Top Spending Guests",
  lowOrOutOfStock: "Low or Out of Stock",
  topCategoriesBySales: "Top Categories by Sales",
  unitSold: "Unit Sold",
  nthUse: "{0} use",
  spend: "Spend",
  guest: "Guest",
  cash: "Cash",
  card: "Card",
  others: "Others",
  failedToLoad: "Failed to load.",
  clickHereToRetry: "Click here to retry.",
  noSalesInDateRange: "There were no sales in this date range.",
  noReturnCreditsInDateRange: "There were no return credits in this date range.",
  new: "New",
  noCheckInsInDateRange: "There were no check-ins in this date range.",
  venueSettings: "Venue Settings",
  general: "General",
  manageYourVenueSetting: "Manage your venue settings such as taxes, and currency.",
  taxes: "Taxes",
  yourGeneralSettings: "Your general settings such as venue name and currency.",
  notifications: "Notifications",
  manageNotifcations: "Manage email alerts and notifications sent to your staff.",
  paymentMethods: "Payment Methods",
  paymentMethodsYouAccept: "Payment methods you accept in your venue.",
  manageYourVenueTax: "Add and manage how your venue charges taxes.",
  creditLimits: "Credit Limits",
  creditsExpiration: "Credits Expiration",
  creditsExpirationOnCheckout: "Credits Expiration on Check-out",
  creditsNeverExpires: "Credits never expires after check-out.",
  creditAutomaticallyExpires: "Credits automatically expires after check-out",
  creditsExpirationDescription: "Set credits to expire when a guest is checked-out.",
  creditsWouldNeverExpire: "Credits would never expire when a guest is currently checked-in.",
  setExpirationPeriod: "Set expiration period",
  resetExpiration: "Reset Expiration",
  resetTheExpirationPeriod:
    "Reset the expiration period once a guest completes a transaction without checking in.",
  daysAfterCheckout: "days after check-out",
  weeksAfterCheckout: "weeks after check-out",
  monthsAfterCheckout: "months after check-out",
  allowPostpaidAndLimit: "Allow Postpaid Credits and set limits.",
  questions: "Questions",
  guestInformationYouCollect: "Guest information you collect upon check-in.",
  account: "Account",
  editYourAccountPreferences: "Edit your account preferences, email address, and password.",
  paymentAndCreditSettings: "Payment and Credit Settings",
  checkInSettings: "Check-In Settings",
  accountSettings: "Account Settings",
  today: "Today",
  thisWeek: "This week",
  thisMonth: "This month",
  custom: "Custom",
  allStaff: "All Staff",
  noAvailableStaff: "No Available Staff",
  selectStaff: "Select Staff",
  allVoucherTypes: "All Voucher Types",
  voucherType: "Voucher Type",
  allStatus: "All Status",
  success: "Success",
  failed: "Failed",
  allDevices: "All Devices",
  sellingOnPos: "Selling on POS",
  notSellingOnPos: "Not Selling on POS",
  allCategories: "All Categories",
  noAvailableCategories: "No Available Categories",
  selectCategory: "Select Category",
  inventoryReport: "Inventory Report",
  categoryReport: "Category Report",
  discountReport: "Discount Report",
  voucherReport: "Voucher Report",
  salesReport: "Sales Report",
  sales: "Sales",
  allMeasurements: "All Measurements",
  lowParLevel: "Low PAR Level",
  reorderPointReached: "Reorder Point Reached",
  venueDetails: "Venue Details",
  venueName: "Venue Name",
  venueCurrency: "Venue Currency",
  currencyAndFormat: "Currency and Format",
  changingCurrencyWontAffect:
    "Changing currency won’t affect the value price of your products, only their currency symbols.",
  manageHowVenueTax:
    "Manage how your venue charges taxes depending on your region. Consult with a tax expert to understand your tax obligations.",
  addNewTax: "Add New Tax",
  editTax: "Edit Tax",
  add: "Add",
  taxName: "Tax Name",
  taxRate: "Tax Rate",
  taxEditNote:
    "Note: Products linked to this tax will be updated automatically. This will not affect past sales.",
  setAsDefaultTax: "Set as default tax",
  taxEg: "e.g. “Value Added Tax“",
  taxAddedName: "Tax “{0}“ added.",
  deleteTax: "Delete Tax",
  deleteName: "Delete “{0}“?",
  youAreAboutToDeleteTax: "You are about to remove “{0}“ tax.",
  deletedTax: "Tax “{0}“ deleted.",
  changesSaved: "Changes saved.",
  paymentMethodsDescription: "Create and manage payment methods you accept in your venue.",
  addNewPaymentMethod: "Add New Payment Method",
  paymentMethodName: "Payment Method Name",
  editPaymentMethod: "Edit Payment Method",
  deletePaymentMethod: "Delete Payment Method",
  paymentMethodAddedName: "Payment Method “{0}“ added.",
  youAreAboutToDeletePaymentMethod: "You are about to remove “{0}“ payment method.",
  deletedPaymentMethod: "Payment method “{0}“ deleted.",
  credits: "Credits",
  postpaidCredits: "Postpaid Credits",
  creditDebit: "Credit/Debit",
  pouchPay: "PouchPAY",
  allowThisGuestProfileLimit:
    "Allow this guest profile to make purchases without credits. They will be charged when they check-out from the venue.",
  limit: "Limit",
  mewsTaxCode: "Is the tax code from MEWS?",
  posDevices: "Point-of-Sale Devices",
  editDevice: "Edit Device",
  deviceName: "Device Name",
  deviceMac: "Device MAC Address",
  deviceImei: "Device IMEI",
  deviceSerialNumber: "Device Serial Number",
  deviceNameAlreadyExists: "This device name already exists",
  deviceNameUpdated: "Device “{0}” updated.",
  printers: "Printers",
  deletePrinterProfile: "Delete Printer Profile",
  editPrinter: "Edit Printer Profile",
  printerProfiles: "Printer Profiles",
  printerProfile: "Printer Profile",
  createPrinterProfile: "Create Printer Profile",
  byCreatingPrinterProfiles:
    "By creating printer profiles, you can set which products to print on orders slips for your different venue stations such as kitchens, bars, etc.",
  printerProfileCreated: "Printer Profile “{0}” created.",
  printerProfileUpdated: "Printer Profile “{0}” updated.",
  printerProfileNameAlreadyExists: "This printer profile name already exists.",
  printerProfileName: "Printer Profile Name",
  printerProfileDeleted: "Printer Profile Deleted",
  printableItems: "Printable Items",
  printableItemsSelect: "Choose which products are printed on this printer profile.",
  printerLocationsDesc: "Choose location(s) where this printer profile can be used.",
  showHideColumns: "Show / Hide Columns",
  exportXlsx: "Export as XLSX",
  columns: "Columns",
  chooseColumns: "Choose which columns you’d like to see on this report.",
  allDiscounts: "All Discounts",
  allVouchers: "All Vouchers",
  pointOfSale: "Point-of-Sale",
  loginSessionEnded: "Login Session Ended",
  ok: "Ok",
  okay: "Okay",
  loginHasEnded: "Your login session has already ended. Please login again.",
  somethingWentWrong: "Something went wrong.",
  looksLikeYouEncounteredSomething:
    "Looks like you encountered an error, we track these errors automatically. For the mean time, try refreshing.",
  generalSettings: "General Settings",
  staffProfile: "Staff Profile",
  changePhoto: "Change Photo",
  uploadPhoto: "Upload Photo",
  remove: "Remove",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  inviteNewStaff: "Invite New Staff",
  role: "Role",
  chooseRole: "Choose role(s) for this staff",
  emailDoesntHaveRecord:
    "The email you entered doesn’t match our record, please double check and try again.",
  accountCantSeemToFind:
    "Oh no! We can't seem to find your account. Please contact the venue manager for assistance.",
  enteredPasswordIncorrect: "The entered password is incorrect.",
  welcomeBack: "Welcome back!",
  letsGetStarted: "Let's get started",
  enterEmailToLogin: "Enter your email address to sign up or to log in.",
  logIn: "Log in",
  getStarted: "Get started",
  hello: "Hello",
  chooseAVenue: "Choose a venue",
  to: "to",
  discount: "Discount",
  searchMovementPlaceholder: "Search movements by voucher name, device, guest",
  tapStatus: "Tap Status",
  tapCount: "Tap Count",
  voucherName: "Voucher Name",
  status: "Status",
  dateCreated: "Date Created",
  date: "Date",
  qty: "Qty",
  discountName: "Discount Name",
  value: "Value",
  availability: "Availability",
  onPos: "On POS",
  tagId: "Tag ID",
  searchTransactionPlaceholder: "Search transactions by guest name, tag ID, location, Check-in ID",
  transactionType: "Transaction Type",
  transactionStatus: "Transaction Status",
  deleteSelectedProduct: "Delete selected product",
  deleteSelectedProducts: "Delete selected products",
  enableSellingOnPos: "Enable selling on POS",
  disableSellingOnPos: "Disable selling on POS",
  manager: "Manager",
  allPaymentMethods: "All Payment Methods",
  choosePaymentMethod: "Choose Payment Method",
  allowedPaymentMethod: "Allowed Payment Methods",
  checkInQuestions: "Check-in Questions",
  questionDescription:
    "Information to collect upon guest registration. Use these questions by assigning it to your {0}.",
  addCustomQuestion: "Add Custom Question",
  invitationHasBeenSentTo: `Invitation has been sent to {0}.`,
  emailAlreadyInVenue: "This email is already associated with an account in this venue.",
  awaitingInviteResponse: "Awaiting invite response.",
  resendInvite: "Resend Invite?",
  revokeInvite: "Revoke Invite",
  roleAndDesignation: "Role and Designation",
  accountIsActive: "Account is Active",
  staffCanAccess: "Staff can access the POS app and perform actions.",
  actions: "Actions",
  name: "Name",
  done: "Done",
  newInviteLink: `New invite link has been sent to "{0}".`,
  turnOffPos: `Turn off selling of {0} {1}?`,
  turnOnPos: `Turn on selling of {0} {1}?`,
  sellOnPos: "Sell on POS",
  dontSellOnPos: "Don't Sell on POS",
  allSelectedAvailablePos: "All selected products will be available on POS.",
  allSelectedNotPos: "All selected products will not be shown on your POS.",
  productUpdated: `{0} product updated.`,
  productsUpdated: `{0} products updated.`,
  inventory: "Inventory",
  productRemovedFromInventory: `{0} product removed from inventory.`,
  productsRemovedFromInventory: `{0} products removed from inventory.`,
  deleteAProduct: "Delete Product",
  deleteAProductSet: "Delete Product Set",
  deleteAProduct2: "Delete Product?",
  deleteProductSet: "Delete Product Set?",
  deleteProduct: `Delete {0}?`,
  deleteProducts: `Delete {0} products?`,
  deleteProducts2: `Delete {0} products`,
  deleteCurrentlyBeingUsed:
    "{0} is currently being used in some product sets. To continue, please remove this product from the following product sets",
  areYouSureYouWantToDeleteProduct2: `Are you sure you want to delete {0}? It will no longer be available in your venue. Previous transactions and reports would not be affected.`,
  youAreAboutToRemoveProduct:
    "You are about to remove this product from your inventory and all your locations. This cannot be undone.",
  youAreAboutToRemoveMultipleProduct:
    "You are about to remove {0} products and their variants from your inventory and all your locations. This cannot be undone.",
  youAreAboutToRemoveVariantProduct:
    "You are about to remove this product and its {0} variants from your inventory and all your locations. This cannot be undone.",
  ifYoudLikeToJustStopSellingProduct:
    "If you’d like to just stop selling a product on your POS, simply disable it by turning off “Sell on POS”.",
  notePreviousTransaction: "Note: Your previous transactions with these products will be retained.",
  cannotBeDeleted: "Cannot be deleted.",
  cannotDeletePartOfComposite: "{0} cannot be deleted as it is part of {1} composite {2}.",
  item: "Item",
  theseProductsCannotBeDeleted: "These products cannot be deleted.",
  productsArePartOfComposite:
    "These products are part of other composite products. To delete, remove them first from the composite products or delete them {0} with the composite products that carry them.  ",
  someArePartOfCompositeNote:
    "{0} {1} below cannot be deleted as they are part of other composite products. To delete these, remove them from the composite products or delete them {2} with the composite products that carry them.",
  productDeleted: `Product "{0}" deleted.`,
  productSetDeleted: `Product set "{0}" deleted.`,
  productsDeleted: `{0} products deleted.`,
  productsDoesNotExist: "Products you wanted to add must exist in all locations.",
  removeNameAsYourStaff: "Remove {0} as your staff?",
  revokeThisInvite: "Revoke this invite?",
  thisStaffHasNpLongerAccess:
    "This staff will no longer have access to {0}. This action cannot be undone.",
  youAreAboutToDeleteStaffName:
    "You are about to remove {0} as your staff. This staff will no longer have access to {1}. This action cannot be undone.",
  noteTransactionStaff: "Note: Your previous transactions with this staff will be retained.",
  deleteStaff: "Delete Staff",
  staffRemoved: "Staff removed.",
  tax: "Tax",
  deleted: "Deleted",
  subTotal: "Subtotal",
  total: "Total",
  items: "Items",
  summary: "Summary",
  topupCredits: "Topup Credits",
  guestsPreviousBalance: "Guest's Previous Balance",
  previousBalance: "Previous Balance",
  totalPaid: "Total Paid",
  guestsNewBalance: "Guest's New Balance",
  newBalance: "New Balance",
  totalReturnedCredits: "Total Returned Credits",
  postPaidCreditsUsed: "Postpaid Credits Used",
  noData: "No Data",
  noDataAvailable: "No data available",
  nthGuestWhoUseThisTag: "{0} guest who used this tag.",
  phone: "Phone",
  phoneNo: "Phone No.",
  birthday: "Birthday",
  information: "Information",
  guestInfoNotYetSync: "Guest info not synced yet",
  registerInfo: "Register Info",
  voided: "Voided",
  void: "Void",
  deviceMacAddress: "Device MAC Address",
  shiftId: "Shift ID",
  voidInfo: "Void Info",
  voidReason: "Void Reason",
  searchResult: "search result",
  searchResults: "search results",
  movement: "Movement",
  inventoryMovement: "Inventory Movement",
  createNewVoucher: "Create New Voucher",
  availableOnPos: "Available on Point-of-Sale (POS)",
  voucherAvailable: "This voucher is available for issuing in your venue.",
  locationVoucherRedeemed: "Choose location(s) where this voucher is redeemed.",
  redeemableProducts: "Redeemable Products",
  productsCanBeRedeemed: "Choose which products can be redeemed from this voucher.",
  browseProducts: "Browse Products",
  selectCategoriesOrProducts: "Select categories or products from your chosen locations",
  selectProducts: "Select Products",
  in: "in",
  searchProductByKeyOrSku: "Search Products by keyword or SKU",
  allProducts: "All Products",
  uncategorized: "Uncategorized",
  productsVariants: "Products / Variants",
  returnedVoucher: "Returned Voucher",
  guestTap: "Guest Tap",
  voidAuthorization: "Void Authorization",
  type: "Type",
  roles: "Roles",
  deviceInfo: "Device Info",
  totalProductSales: "Total Product Sales",
  notes: "Notes",
  totalTopupCredits: "Total Topup Credits",
  debitCredit: "Debit / Credit Card",
  totalRefundedCredits: "Total Refunded Credits",
  guestsCheckedIn: "Guests Checked In",
  returnedTags: "Returned Tags",
  voidedItems: "Voided Items",
  voidedValue: "Voided Value",
  vouchersIssued: "Vouchers Issued",
  vouchersRemoved: "Vouchers Removed",
  vouchersRedeemed: "Vouchers Redeemed",
  voucherSupplyValue: "Vouchers Supply Value",
  voucherRetailValue: "Vouchers Retail Value",
  vouchersMaximumNumberReached: "Maximum number of vouchers reached.",
  vouchersCreationLimitInfo:
    "You may only create a maximum of 8 vouchers per venue. You may delete expired or inactive vouchers to make room for more vouchers.",
  shiftStart: "Shift Start",
  shiftEnd: "Shift End",
  openingBalance: "Opening Balance",
  expectedEndingBalance: "Expected Ending Balance",
  discrepancy: "Discrepancy",
  viewNTransactions: "View {0} Transactions",
  usageDatePeriod: "Usage Date Period",
  availableEveryday: "Available everyday",
  customDateTime: "Custom Date and Time Range",
  issuanceLimit: "Issuance Limit",
  limitVoucherPerPerson: "Limit voucher issued per person",
  createNewDiscount: "Create New Discount",
  discountCanBeApplied: "This discount can be applied in your venue.",
  egSeniorDiscount: "e.g. Senior Discount",
  egAppliesToGuests: "e.g. Applies to guests 60 yrs old and above",
  discountableProducts: "Discountable Products",
  chooseProductsCanBeDiscounted: "Choose which products from your inventory can be discounted.",
  percentage: "Percentage",
  fixedAmount: "Fixed Amount",
  discountType: "Discount Type",
  managerAuthorization: "Manager Authorization",
  requireManagerAuthorization: "Require manager authorization when claiming this discount.",
  activeDateRange: "Active Date Range",
  availableAnytime: "Available anytime",
  noTagUid: "No Tag UID",
  nthStaffWhoUsedThisTag: "{0} staff who used this tag.",
  voucherCreated: `{0} added.`,
  voucherUpdated: `Changes saved.`,
  voucherDeleted: `Voucher "{0}" deleted.`,
  voucherNameAlreadyExist: "This voucher name already exists.",
  discountNameAlreadyExist: "This discount name already exists.",
  defaultQuestions: "Default Questions",
  emailAddress: "Email Address",
  mobileNumber: "Mobile Number",
  gender: "Gender",
  customQuestions: "Custom Questions",
  nOptions: "{0} options",
  selectAType: "Select a type",
  typeAQuestion: "Type a question",
  nationality: "Nationality",
  textbox: "Textbox",
  dropdown: "Dropdown",
  checkbox: "Checkbox",
  radioButton: "Radio Button",
  country: "Country",
  typeAnOption: "Type an option",
  options: "Options",
  question: "Question",
  addOption: "Add Option",
  addOptions: "Add Options",
  deleteVoucher: "Delete Voucher",
  discountCreated: `Discount "{0}" created.`,
  discountUpdated: `Discount "{0}" updated.`,
  discountDeleted: `Discount "{0}" deleted.`,
  noEndDate: "No end date",
  customQuestion: "Custom Question",
  duplicateOption: "Duplicate option",
  questionAddedName: "Question “{0}“ added.",
  youAreAboutToDeleteQuestion: "You are about to delete this question. This cannot be undone.",
  deleteThisQuestion: "Delete this question?",
  questionDeleted: "Question “{0}“ deleted.",
  proceed: "Proceed",
  questionAlreadyExist: "Question already exists.",
  taxAlreadyExists: "Tax already exists.",
  createLocationToStoreYourProducts: "Create a location to store your products.",
  variants: "Variants",
  setAnAmountToLimitPostPaid: "Set an amount to limit a guest's allowed postpaid credits.",
  logout: "Logout",
  yourVenues: "Your Venues",
  checkYourEmail: "Check your email",
  looksLikeAlreadyInSystem: "Looks like you’re already in our system!",
  checkYourEmailAt: "Check your email at {0} to complete setting up your account.",
  youAreAssigned: "You are assigned with the following roles:",
  useVenueAppAndLogin:
    "Use the Venue App and login with your activated NFC Tag or with your current username or password. Don’t have the app yet? Ask your venue manager or {0}.",
  contactUs: "contact us",
  guestProfile: "Guest Profile",
  guestProfiles: "Guest Profiles",
  createNewProfile: "Create New Profile",
  createNewGuestProfile: "Create New Guest Profile",
  guestProfileName: "Guest Profile Name",
  preloadedVouchers: "Preloaded Vouchers",
  profileName: "Profile Name",
  assignVoucherToGuest: "Assign the vouchers to guests in this group upon registration.",
  allowPostpaidInGuestProfile:
    "Allow this guest profile to make purchases without credits. They will be charged when they check-out from the venue.",
  guestQuestions: "Guest Questions",
  informationToCollect: "Information to collect upon guest registration.",
  editQuestions: "Edit Questions",
  askGuestInfoCheckIn: "Ask guest's information during check-in.",
  required: "Required",
  defaultFields: "Default Fields",
  customFields: "Custom Fields",
  include: "Include",
  venueNameAlreadyExist: "Venue name already exists, please choose another name",
  paymentMethodAlreadyExist: "Payment method already exists",
  default: "Default",
  noTagsFound: "No tags found.",
  noResultsFound: "No Results Found",
  noResults: "No Results",
  noResultsMatchingX: "No results matching “{0}”",
  noResultInSearch: "We did not find any results for your search.",
  search: "Search",
  search2: "Search...",
  chooseVouchers: "Choose Vouchers",
  browseVouchers: "Browse Vouchers",
  noVouchersSelectedYet: "No vouchers selected yet.",
  selectVouchers: "Select Vouchers",
  searchVouchers: "Search Vouchers",
  quantity: "Quantity",
  guestProfileNameCreate: "Guest profile “{0}” created.",
  deleteGuestProfile: "Delete Guest Profle",
  askDeleteGuestProfile: "Delete guest profile?",
  youAreAboutToDeleteGuestProfile:
    "You are about to delete this guest profile. This cannot be undone.",
  guestProfileDeleted: "Guest profile deleted.",
  continuingOnThisPageWillLogout: "Continuing on this page will logout to your current session.",
  youAreLoggedInAs: "You are logged in as {0}",
  continue: "Continue",
  continueAs: "Continue as {0}",
  setUpYourAccount: "Setup your account!",
  password: "Password",
  confirmPassword: "Confirm password",
  choosePassword: "Choose password",
  show: "Show",
  hide: "Hide",
  signUp: "Sign Up",
  termsInSignUp: "By signing up, I agree to PouchNATION's {0}, {1}, and {2}.",
  termsOfService: "terms of service",
  privacyPolicy: "privacy policy",
  communityGuidelines: "community guidelines",
  wristbandTag: "Wristband Tag",
  wristbandKeeping: "Wristband Keeping",
  banTags: "Ban Tags",
  restrictUseOfTags: "Restrict use of certain tags in your venue.",
  wristbandTagKeeping: "Wristband Tag Keeping",
  allowOrRestrictGuestWBKeeping: "Allow or restrict guests from keeping their tags upon check-out.",
  allowGuestWBKeeping: "Allow guests to keep their tags upon check-out.",
  banATag: "Ban a Tag",
  onceYouBanATag: "Once you ban a tag, that tag cannot be used to transact inside your venue.",
  banList: "Ban List",
  tagUID: "Tag UID",
  searchBanTags: "Search banned tags by Tag UID",
  dateBanned: "Date Banned",
  bannedBy: "Banned By",
  lastIssuedTo: "Last Issued To",
  reason: "Reason",
  lostTag: "Lost Tag",
  defectiveTag: "Defective Tag",
  searchProductsIn: "Search products in {0}",
  manageEmailNotif: "Manage emails notification sent to you and your staff.",
  stockLevelAlerts: "Stock Level Alerts",
  whenAnItemAlertLevel: "When an item falls to its alert level.",
  manageAccountNotificationStockLevel:
    "Manage accounts to notifiy when an item falls to its alert level.",
  yourMailingIsEmpty: "Your mailing list is still empty.",
  addAccount: "Add Account",
  getNotifications: "Get Notifications",
  mailingList: "Mailing List",
  addAnotherAccount: "Add another account",
  thisEmailIsAlreadyAdded: "This email is already added.",
  accountEmailAdded: "Account “{0}“ added.",
  editAccount: "Edit Account",
  removeFromList: "Remove from list",
  removeThisAccount: "Remove this account?",
  youAreAboutToRemoveFromMailingList:
    "You are about to remove {0} from this mailing list. This account won't be able to receive stock level alerts.",
  accountEmailRemoved: "Account “{0}“ removed.",
  unitsSold: "Units Sold",
  accountAlreadyExists: "This email is already associated with an account in this venue",
  chooseLocationForThisStaff: "Choose location designation(s) for this staff",
  tagIds: "Tag IDs",
  youAreAboutToDeleteAQuestionThatIsLinked:
    "You are about to delete a question that is linked to a guest profile. This cannot be undone.",
  thisQuestionIsLinkedToNProfiles: "This question is linked to {0} guest profile(s).",
  changePassword: "Change Password",
  chooseAPassword: "Use a strong password that you don’t use elsewhere.",
  edit: "Edit",
  newPassWord: "New Password",
  currentPassword: "Current Password",
  passwordDoesNotMatch: "The passwords you entered do not match",
  passwordIncorrect: "The password you entered is incorrect.",
  currentPasswordAndNewPasswordMatched: "Current and new password is matched",
  minOfNCharacters: "Minimum of {0} characters",
  allLocations: "All Locations",
  noProductsSelected: "No products selected yet.",
  noProductsAvailable: "No products available",
  manageAndAssignQuestions:
    "Manage and assign check-in questions, free credits and vouchers to different guest profiles.",
  profileNameAlreadyExists: "Profile name already exists, please enter a different name.",
  guestProfileNamePlaceholder: "e.g. “Food Court Guests“",
  guestProfileDescriptionPlaceholder: "e.g. “Guests who checks-in the foodcourt“",
  guestProfileAmountPlaceholder: "0.00",
  createStaffWhoCan:
    "Create staff who can topup credits, register guests, and sell on your venues.",
  createVouchersForGuest: "Create vouchers for your guests and staff use!",
  noVoucherMovement: "No voucher movement.",
  noDeviceAvailable: "No Device Available",
  selectDevice: "Select Device",
  editLocations: "Edit Locations",
  editPaymentMethods: "Edit Payment Methods",
  looksLikeYouHaveNoLocationYet: "Looks like you have no locations yet.",
  toContinueCreatingStaff: "To continue creating this staff, create a location first.",
  toContinueCreatingProducts: "To continue creating this product, create a location first.",
  toContinueCreatingDiscount: "To continue creating this discount, create a location first.",
  createALocation: "Create a Location",
  noAvailableLocationsYet: "No available locations yet.",
  noAvailablePaymentMethodYet: "No available payment method yet.",
  staffProfiles: "Staff Profiles",
  createNewStaffProfile: "Create New Staff Profile",
  staffProfileName: "Staff Profile Name",
  manageYourStaffProfileDescription:
    "Manage your staff, their roles, designations, and staff profile they belong to.",
  assignVoucherStaff: "Assign the vouchers to staff in this group",
  assignFreeCreditsStaff: "Assign free credits to staff in this group",
  freeCredits: "Free Credits",
  staffProfileNamePlaceholder: "Type the name of this staff profile...",
  noSearchResultsForName: "No search results for {0}.",
  staffProfileNameCreated: "Staff Profile “{0}” created.",
  freeCreditsHoverTooltip:
    "Free credits are non-refundable and would be prioritized and used first upon payments.",
  noVoucherAvailable: "No Voucher Available",
  allGuestProfiles: "All Guest Profiles",
  noAvailableGuestProfile: "No available Guest Profile",
  selectGuestProfile: "Select Guest Profile",
  invalidToken: "Invalid Token",
  checkYourEmailOrAdmin: "Check your email or contact your administrator.",
  invalidFileType: "Invalid file type. Allowed file types are JPG or PNG.",
  unbaleToUploadMoreThanMb: "Unable to upload more than 1mb.",
  unableToDelete: "Unable to Delete",
  deleteDiscount: "Delete Discount",
  receipts: "Receipts",
  receiptFormatting: "Receipt Formatting",
  viewPreview: "View Preview",
  closePreview: "Close Preview",
  customizeReceipt: "Customize each section of your receipt.",
  customizeSectionReceipt: "Customize the sections of your receipt.",
  formatting: "Formatting",
  manageBusinessInfo: "Manage Business Information",
  salesInformation: "Sales Information",
  chooseInformation: "Choose which information to include in the sales breakdown.",
  headerSection: "Header Section",
  footerSection: "Footer Section",
  manageReceipts: "Manage the appearance and information of your receipts.",
  youCanNowLogin: "You can now login using your email and password.",
  noDevicesFound: "No devices found",
  noBannedTagsYet: "No banned tags yet.",
  createNewProduct: "Create New Product",
  exportTransactions: "Export Transactions",
  exportRegisterReport: "Export Register Report",
  noAvailableLocations: "No available locations",
  creditsDescription:
    "Assign free credits to guests in this group and set their postpaid credit limit.",
  reallyUnbanTag: "Really unban this tag?",
  unban: "Unban",
  unbanningWillMakeTagAccessible: "Unbanning will make this tag accessible in your venue.",
  tagUnbanned: "Tag unbanned.",
  banTag: "Ban Tag",
  tagBanned: "Tag banned.",
  createAndManageProducts: "Create and manage products that are sellable in your locations",
  createCategoriesToLink: "Create categories to link and categorise your products",
  inviteYourVenueStaff: "Invite your venue’s staff, manage their role and access in your POS",
  trackEveryGuestCheckedIn:
    "Track every guest checked-in in your venue and see their favorite product and location",
  createAndManagerVouchers: "Create and manage your vouchers that will be used to redeem products",
  createAndManagerDiscounts: "Create and manage your discounts with discounted products",
  viewEveryTransactions:
    "View every transactions made in your venue within a certain period of time",
  viewAndExportEachClosedRegister: "View and Export each closed register report made by your staff",
  trackWhichItemsLow: "Track which items are low in stocks and needs to be resupplied",
  viewAndExportSalesReportByCategories: "View and export your sales report by categories",
  viewAndExportDiscountReport:
    "View and export the list of your discounts and the total amount deducted for each discount",
  viewAndExportVoucherReport:
    "View and export all vouchers applied on redeemed products and the total amount of retail and supply redeemed by each voucher",
  manageThePosDevicesVenue: "Manage POS devices that are logged in and is being used in your venue",
  manageImportantDetailsSettings:
    "Manage the important details and rules of your venue such as venue settings, payment and credit settings, check-in settings and your account settings.",
  postpaidCreditsUsed: "Postpaid Credits Used",
  vouchersUsage: "Vouchers Usage",
  freeCreditsIssued: "Free Credits Issued",
  freeCreditsRemoved: "Free Credits Removed",
  newGuests: "New Guests",
  checkOuts: "Check-outs",
  tagsIssued: "Tags Issued",
  clearedTags: "Cleared Tags",
  bannedTags: "Banned Tags",
  lostTags: "Lost Tags",
  defectiveTags: "Defective Tags",
  shift: "Shift",
  actualClosingBalance: "Actual Closing Balance",
  totalTax: "Total Tax",
  openReceiptPreview: "Open Receipt Preview",
  closeReceiptPreview: "Close Receipt Preview",
  supplyItems: "Supply Items",
  supplyValue: "Supply Value",
  createNewSupplyItem: "Create New Supply Item",
  manageTheStocksPerLocation: "Manage the stocks per location of this supply item.",
  skuStockKeepingUnit: "SKU (Stock Keeping Unit)",
  stocks: "Stocks",
  pricing: "Pricing",
  supplyCost: "Supply Cost",
  totals: "Totals",
  supplyItemNameCreated: "Supply Item “{0}” created.",
  supplyItemDeleted: "Supply Item Deleted",
  supplyItemAlreadyExists: "Supply item name already exists.",
  skuAlreadyExists: "SKU already exists.",
  supplyItem: "Supply Item",
  inventoryValue: "Inventory Value",
  inStock: "In Stock",
  parLevel: "Par Level",
  reorderPoint: "Reorder Point",
  reorderStocks: "Reorder Stocks",
  checkStocks: "Check Stocks",
  totalInStock: "Total In Stock",
  sku: "SKU",
  totalStocksPerLocation: "Total Stocks per Location",
  editSupplyItem: "Edit Supply Item",
  deleteSupplyItem: "Delete Supply Item",
  newProduct: "New Product",
  createTheProduct: "Create the products to sell in your venue.",
  newProductSet: "New Product Set",
  combineYourProducts: "Combine your products to be sold as a set.",
  editProduct: "Edit Product",
  hideInPos: "Hide in POS",
  activateInPos: "Activate in POS",
  manageInventory: "Manage Inventory",
  disclaimerCategoryReport:
    "DISCLAIMER: There may be products with multiple categories assigned to it which will result in double counting in each line item.",
  noReportsFound: "No reports found.",
  weDidNotFoundReports: "We did not found any reports for your filters.",
  productName: "Product Name",
  productSetName: "Product Set Name",
  nameYourProduct: "Name your product...",
  nameYourProductSet: "Name your product set...",
  describeThisProductSet: "Describe this product set",
  describeThisProduct: "Describe this product...",
  selectToIncludeCategory: "Select to include this product in a category..",
  selectToIncludeProductSetInCategory: "Select to include this product set in a category..",
  activateOnPos: "Activate on Point-of-Sale (POS)",
  chooseTheLocationWhereThisProduct:
    "Choose the locations where this product is sold and where the supplies are deducted.",
  chooseAtleast1Location: "Choose at least 1 location",
  manageInventoryAndSupply: "Manage the inventory and supply items of this product.",
  composition: "Composition",
  selectAndMakeComposition: "Select the make and composition of this product.",
  needHelp: "Need Help?",
  addAWholeProduct: "Add a Whole Product",
  addThisProduct: "Add this product as a new product and supply item.",
  assembleAProduct: "Assemble a Product",
  chooseASimpleOrMultipleItem: "Choose a single or multiple supply items to create a product.",
  all: "All",
  checkedIn: "Checked-in",
  checkedOut: "Checked-out",
  noRemainingBalance: "No Remaining Balance",
  withRemainingBalance: "With Remaining Balance",
  withPendingPayments: "With Pending Payments",
  change: "Change",
  nameYourSupplyItem: "Name your supply item",
  reachedReorderPoint: "Reached Reorder Point",
  belowParLevel: "Below Par Level",
  zeroInventory: "Zero Inventory",
  createAndManageRawMaterials:
    "Create and manage raw material items and its inventory stocks for each location.",
  totalAmount: "Total Amount",
  pleaseSelectStockUnit: "Please Select Stock Unit",
  automaticallyGenerateSku: "Automatically Generate SKU",
  supplyUnit: "Supply Unit",
  stockWeightPerN: "Stock weight per {0}",
  sellingQty: "Selling Qty",
  thisProductsHasMultipleVariant:
    "This product has multiple options such as size, color, flavor, etc.",
  pricingAndTaxes: "Pricing and Taxes",
  chargeTaxesOnThisProduct: "Charge taxes on this product",
  viewTaxSettings: "View Tax Settings",
  price: "Price",
  markUp: "Markup",
  retailPrice: "Retail Price",
  egSizeFlavor: "eg. Size, Flavor",
  enterEachOption: "Enter each option separated by a comma",
  attribute: "Attribute",
  addAnotherAttribute: "Add Another Attribute",
  manageVariants: "Manage Variants",
  netWeightN: "Net Weight {0}",
  editVariants: "Edit Variants",
  selectItemsThisProductMadeOf:
    "Select the item/s this product is made of. Set the quantity of each supply item that will be deducted per sale order of this product.",
  addAnotherSupplyItem: "Add Another Supply Item",
  createNewProductSet: "Create New Product Set",
  stockUnit: "Stock Unit",
  selectStockUnit: "Select Stock Unit",
  quantitySupplyItems: "The quantity of supply items used for this product.",
  selectSupplyItem: "Select supply item",
  attributeAlreadyExists: "Attribute name already exists in this product",
  postpaidCreditsUsedCanOnlyBeReplenished:
    "Postpaid Credits Used can only be replenished and paid for via top-up or upon check-out.",
  totalFreeCreditsIssued: "Total Free Credits Issued",
  totalRemoved: "Total Removed",
  totalFreeCreditsRemoved: "Total Free Credits Removed",
  optionNameAlreadyExists: "Option name already exists",
  selectStockLevel: "Select Stock Level",
  allStockLevels: "All Stock Levels",
  stockLevel: "Stock Level",
  stockLevels: "Stock Levels",
  alreadyExists: "Already exists.",
  compositionGuide: "Composition Guide",
  productsVSupplyItems: "Products vs Supply Items",
  productsAreSupplyItemsAre:
    "{0} are the items that you sell in your venue. {1} are the items that you use to build your products. Supply items are managed in your {2}.",
  wholeProduct: "Whole Products",
  aWholeProductIsFinished:
    "A whole product is a finished product to be sold in your venue. With variants, you may choose to sell different variations of this product such as flavor, color, and size. All whole products and it’s variants would each have it’s own supply item.",
  byAssemblingAProduct:
    "By assembling a product, you can create a product with multiple supply items as well as breakdown a single supply item with variants.",
  example1AssembleProduct:
    "{0}:  A Cafe Latte would need {1}, {2}, and {3}. The inventory would be deducted from the coffee beans, milk, and sugar when a Cafe Latte is purchased.",
  example1: "Example 1",
  coffeeBeans: "coffee beans",
  milk: "milk",
  sugar: "sugar",
  example2AssembleProduct:
    "{0}: Wine can be sold as a {1} or a {2}. Select the wine as the supply item, then create the variants “bottle” and “glass” and adjust the quantity of the item per variant, so it would be deducted from the same supply item accoridingly.",
  example2: "Example 2",
  bottle: "bottle",
  glass: "glass",
  anyMoreQuestions: "Any more questions? {0}",
  feelFreeToContactPouchnation: "Please feel free to reach us at PouchNATION.",
  thisProductNameAlreadyExists: "This product name is already exists",
  nameAdded: "“{0}” added.",
  thisSkuExists: "This SKU already exists",
  selectTax: "Select Tax",
  availabilityOnPos: "Availability on POS",
  addProducts: "Add products or supply items in this product set.",
  searchForProducts: "Search for products or supply items",
  original: "Original",
  adjustQuantity: "Adjust Quantity",
  newQuantity: "New Quantity",
  removeStocks: "Remove Stocks?",
  removeStocksConfirm: "Remove Stocks",
  removeStocksDetails: "Are you sure you want to remove {0} {1} in stocks of {2} in {3}?",
  itemsSold: "Items Sold",
  grossProfit: "Gross Profit",
  editProductSet: "Edit Product Set",
  taxIncluded: "Tax included",
  taxPercentageAndValue: "Tax ({0}%): {1}",
  addAnotherProductOrSupplyItem: "Add another product or supply item",
  postpaidCreditLimit: "Postpaid Credit Limit",
  viewTheSalesSummaryOfYourVenue:
    "View the sales summary of your venue and its top locations, products, and more.",
  manageSupplyItems: "Manage Supply Items",
  manageInventoryAndProducts: "Manage the inventory and products of this set.",
  typeToSearch: "Type to search for an item!",
  assemble: "Assemble",
  whole: "Whole",
  productSet: "Product Set",
  searchProductsByKeyword: "Search Products by keyword",
  hideVariant: "Hide Variant",
  activateVariant: "Activate Variant",
  removeVariant: "Remove Variant",
  areYouSureYouWantToRemoveVariant:
    "Are you sure you want to remove the “{0}” variant? Its supply item would not be removed.",
  addVariant: "Add Variant",
  defaultTax: "Default Tax",
  customTax: "Custom Tax",
  refundedCredits: "Refunded Credits",
  returningGuests: "Returning Guests",
  adjustTheSupplyItemsVariant:
    "Adjust the supply items and the quantities for this specific variant.",
  variantAlreadyExist: "Variant already exist",
  addNewSupplyItem: "Add New Supply Item",
  addNewSupplyItemSelect: `Add New Supply Item “{0}”`,
  active: "Active",
  hidden: "Hidden",
  invalidOptions: "Invalid option(s).",
  recentTransactions: "Recent Transactions",
  pairedTags: "Paired Tags",
  amount: "Amount",
  totalSpent: "Total Spent",
  lastPurchasedProduct: "Last Purchased Product",
  lastVisitedLocation: "Last Visited Location",
  profile: "Profile",
  overview: "Overview",
  totalVisits: "Total Visits {0}",
  guestSince: "Guest since {0}",
  viewAllTransactions: "View All Transactions",
  creditBalance: "Credit Balance",
  tagInfo: "Tag Info",
  issuedBy: "Issued by",
  issuedOn: "Issued on",
  createAccount: "Create Account",
  viewPlansAndPricing: "View Plans and Pricing",
  newToPouchNation: "New to PouchNATION?",
  legalBusinessName: "Legal Business Name",
  businessAddress: "Business Address",
  contactNumber: "Contact Number",
  proceedToCheckout: "Proceed to Checkout",
  createYourVenue: "Create Your Venue",
  thisVenueFreeTrial: "This venue will be on free-trial for the first 30-days.",
  subscription: "Subscription",
  manageYourVenueSubscription:
    "Manage your venue's subscription, billing information, and invoices.",
  youCanChangeThisLater: "You can change this later!",
  assignAProfile: "Assign a profile",
  streetVillage: "Street address, Town / Village",
  cityStateCountry: "City, State, Zip Code",
  youAreAboutToDeleteStaffProfile:
    "You are about to delete this staff profile. This cannot be undone.",
  askDeleteStaffProfile: "Delete staff profile?",
  deleteStaffProfile: "Delete Staff Profile",
  staffProfileDeleted: "Staff profile deleted",
  guestList: "Guest List",
  guestProfileList: "Guest Profile List",
  editGuestProfile: "Edit Guest Profile",
  signInButton: "Sign-in Button",
  signIn: "Sign-in",
  staffList: "Staff List",
  editStaff: "Edit Staff",
  locationsList: "Locations List",
  checkinId: "Check-in ID",
  bookingNo: "Booking No.",
  dateOfCheckin: "Date of Check-in",
  dateOfCheckout: "Date of Check-out",
  pairedTagOnCheckin: "Paired Tag on Check-in",
  totalTagsUsed: "Total Tags Issued",
  checkedInBy: "Checked-in by",
  lastSynced: "Last synced {0}",
  viewBillingStatement: "View Billing Statement",
  notedByOn: "Noted by {0} on {1}",
  customerBehaviourOverTime: "Customer Behaviour Overtime",
  guestDetails: "Guest Details",
  birthdate: "Birthdate",
  showMore: "Show More",
  showLess: "Show Less",
  hideDetails: "Hide Details",
  totalSpentToDate: "Total Spent to Date",
  averageDurationOfStay: "Average Duration of Stay",
  mostPurchasedProduct: "Most Purchased Product",
  mostVisitedLocation: "Most Visited Location",
  createNote: "Create Note",
  addNote: "Add Note",
  noNotesFound: "No Notes Found",
  updatedOn: "updated on {0}",
  note: "note",
  checkInDetails: "Check-in Details",
  checkOutDetails: "Check-out Details",
  banDetails: "Ban Details",
  forceBanned: "Forced Checked-out and Ban Details",
  forceCheckoutDetails: "Forced Check-out Details",
  checkedOutBy: "Checked-out by",
  checkedOutOn: "Checked-out on",
  forcedCheckedOutBy: "Forced Check-out by",
  addANote: "Add a note...",
  editNote: "Edit Note",
  forceCheckout: "Force Check-out",
  forceCheckoutName: "Force Check-out {0}?",
  exportCheckinDetails: "Export Check-in Details",
  checkout: "Check-out",
  guestCheckedOutAndBanned: "Guest checked-out and tag banned.",
  forceCheckOutBy: "Forced check-out by",
  bookings: "Bookings",
  bookingList: "Booking List",
  bookingDescription: "Keep track of every booking reservation in your venue.",
  arrivals: "Arrivals",
  departure: "Departure",
  staying: "Staying",
  toBeCheckedIn: "To Be Checked-in",
  inProgress: "In Progress",
  missedCheckIn: "Missed Check-in",
  missedCheckOut: "Missed Check-Out",
  cancelled: "Cancelled",
  accommodation: "Accommodation",
  bookingDetails: "Booking Details",
  reasonsForStay: "Reasons for Stay",
  noOfNights: "No. of Nights",
  arrivalDate: "Arrival Date",
  departureDate: "Departure Date",
  noOfGuests: "No. of Guests",
  bookedBy: "Booked by",
  companyDetails: "Company Details",
  guestSpecialRequest: "Guest Special Requests",
  bookingSummary: "Booking Summary",
  bookingGuests: "Booking Guests",
  bookingAccomodation: "Booking Accomodation",
  billAmount: "Bill Amount",
  paymentStatus: "Payment Status",
  paymentMethod: "Payment Method",
  powerYourVenue: "Power Your Venue with Integrations.",
  integrateDesc: "Integrate with powerful property managements systems and more.",
  viewBookingInfo:
    "View booking information from different platforms such as MEWS, PouchNATION Ticketing, and more!",
  easierGuestReg: "Easier Guest Registration and Check-ins",
  createYourOwnIntegration: "Create your own integrations with PouchCONNECT",
  viewIntegrations: "View Integrations",
  integration: "Integration",
  integrations: "Integrations",
  integrationSettingDesc:
    "Connect with other business and productivity apps to help you grow and streamline your business.",
  extendThePower: "Extend the power of your PouchVENUE with the best-in-class add-ons.",
  termsInAllowingAccess:
    "By clicking on Allow Access, you authorize {0} to use, receive, and store your information. Please review {1}’s {2} and {3}. You can stop it at any time on the Integrations settings of your PouchNATION account.",
  termsInAllowingAccess2:
    "By clicking on Allow Access, you authorize {0} to use, receive, and store your information. You can stop it at any time on the Integrations settings of your PouchNATION account.",
  termsInAllowingAccess3:
    "By clicking on Allow Access, you authorize {0} to store your {1} information. Please review our {2} and {3}.",
  termsAndConditions: "Terms and Conditions",
  privacyPolicy2: "Privacy Policy",
  allowAccess: "Allow Access",
  connectAndInstall: "Connect and Install",
  accessToken: "Access Token",
  goToXDashboard: "Go to {0} Dashboard",
  xConnectedSuccessfully: "{0} Connected Successfully",
  xConnectedFailed:
    "We can't seem to recognize this access token. Please refer to the instructions below to retrieve your proper access token.",
  connected: "Connected",
  disconnect: "Disconnect",
  editAccessToken: "Edit Access Token",
  typeDisconnect: "Type DISCONNECT in uppercase to confirm",
  youAreAboutToDisconnect:
    "You’re about to disconnect and permanently remove all information from this app.",
  reconnect: "Reconnect",
  bookingId: "Booking ID",
  numberOfNights: "# of Nights",
  numberOfGuests: "# of Guests",
  totalBookings: "Total Bookings",
  toBeCheckedOut: "To Be Checked-Out",
  confirmed: "Confirmed",
  selectStatus: "Select status",
  noBookingsYet: "No bookings yet.",
  youllFindYourBookingsHere: "You’ll find your bookings here.",
  cantFindDesc: "Can’t find what you’re looking for? Try a different search or filter.",
  assignedRooms: "Assigned Rooms/Beds",
  notCheckedIn: "Not Checked-in",
  headOfBooking: "Head of Booking",
  bookingAmount: "Booking Amount",
  stocktake: "Stocktake",
  trackAndManage: "Track and manage your manual inventory counts.",
  createNewStocktake: "Create New Stocktake",
  forApproval: "For Approval",
  completed: "Completed",
  searchForStockId: "Search for Stock ID...",
  id: "ID",
  dateModified: "Date Modified",
  pleaseEnsureLocation:
    "Please ensure that a location has no ongoing transactions before starting a stocktake.",
  time: "Time",
  toContinueCreatingStocktake: "To continue creating this stocktake, create a location first.",
  stocktakeAlreadyExists: "Stocktake already exists",
  startStocktake: "Start Stocktake",
  stocktakeWithId: "Stocktake #ST-{0}",
  startedOn: "Started on",
  lastModifiedBy: "Last Modified by",
  lastModifiedOn: "Last Modified on",
  completedOn: "Completed on",
  completedBy: "Completed by",
  voidedOn: "Voided on",
  voidedBy: "Voided by",
  expStockQty: "Exp. Stock Qty",
  actualStockQty: "Actual Stock Qty",
  difference: "Difference",
  completeStocktake: "Complete Stocktake",
  share: "Share",
  stocktakeSaved: "Stocktake Saved",
  onceYouCompleteStocktake:
    "Once you complete a stocktake, all actual quantities of the supply items would be adjusted accordingly. This action cannot be undone.",
  completeStocktakeConfirmation: "Complete {0}?",
  voidStocktake: "Void Stocktake",
  almostThere: "Almost there!",
  completeYourSubscription: "Complete your subscription to access your venue, ",
  completeSubscription: "Complete Subscription",
  undo: "UNDO",
  view: "View",
  expectedQty: "Expected Qty",
  actualQty: "Actual Qty",
  voidId: "Void {0}?",
  voidConfirmation: "Are you sure you want to void {0} This action cannot be undone.",
  searchForStocktakeId: "Search for Stocktake ID...",
  shareThisLink:
    "Share this link to open {0} on any device! Copy the link and share it with any of your staff.",
  copyLink: "Copy Link",
  copied: "Copied!",
  accountMapping: "Account Mapping",
  accountMappingDescription: "Set and map your accounts to the PouchVENUE system.",
  mapAndManageAccounts:
    "Map and manage your default accounts for each PouchVENUE business process.",
  mapYourAccounts: "Map your accounts for inventory assets, discrepancy expenses, etc.",
  manageYourAccountsSales:
    "Manage your accounts regarding sales, cost of goods, product taxes, or discounts.",
  manageToppedUpCredits:
    "Manage topped-up credits, free credits, postpaid credits, and expired credits.",
  purchasingAndInventory: "Purchasing and Inventory",
  inventoryAccountPlaceholder: "Asset/Inventory",
  InventoryGainLossAccountPlaceholder: "Gain/Loss on Sales of Inventory",
  taxOnPurchasesAccountPlaceholder: "Liability/Tax on Purchases",
  revenueAccountPlaceholder: "Revenue/Sales",
  costOfGoodsSoldPlaceholder: "COGS/COGS",
  taxOnSalesLiabilityAccountPlaceholder: "Liability/Tax on Sales",
  salesDiscountsPlaceholder: "Sales Discount",
  salesFOCPlaceholder: "Revenue/Sales FOC",
  creditsLiabilityAccountPlaceholder: "Liability/Credits",
  freeCreditsLiabilityAccountPlaceholder: "Liability/Free Credits",
  postpaidCreditsLiabilityAccountPlaceholder: "Asset/Postpaid Credits",
  clearedUnusedAndExpiredCreditsPlaceholder: "Other Income (expiry)",
  inventoryAccount: "Inventory Account",
  inventoryAccountMessage: "For purchased supply items and inventory before selling.",
  inventoryGainLossAccount: "Inventory Gain/Loss Account",
  inventoryGainLossAccountMessage: "For discrepancies in stocktake, stock adjustments, etc.",
  taxOnPurchasesAccount: "Tax on Purchases Account",
  taxOnPurchasesAccountMessage: "VAT and other taxes on purchase orders.",
  RevenueAccount: "Revenue Account",
  RevenueAccountMessage: "The default account for all sale revenue.",
  costOfGoodsSold: "Cost of Goods Sold (COGS)",
  costOfGoodsSoldMessage: "The default account for the cost of a supply once sold.",
  taxOnSalesLiabilityAccount: "Tax on Sales Liability Account",
  taxOnSalesLiabilityAccountMessage: "The default account for taxes charged for each product.",
  salesDiscounts: "Sales Discounts",
  salesDiscountsMessage: "The cost for all applied discounts.",
  salesFOC: "Sales FOC",
  salesFOCMessage: "The cost for all applied free credits.",
  creditsLiabilityAccount: "Credits Liability Account",
  creditsLiabilityAccountMessage:
    "The holding account for all credits that are topped up in a tag before sales.",
  freeCreditsLiabilityAccount: "Free Credits Liability Account",
  freeCreditsLiabilityAccountMessage: "Cost of issued free credits to guests once used.",
  postpaidCreditsLiabilityAccount: "Postpaid Credits Liability Account",
  postpaidCreditsLiabilityAccountMessage:
    "Credits used when a guest reaches a zero balance. To be paid via top-up upon check-out.",
  clearedUnusedAndExpiredCredits: "Cleared, Unused, and Expired Credits",
  clearedUnusedAndExpiredCreditsMessage:
    "For credits that were topped up but were cleared, non-refundable, or expired.",
  revenueAccount: "Revenue Account",
  linkWithMewsAccountingItems: "Link with MEWS Accounting Items",
  linkAccountingItem: "Link Accounting Item",
  holdingAccoutForToppedUpItems:
    "The holding account for all credits that are topped up in a tag before sales",
  processingTransactions: "Processing Transactions...",
  thereIsSomeError:
    "Uh-oh! There is some error in our end. Please bear with us and check again later.",
  transactionsFailedToProcess: "transactions failed to process.",
  pleaseCheckYourNetwork: "Please check your network to update your venue data.",
  offline: "Offline.",
  seconds: "seconds",
  second: "second",
  minutes: "minutes",
  minute: "minute",
  hours: "hours",
  hour: "hour",
  days: "days",
  day: "day",
  months: "months",
  month: "month",
  years: "years",
  year: "year",
  chartOfAccounts: "Chart of Accounts",
  chartOfAccountsDescription: "Update and manage your chart of accounts.",
  categorizeAndManage: "Categorize and manage every transaction with chart of accounts.",
  mapAndManageAccountsLink: "Map and manage your accounts in {0} for proper accounting",
  code: "Code",
  accountType: "Account Type",
  currency: "Currency",
  theLinkYouAreTryingToAccess: "The link you are trying to access is not from your Venue.",
  youDontHavePermission: "You don’t have permission to access this link",
  theLinkYouAreTryingToAccessDoesNotBelongToLocation:
    "The link you are trying to access is not from your assigned location",
  reallyUnbanThisTagDescription:
    "Unbanning this tag will only take effect next time your devices are synced and updated (usually every 5 minutes when connected to internet).  To reflect these changes immediately, please sync and update your devices once you unban this tag.",
  unbanTag: "Unban Tag",
  banningThisTagWillOnlyTakeEffect:
    "Banning this tag will only take effect next time your devices are synced and updated (usually every 5 minutes when connected to internet). To reflect these changes immediately, please sync and update your devices once you ban this tag.",
  accountingSoftware: "Accounting Software",
  distribution: "Distribution",
  inventoryManagement: "Inventory Management",
  propertyManagementSystem: "Property Management System",
  allowAccessAndConnect: "Allow Access and Connect",
  together: "Together",
  retailPriceMustNotBeNegative: "Retail price must not be negative",
  addAValidAttributeAndOptions: "Add a valid attribute and options.",
  variantIsCurrentlyBeingUsed: "Variant is currently being used in the following product",
  removeVariantFirstFromProduct: "Remove this variant first from the product",
  toDeleteItem: "to delete this item.",
  and: "and",
  productCategory: "Product Category",
  minimumOf: "Minimum of 2 items required.",
  margin: "Margin",
  discountApplied: "Discount Applied",
  voucherApplied: "Voucher Applied",
  guestName: "Guest Name",
  staffName: "Staff Name",
  viewDetails: "View Details",
  invalidTimeRange: "Invalid time range",
  used: "Used",
  discountRateOrValue: "Discount Rate or Value",
  measurements: "Measurements",
  stockVariance: "Stock Variance",
  valueRedeemed: "Value Redeemed",
  totalUse: "Total Use",
  productRedeemed: "Product Redeemed",
  checkOutTheGuide: "Check out the guide to connect integrations.",
  thisAppRequests: "This app requests the following information:",
  vatReg: "VAT Reg.",
  deviceId: "Device ID",
  serialNumberAbbv: "S/N",
  orNo: "OR#",
  staffWithColonSymbol: "Staff:",
  staffTagWithColonSymbol: "Staff Tag:",
  accrNo: "Accr#:",
  dateIssuedWithColonSymbol: "Date Issued:",
  validUntilWithColonSymbol: "Valid Until:",
  permitNumberWithColonSymbol: "Permit Number:",
  guestWithColonSymbol: "Guest:",
  txnId: "Txn Id: #",
  thisServesAsAnOfficialReceipt: "- THIS SERVES AS AN OFFICIAL RECEIPT -",
  tags: "Tags",
  searchReportsByShiftId: "Search reports by shift id",
  start: "Start",
  end: "End",
  passwordsDoNotMatch: "Passwords do not match",
  eightCharactersMinimum: "8 characters minimum",
  voucherMovementPage: "Voucher Movement Page",
  vouchersListPage: "Vouchers List Page",
  saveVoucherForm: "Save Voucher Form",
  editVoucherPage: "Edit Voucher Page",
  createVoucherPage: "Create Voucher Page",
  saveVenueForm: "Save Venue Form",
  selectVenue: "Select Venue",
  transactionInfoPage: "Transaction Info Page",
  transactionsPage: "Transactions Page",
  saveStaffProfileForm: "Save Staff Profile Form",
  editStaffProfilePage: "Edit Staff Profile Page",
  createStaffProfile: "Create Staff Profile",
  staffProfileList: "Staff Profile List",
  saveStaffForm: "Save Staff Form",
  registerStaff: "Register Staff",
  editStaffPage: "Edit Staff Page",
  reportsInfoPage: "Reports Info Page",
  registerReportsPage: "Register Reports Page",
  wristbandTagSettings: "Wristband Tag Settings",
  saveWristbandKeepingSettings: "Save Wristband Keeping Settings",
  discardWristbandKeepingSettings: "Discard Wristband Keeping Settings",
  saveTaxSetting: "Save Tax Setting",
  taxSettings: "Tax Settings",
  receiptSettings: "Receipt Settings",
  saveReceiptSettings: "Save Receipt Settings",
  saveQuestion: "Save Question",
  deleteQuesiton: "Delete Question",
  questionSettings: "Question Settings",
  savePaymentMethod: "Save Payment Method",
  paymentMethodsSettings: "Payment Methods Settings",
  addPaymentMethod: "Add Payment Method",
  stockLevelNotificationSettings: "Stock Level Notification Settings",
  notificationSettings: "Notification Settings",
  integrationSettings: "Integration Settings",
  saveGeneralSettings: "Save General Settings",
  creditSettings: "Credit Settings",
  saveCreditSetting: "Save Credit Setting",
  banTagListSettings: "Ban Tag List Settings",
  searchTag: "Search Tag",
  saveAccountSetting: "Save Account Setting",
  saveProductSet: "Save Product Set",
  productList: "Product List",
  saveProduct: "Save Product",
  createProductSet: "Create Product Set",
  createProduct: "Create Product",
  saveNewSupplyItemForm: "Save New Supply Item Form",
  noLocation: "No Location",
  download: "Download",
  clear: "Clear",
  generatingReport: "Generating Report...",
  downloading: "Downloading...",
  allReportsReadyForDownload: "All reports ready for download",
  preparingReport: "Preparing Report",
  clearDownloads: "Clear Downloads",
  reportsFailedToLoad: "Reports failed to load.",
  loading: "Loading...",
  thisFieldIsRequired: "This field is required.",
  thisEmailIsInvalid: "This email is invalid.",
  shouldBeANumber: "Should be a number.",
  noStocks: "No Stocks",
  credit: "Credit",
  other: "Other",
  topupAndReturnCredits: "Topup and Return Credits",
  registerGuests: "Register Guests",
  returnCredits: "Return Credits",
  stockManager: "Stock Manager",
  inactive: "Inactive",
  expired: "Expired",
  sale: "Sale",
  mixed: "Mixed",
  redeem: "Redeem",
  issueFreeCredits: "Issue Free Credits",
  removeFreeCredits: "Remove Free Credits",
  issue: "Issue",
  usage: "Usage",
  businessName: "Business Name",
  taxNumber: "Tax Number",
  serialNumber: "Serial Number",
  receiptNumber: "Receipt Number",
  dateAndTimeOfTransaction: "Date and Time of Transaction",
  venueLocation: "Venue Location",
  staffNameAndOrTagId: "Staff Name and/or Tag ID",
  posProvider: "POS Provider",
  guestNameAndOrTagId: "Guest Name and/or Tag ID",
  transactionId: "Transaction ID",
  itemTax: "Item Tax",
  guestsPreviousBalanceTotalPaidAndNewBalance:
    "Guests Previous Balance, Total Paid, and New Balance",
  redeemed: "Redeemed",
  addedStock: "Added Stock",
  removedStock: "Removed Stock",
  createdSupplyItem: "Created Supply Item",
  trackedInventory: "Tracked Inventory",
  saleReturn: "Sale Return",
  typeAReason: "Type a reason...",
  chooseAReason: "Choose a reason",
  learnMore: "Learn More",
  freeCreditsAreCreditsThatAreFreeOfCharge:
    "Free Credits are credits that are free of charge. These credits are",
  lowerCaseNoneRefundable: "non-refundable",
  andWouldBe: "and would be",
  prioritizedAndUsedFirst: "prioritized and used first upon payments.",
  postPaidCreditsIsTheAmount:
    "Postpaid credits is the amount a guest could use when their balance reaches zero. When a guest has postpaid credits balance, the amount should be settled and paid for via top-up oor upon checkout",
  toAddMoreCreditsToATag: "To add more credits to a tag, guests may be able to",
  lowerCaseTopUpCredits: "top-up credits",
  uponRegistration: "upon registration or with any top-up staff / kiosk within the venue.",
  viewCategoryDetails: "View Category Details",
  viewDiscountDetails: "View Discount Details",
  retryReloadingChart: "Retry Loading Chart",
  viewVoucherDetails: "View Voucher Details",
  set: "Set",
  sets: "Sets",
  thisTagIdIsAlreadyBanned: "This Tag ID is already banned.",
  cancelBanTag: "Cancel Ban Tag",
  continueBanningThisTag: "Continue banning this tag?",
  noRecordsOf: "No records of",
  usesLastIssuedTo: "uses, last issued to",
  addProduct: "Add Product",
  noResultsMatching: "No results matching",
  enterYourEmailAddress: "Enter your email address",
  enterYourPassword: "Enter your password",
  subscribeAndRegister: "Subscribe and register now to get a",
  freeTrial: "30-day free trial!",
  assignedSpace: "Assigned Space",
  capacity: "Capacity",
  rooms: "Rooms",
  checkInDate: "Check-in Date",
  checkedInDate: "Checked-In Date",
  checkedOutDate: "Checked-Out Date",
  saveCategoryForm: "Save Category Form",
  displayCategoryInPos: "Display this category in the POS devices.",
  chooseCategoryColor: "Choose a category color:",
  errorLoadingColors: "Error loading colors. Please try again later.",
  categoryList: "Category List",
  productCount: "Product Count",
  createCategory: "Create Category",
  editCategory: "Edit Category",
  saveDeviceForm: "Save Device Form",
  saveDiscountForm: "Save Discount Form",
  deviceImeiSerial: "Device IMEI / Serial",
  lastSync: "Last Sync",
  deviceList: "Device List",
  viewDevice: "View Device",
  createDiscountPage: "Create Discount Page",
  percentageBetween: "Percentage should be between 1-100",
  fixedAmountRange: "Fixed amount should be between 1-50,000",
  discountsPage: "Discounts Page",
  editDiscountsPage: "Edit Discount Page",
  deleteNote: "Delete Note",
  currentGuestProfiles: "Current Guest Profiles",
  searchGuestsByNameOrId: "Search guests by name or tag ID",
  dateOfRegistration: "Date of Registration",
  guestOrTagStatus: "Guest or Tag Status",
  removeThisSupplyItem: "Remove this supply item first from the products to delete this item.",
  pleaseCreateLocation: "Please create at least 1 location to allocate this supply item's stocks.",
  itemName: "Item Name",
  typeHere: "Type here...",
  weightInGrams: "Weight in grams",
  orderQty: "Order Qty",
  pricePerOrderQty: "Price per Order Qty",
  maximumValueAllowed: "Maximum value allowed is 20,000,000",
  searchSupplyItemBy: "Search supply item by name or SKU",
  stockBelowParLevel: "Stock is below par level",
  stockReachedReorderPoint: "Stock has reached reorder point",
  inventoryMovementPage: "Inventory Movement Page",
  supplyItemSummaryPage: "Supply Item Summary Page",
  saveLocationForm: "Save Location Form",
  savePrinterProfileForm: "Save Printer Profile Form",
  openWindow: "Open Window To Locations",
  locationDesignation: "Location Designation",
  indeterminate: "indeterminate",
  filters: "Filters",
  clearAllFilters: "Clear All Filters",
  applyFilter: "Apply Filter",
  allRightsReserved: "All rights reserved.",
  copyrightYear: "Copyright © 2021",
  pouchnation: "PouchNATION",
  termsOfUse: "Terms of Use",
  privacy: "Privacy",
  glossary: "Glossary",
  feedback: "Feedback",
  roadMap: "Roadmap",
  contactUs2: "Contact Us",
  pouchConnect: "PouchCONNECT",
  pouchLogo: "PouchNATION Logo",
  perPage: "{0} per page",
  imageTypeSize: "JPG, GIF or PNG. Max size of 1MB",
  businessContactNumber: "Business Contact Number",
  defaultAccountForTaxes: "The default account for taxes charged for each product.",
  selectAccount: "Select Account",
  saleDiscounts: "Revenue/Sales Discounts",
  saleFoc: "Revenue/ Sales FOC",
  clearedUnusedExpiredCredit: "Other Income (Expiry)",
  noAccountsYet: "No Accounts Yet",
  pleaseConnectToAnAccounting:
    "Please connect to an accounting integration to manage your Chart of Accounts.",
  pleaseCreateAccounts: "Please create account codes from your integrated accounting platform.",
  setCode: "Set Code",
  endOfDayReports: "End of Day Reports",
  reviewYourDailyVenueActivity: "Review your daily venue activity. ",
  businessDay: "Business Day",
  timeStarted: "Time Started",
  timeEnded: "Time Ended",
  shifts: "Shifts",
  addNewAccountCode: "Add new account code",
  addNewAccountCodeName: "Add new account code “{0}”",
  accountCode: "Account Code",
  accountName: "Account Name",
  addNewAccount: "Add new account",
  typeToSetAccountCode: "Type to set/select an account code.",
  accountCodeAlreadyExist: "Account code already exists.",
  accountCodeAddedName: "Account Code “{0}“ added.",
  runEndOfDay: "Run End of Day",
  syncToMews: "Sync to MEWS",
  export: "Export",
  open: "Open",
  theShiftAndTransactionDetails:
    "The shift and transactions details will display here once the shift is closed on the register.",
  asset: "Asset",
  liability: "Liability",
  equity: "Equity",
  revenue: "Revenue",
  expense: "Expense",
  unknown: "Unknown",
  thisAppWouldRead: "This app would read and/or write the following information:",
  wouldReadOrWrite: "{0} would read and/or write the following information:",
  endOfDay: "End of Day",
  endDay: "End Day",
  averageAmountPerItem: "Ave. Amount per Item",
  noAccountsAvaiable: "No Account(s) Available",
  allAccountTypes: "All Account Types",
  endOfDayChecklist: "End of Day Checklist",
  allShiftsClosed: "All Shifts Closed",
  allDevicesUsed: "All Devices Used",
  selectAccountType: "Select Account Type",
  accountNotFound: "Account not found",
  suggested: "Suggested",
  accounts: "Accounts",
  ignore: "Ignore",
  unignore: "Unignore",
  endOfDayCompleted: "End of Day Completed",
  endOfDayFailed: "Failed to Complete End of Day",
  mewsAccounting: "MEWS_Accounting",
  mewsBookings: "MEWS_Bookings",
  cloudbeds: "Cloudbeds",
  purchasePlus: "PurchasePlus",
  connect: "Connect",
  viewInfo: "View Info",
  comingSoon: "Coming Soon",
  upToDate: "Up to date",
  salesSummary: "Sales Summary",
  shiftsAndTransactions: "Shifts and Transactions",
  daySummary: "Day Summary",
  reportingDay: "Reporting Day",
  dayStart: "Day Start",
  dayEnd: "Day End",
  totalOpeningBalance: "Total Opening Balance",
  totalExpectedEndingBalance: "Total Expected Ending Balance",
  endedBy: "Ended by",
  opened: "Opened",
  endOfDayReportsListPage: "End of Day Reports List Page",
  voucherReachedIssuanceLimit: "This voucher has reached its maximum issuance limit of {0}.",
  bookingPrice: "Booking Price",
  theAmountTheGuestWillPay: "This is the amount the guest will pay for their booking.",
  ignoredShiftFromPrevious: "Ignored shift from previous EOD",
  noRecords: "No records.",
  viewPrinter: "View Printer",
  createAccountCode: "Create account code",
  createVenuePage: "Create Venue Page",
  onboardingTitle: "Welcome! Start setting up your venue.",
  onboardingDescription: "Setup your venue with these simple steps to get your venue started.",
  onboardingTitleCompleted: "Your venue is ready to go!",
  onboardingDescriptionCompleted:
    "Awesome! You can now start selling on your PouchVENUE POS devices! Order your NFC tags now to do cashless transactions with door lock activation! Check out our ebook as well on how to optimize your venue!",
  orderYour: "Order your",
  nfcTags: "NFC tags",
  nowToDo: "now to do ",
  cashlessTransactionsWithDoor: "cashless transactions with door lock activation! ",
  checkoutOurEbook: "Check out our ebook as well on how to optimize your venue!",
  addASupplyItem: "Add a Supply Item",
  addAProduct: "Add a Product",
  inviteYourStaff: "Invite Your Staff",
  downloadAndSync: "Download PouchVENUE App and Sync your first transaction",
  getPouchVENUEeBook: "Get PouchVENUE eBook",
  night: "night",
  available: "Available",
  maximumLengthCharacters: "Maximum {0} character(s).",
  minimumCharactersAllowed: "Minimum of {0} characters allowed",
  maximumValueIs: "Maximum value is",
  minimumValueIs: "Minimum value is",
  minimum: "Minimum",
  isAreserveWord: "is a reserve word.",
  valueBetween: "Value should be between",
  checkinAmount: "Check-in Amount",
  activateOnOnlineMenu: "Activate on Online Menu",
  connectPurchasePlus: "Connect Purchase Plus to your PouchNATION account.",
  pleaseEnterYourSFTP: "Please enter your SFTP server details and credentials.",
  editSFTPDetails: "Edit SFTP Details",
  hostname: "Hostname",
  directory: "Directory",
  username: "Username",
  tableManagement: "Table Management",
  manageLocationTable: "Manage this location’s table and seating arrangements.",
  tables: "Tables",
  thisLocationHasTables: "This location has tables and seating arrangements.",
  selectNumberOfTables: "Select the number of tables",
  defaultTableName: "Default Table Name",
  customName: "Custom Name",
  displayedIn: "Displayed In",
  posOnly: "POS Only",
  onlineMenuOnly: "Online Menu Only",
  posAndOnlineMenu: "POS + Online Menu",
  disabled: "Disabled",
  generateQRCode: "Generate QR Code",
  qrCodeForOnlineMenu: "QR Code for Online Menu",
  generateQRDescription:
    "Generate and print out QR codes for customers to scan and order from this location’s online menu.",
  locationQRCode: "Location QR Code (One for the whole location)",
  tableQRCode: "Table QR Codes (Per table)",
  generateAndDownload: "Generate & Download",
  noOfTables: "# of Tables",
  purchaseplus: "Purchase Plus",
  showOnlineMenu: "Show in Online Menu",
  hideOnlineMenu: "Hide in Online Menu",
  customNameIsAlreadyExisting: "Custom name is already existing.",
  mewsSystems: "MEWS Systems",
  mewsBookings1: "MEWS Bookings",
  mewsAccounting1: "MEWS Accounting",
  mews: "MEWS",
  noSearchFound: "No Search Found",
  selectCountry: "Select Country",
  ignoreAllShifts: "Ignore All Shifts",
  ignoreAllOpenShifts: "Ignore All Open Shifts",
  unignoreAllShifts: "Unignore All Shifts",
  unignoreAllOpenShifts: "Unignore All Open Shifts",
  taxCode: "Tax Code",
  selectRedeemableProducts: "Select Redeemable Products",
  specificCategories: "Specific Categories",
  specificProducts: "Specific Products",
  searchCategories: "Search and select categories for this voucher",
  editDetails: "Edit Details",
  credentialsAreInvalid:
    "Oops, some credentials are invalid. Please enter the correct data and try again.",
  orderYourNFCTags: "Order Your NFC Tags",
  forProperReportingAndTo:
    "For proper reporting and to keep your dashboard up-to-date, please run the End of Day daily to avoid any data loss.",
  forProperReportingWithYourMews:
    "For proper reporting with your MEWS account, please make sure that your accounting editable history window is set to 7 days. Run the End of Day daily to avoid any data loss.",
  thisShiftHasUnsyncedTransactions: "This shift has unsynced transactions",
  runningTheEodWithUnsynced:
    "Running the EOD with unsynced transactions will result to an incomplete report.",
  someShiftsHaveUnsynced:
    "Some shifts have unsynced transactions. Please check the shifts' devices to push the venue data.",
  sellingQtyMustBeGreaterThanZero: "Selling quantity must be greater than zero.",
  linkOutlet: "Link Outlet",
  selectOutlet: "Select Outlet",
  linkOutletHoverTooltip: "Link to MEWS Outlets",
  outlet: "outlet",
  bookingStatus: "Booking Status",
  guestsStatus: "Guests Status",
  theBookingStatus: "The booking status from source.",
  theActualNumberOfGuests: "The actual number of guests that are checked-in/out",
  noOfCheckedIn: "{0} checked-in",
  noOfCheckedOut: "{0} checked-out",
  none: "None",
  referenceNo: "Ref. No.",
  totalRefunded: "Total Refunded",
  reportAProblem: "Report a Problem",
  scheduleACall: "Schedule a Call",
  helpCenter: "Help Center",
  confirmationPending: "Confirmation Pending",
  noShow: "No Show",
  inHouse: "In-House",
  supportPouchnation: "support@pouchnation.com",
  pleaseEmailUs: "Please email us at {0} {1}",
  toScheduleACall: "To schedule a call with our customer support.",
};

export default Object.freeze(locale);
