import React from "react";
import ChartContainer from "../chart-container";
import { MultiLineChart } from "components/commons";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import lang from "translations";

const TotalSalesLineChart = ({ ...props }) => {
  const data = props.mappedData;
  const chartColors = ["#77C5DD", "#93328E"];

  return (
    <ChartContainer {...props} empty={!data.creditSales} emptyText={lang.noSalesInDateRange}>
      <MultiLineChart
        isLegendPointStyle={true}
        legendBoxWidth={12}
        position={"bottom"}
        checkBeginAtZero={true}
        align={"center"}
        chartColors={chartColors}
        height={330}
        {...data.data}
        formatToolTipValue={(value) => {
          return formatNumberToMoneyWithCurrencySymbol(value.value);
        }}
      />
    </ChartContainer>
  );
};

export default TotalSalesLineChart;
