import {
  Form,
  Modal,
  Field,
  Input,
  Checkbox,
  Text,
  Toast,
  ActionButton,
  Select,
  AlertMessage,
  Icon,
  // InputCounter
} from "components/commons";
import { useApi, useForm, useModal, useMount } from "hooks";
import React, { useCallback, useEffect, useMemo, useContext } from "react";
import lang from "translations";
import initialFormState from "./tax.form-state";
import DeleteTaxModal from "../delete-tax/delete-tax.modal";
import { mixpanel, TrackEvent } from "mixpanel";
import { VenueContext } from "contexts";
import { getAccountCodes } from "apis";
import { useState } from "react";
import { accountingResponse } from "mappers/accounting.mapper";
import { groupAccountOptions, mapAccountOptions } from "services/accounting.service";
import { useFlags } from "launchdarkly-react-client-sdk";

const TaxFormModal = ({
  title,
  active,
  close,
  primaryText,
  initialState = undefined,
  isEdit,
  save,
  submitting,
  id,
  refreshList,
  defaultTaxAccountMapping,
}) => {
  const unsaveChangesModal = useModal();
  const deleteTaxModal = useModal();
  const { venue } = useContext(VenueContext);
  const { venueId } = venue;
  const [searchKey, setSearchKey] = useState("");
  const [firstRender, setFirstRender] = useState(false);
  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);
  const { taxCodeFlag } = useFlags();
  const defaultTaxAccountCodeId = defaultTaxAccountMapping
    ? defaultTaxAccountMapping?.accountCodeId
    : null;

  const { dirty, fields, modifyField, clearForm, submitForm, getFormValues, applyFieldErrors } =
    useForm({
      initialState: formState,
    });

  const { request, mappedData, loading } = useApi({
    api: getAccountCodes,
    params: {
      businessId: venueId,
    },
    isArray: true,
    mapper: accountingResponse,
  });

  useMount(async () => {
    request();
    setFirstRender(true);
  });

  const exitForm = () => {
    clearForm();
    unsaveChangesModal.close();
  };

  const taxAccountOptions = useMemo(() => {
    return mapAccountOptions(mappedData);
  }, [mappedData]);

  const onClose = () => {
    if (dirty) {
      unsaveChangesModal.show({
        ok: () => {
          exitForm();
          setTimeout(() => {
            close();
          });
        },
      });
      return;
    }
    close();
    exitForm();
  };

  useEffect(() => {
    if (active) {
      setFirstRender(true);
      clearForm();
    }
    // eslint-disable-next-line
  }, [active]);

  const onSave = useCallback(() => {
    save(
      getFormValues(),
      (res, { message }) => {
        Toast({
          content: message,
          success: true,
          icon: "check",
        }).open();
        close();
        clearForm();
      },
      ({ code, handleError }) => {
        const err = {
          3079: () => {
            applyFieldErrors({
              name: lang.taxAlreadyExists,
            });
          },
        };
        if (err[code]) {
          err[code]();
        } else {
          handleError();
        }
      }
    );
  }, [save, clearForm, close, getFormValues, applyFieldErrors]);

  return (
    <div>
      <Modal
        title={title}
        noCloseButton
        active={active}
        hidden={unsaveChangesModal.active || deleteTaxModal.active}
        onClose={() => onClose()}
        primaryText={primaryText}
        loading={submitting}
        actionContent={
          <ActionButton
            loading={submitting}
            className="m-md pb-md"
            mb="md"
            primary={{
              text: primaryText,
              onClick: () => {
                mixpanel.track(TrackEvent.ClickedButton, {
                  Button: lang.saveTaxSetting,
                  Page: lang.taxSettings,
                });
                submitForm(onSave);
              },
              disabled: !dirty,
            }}
            secondary={{
              onClick: () => {
                onClose();
              },
              text: lang.cancel,
            }}
            danger={
              isEdit && !initialState.isDefault
                ? {
                    text: lang.deleteTax,
                    onClick: () => deleteTaxModal.show(),
                  }
                : null
            }
          />
        }
      >
        <Form unsaveChangesModal={unsaveChangesModal}>
          <Field {...fields.name} label={lang.taxName}>
            <Input required {...fields.name} onChange={modifyField} />
          </Field>
          <div className="flex gap-2">
            {taxCodeFlag && (
              <Field {...fields.taxCode} label={lang.taxCode} width="w-1/2">
                <Input {...fields.taxCode} onChange={modifyField} />
              </Field>
            )}
            <Field {...fields.percentage} label={lang.taxRate} width="w-1/2" className="mb-sm">
              <Input
                required
                {...fields.percentage}
                iconSuffix={<span className="text-xs text-gray pr-xs">%</span>}
                onChange={(name, { value }) => {
                  if (!value || (Number(value) && Number(value) >= "0") || Number(value) === "0") {
                    modifyField(name, {
                      value,
                    });
                  }
                }}
              />
            </Field>
          </div>
          <Field {...fields.accountCodeId} label="Tax on Sales Liability Account">
            <Select
              {...fields.accountCodeId}
              loading={loading}
              fetchingOptions={loading}
              options={taxAccountOptions}
              groupedOptions={groupAccountOptions(
                taxAccountOptions,
                fields.accountCodeId.suggested,
                true
              )}
              value={
                ((!isEdit && defaultTaxAccountMapping) ||
                  (isEdit && !fields.accountCodeId.value)) &&
                firstRender
                  ? defaultTaxAccountCodeId
                  : fields.accountCodeId.value
              }
              onChange={(name, { value }) => {
                setFirstRender(false);
                modifyField(name, { value: value });
              }}
              searchable
              searchValue={searchKey}
              onSearch={(val) => {
                setSearchKey(val);
              }}
              customNotFoundContent={lang.accountNotFound}
            />
          </Field>

          <div className="grid grid-cols-3">
            {isEdit && !initialState.isDefault && (
              <div className="flex items-center gap-2">
                <div>
                  <Field>
                    <Checkbox
                      value={fields.isDefault.value}
                      onChange={() => {
                        modifyField("isDefault", {
                          value: !fields.isDefault.value,
                        });
                      }}
                    />
                  </Field>
                </div>
                <div>
                  <Text className="mt-xs">{lang.setAsDefaultTax}</Text>
                </div>
              </div>
            )}
            <div className="flex items-center gap-2 col-span-2">
              <div>
                <Field>
                  <Checkbox
                    {...fields.isMewsTaxCode}
                    value={fields.isMewsTaxCode.value}
                    onChange={modifyField}
                  />
                </Field>
              </div>
              <div>
                <Text className="mt-xs">{lang.mewsTaxCode}</Text>
              </div>
            </div>
          </div>
          {!isEdit && <Text color="text-gray">{lang.defaultAccountForTaxes}</Text>}
          {isEdit && (
            <AlertMessage
              className="mt-md"
              banner
              customMessage={
                <div className="flex gap-2 items-center py-xs">
                  <Icon name="info" className="text-md text-blue" />
                  <Text>{lang.taxEditNote}</Text>
                </div>
              }
            />
          )}
        </Form>
      </Modal>
      <DeleteTaxModal
        {...deleteTaxModal}
        name={initialState?.name}
        id={id}
        goToTax={() => {
          close();
          refreshList();
        }}
      />
    </div>
  );
};

export default TaxFormModal;
