import { Text, Icon, Image, Pill } from "components/commons";
import { useCallback, useContext, useMemo, useState } from "react";
import { Popover } from "antd";
import lang from "translations";
import { PillType, ProductStockLevel } from "enums";
import { prettifyProductStockLevel } from "services";
import { DefaultImage } from "images";
import { productInfoResponse } from "mappers/product.mapper";
import { getProduct } from "apis";
import { useApi } from "hooks";
import { VenueContext } from "contexts";

const ProductNamePopover = ({
  productId,
  trigger = "hover",
  placement = "right",
  children = [],
}) => {
  const [visible, setVisible] = useState(false);
  const { venue } = useContext(VenueContext);
  const { venueId } = venue || {};

  const {
    request: getProductRequest,
    mappedData: mappedProduct,
    loading,
  } = useApi({
    api: getProduct,
    params: {
      productId: productId,
      venueId,
    },
    mapper: productInfoResponse,
  });

  const {
    stockLevel,
    name,
    productLogo,
    categories: productCategories = [],
    locations: productLocations = [],
    retailPriceRange,
    isActiveAllCategories = false,
    isActiveAllLocations = false,
    sellableInPos,
  } = mappedProduct || {};

  const togglePopover = useCallback(
    async (visibility) => {
      if (!visible) {
        await getProductRequest();
      }
      setVisible(visibility);
    },
    [getProductRequest, visible]
  );

  const categories = useMemo(() => {
    let label = "in";
    if (productCategories && productCategories.length !== null) {
    }
    if (isActiveAllCategories) {
      return (
        <div className=" text-sm ">
          {label} <span className="text-sm font-semibold">{lang.allCategories}</span>
        </div>
      );
    } else {
      return (
        <div className="text-sm w-48 mt-0 multi-line">
          {label}{" "}
          {(productCategories &&
            productCategories.length > 0 &&
            productCategories
              .map(({ value, text, color }) => {
                return (
                  <span
                    style={{ color } !== null ? { color } : "black"}
                    className="text-sm font-semibold"
                  >
                    {text}
                  </span>
                );
              })
              .reduce((combined, curr) => [combined, ", ", curr])) || (
            <span className="text-sm font-semibold">{lang.uncategorized}</span>
          )}
        </div>
      );
    }
  }, [productCategories, isActiveAllCategories]);

  const locations = useMemo(() => {
    let status = sellableInPos ? lang.active : lang.hidden;
    if (productLocations && productLocations.length !== null) {
    }
    if (isActiveAllLocations) {
      return (
        <div className=" text-sm ">
          <Icon name="vendor" className="text-sm text-gray pr-2"></Icon>
          {status} in
          <span className="text-sm font-semibold"> {lang.allLocations}</span>
        </div>
      );
    } else {
      if (productLocations && productLocations.length === 0) {
        return (
          <div className=" text-sm ">
            <Icon name="vendor" className="text-sm text-gray pr-2"></Icon>
            {status}
          </div>
        );
      }
      return (
        <div className="text-sm">
          <Icon name="vendor" className="text-sm text-gray pr-2"></Icon>
          {status} in
          {productLocations
            ?.map((locations) => {
              return <span className="text-sm font-semibold"> {locations.text}</span>;
            })
            .reduce((combined, curr) => [combined, ", ", curr]) || ""}
        </div>
      );
    }
  }, [productLocations, isActiveAllLocations, sellableInPos]);

  const stockLevelField = useMemo(() => {
    if (stockLevel === ProductStockLevel.InStock) {
      return (
        <Pill type={PillType.Greenish} size="text-xs">
          {prettifyProductStockLevel(stockLevel)}
        </Pill>
      );
    } else if (stockLevel === ProductStockLevel.CheckStock) {
      return (
        <Pill type={PillType.Yellow} size="text-xs">
          {prettifyProductStockLevel(stockLevel)}
        </Pill>
      );
    } else if (stockLevel === ProductStockLevel.ReorderStock) {
      return (
        <Pill type={PillType.Reddish} size="text-xs">
          {prettifyProductStockLevel(stockLevel)}
        </Pill>
      );
    } else if (stockLevel === ProductStockLevel.NoStock) {
      return (
        <Pill type={PillType.Opaque} size="text-xs">
          {prettifyProductStockLevel(stockLevel)}
        </Pill>
      );
    }
  }, [stockLevel]);

  const content = useMemo(() => {
    return (
      <div className="m-0 flex max-h-52 w-80">
        {!loading && productInfoResponse && (
          <>
            <Image src={productLogo || DefaultImage} className="h-32 w-32 pr-md rounded" />
            <div className="flex-1 gap-3 justify-left">
              <div className=" w-48  pb-2.5">
                <Text className="text-base font-bold truncate">{name}</Text>
                {productCategories && categories}
              </div>
              <div className="grid-rows-3 space-y-1">
                <div className="text-sm">
                  <Icon name="currency-circle" className="text-sm text-gray pr-2" />
                  <span className="text-sm">{retailPriceRange}</span>
                </div>
                <div>{productLocations && locations}</div>
                <div>
                  <div className="flex items-center">
                    <Icon name="inventory" className="text-sm text-gray pr-2" />
                    {stockLevelField}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }, [
    loading,
    productLocations,
    productCategories,
    stockLevelField,
    productLogo,
    retailPriceRange,
    categories,
    locations,
    name,
  ]);

  return (
    <>
      <Popover
        content={content}
        onVisibleChange={togglePopover}
        trigger={trigger}
        visible={visible}
        placement={placement}
        mouseEnterDelay={0.3}
      >
        {children}
      </Popover>
    </>
  );
};

export default ProductNamePopover;
