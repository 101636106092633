import { searchBookings } from "apis/booking.api";
import classNames from "classnames";
import { ButtonLink, DataTable, Text, Tooltip, Icon } from "components/commons";
import { ModuleWrapper } from "components/fragments";
import { HeaderA } from "components/headers";
import { VenueContext } from "contexts";
import { BookingStatus, DateTime, IntegrationType } from "enums";
import { useApi, useFilter, useMount } from "hooks";
import { bookingListFilterRequest, bookingListResponse } from "mappers/booking.mapper";
import { mixpanel } from "mixpanel";
import { Path } from "paths";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { formatDate, mapObject, prettifyIntegrationType, normalizeBookingStatus } from "services";
import lang from "translations";
import BookingStatusPill from "../booking-status-pill";
import BookingFilter from "./booking-filter";
import BookingSummary from "./booking-summary.module";
import columns from "./columns";
import { bookingListFilterState } from "./filters";
import { useFlags } from "launchdarkly-react-client-sdk";

const BookingList = () => {
  const { venue } = useContext(VenueContext);
  const { bookingListImprovement } = useFlags();

  const {
    request: searchBookingsRequest,
    error,
    mappedData,
    result: searchBookingsResult = { data: [], metadata: { total: 0 } },
    loading,
  } = useApi({
    api: searchBookings,
    isArray: true,
    handleOwnError: true,
    mapper: bookingListResponse,
  });

  const { modifyFilter, clearFilter, filterState, requestState, modifyFilters, isFilterDirty } =
    useFilter(bookingListFilterState(venue.venueId));

  const [hasAppliedFilter, setHasAppliedFilter] = useState(isFilterDirty);

  useMount(() => {
    fetchBookings(requestState);
  });

  const fetchBookings = useCallback(
    (requestState) => {
      searchBookingsRequest(mapObject(requestState, bookingListFilterRequest));
    },
    [searchBookingsRequest]
  );

  const applyFilterCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({
        page: 1,
        searchKey,
      });
      fetchBookings(requestState);
      setHasAppliedFilter(true);
    },
    [fetchBookings, modifyFilters]
  );

  const clearFilterCb = useCallback(() => {
    const { requestState } = clearFilter();
    fetchBookings(requestState);
    setHasAppliedFilter(false);
  }, [clearFilter, fetchBookings]);

  useMount(() => {
    mixpanel.track(TrackEvent.VisitedPage, {
      Page: lang.bookingList,
    });
  });

  const prepareProductList = useCallback(
    (mappedData) => {
      return mappedData.map((bookingItem) => {
        const {
          id,
          bookingNumber,
          startTime,
          endTime,
          bookedBy,
          noOfNights,
          noOfGuests,
          emailAddress,
          source = "",
          status,
          rawStatus,
          noOfCheckedIn = 0,
          noOfCheckedOut = 0,
        } = bookingItem;

        return {
          id: (
            <>
              <ButtonLink
                className="cursor-pointer whitespace-nowrap text-blue"
                path={Path.BOOKING_ID(id)}
              >
                {bookingNumber}
              </ButtonLink>
              {bookingListImprovement && (
                <Text color="text-gray">{prettifyIntegrationType(source)}</Text>
              )}
            </>
          ),
          startTime: (
            <>
              <Text className="whitespace-nowrap">{formatDate(startTime, DateTime.C)}</Text>
              {source !== IntegrationType.CLOUDBEDS && (
                <Text color="text-gray">{formatDate(startTime, DateTime.B)}</Text>
              )}
            </>
          ),
          endTime: (
            <>
              <Text className="whitespace-nowrap">{formatDate(endTime, DateTime.C)}</Text>
              {source !== IntegrationType.CLOUDBEDS && (
                <Text color="text-gray">{formatDate(endTime, DateTime.B)}</Text>
              )}
            </>
          ),
          bookedBy: (
            <>
              <Text>{bookedBy}</Text>
              <Text color="text-gray">{emailAddress}</Text>
            </>
          ),
          noOfNights: <Text>{noOfNights}</Text>,
          noOfGuests: <Text>{noOfGuests}</Text>,
          bookingStatus: bookingListImprovement ? (
            <BookingStatusPill
              status={
                source !== IntegrationType.CLOUDBEDS
                  ? normalizeBookingStatus(bookingItem)
                  : rawStatus
              }
              source={source}
            ></BookingStatusPill>
          ) : (
            <BookingStatusPill status={normalizeBookingStatus(bookingItem)}></BookingStatusPill>
          ),
          guestsStatus: bookingListImprovement && (
            <div className="flex flex-col text-sm">
              <lang.Translate
                className={classNames("text-sm", {
                  "text-green": status === BookingStatus.CHECKED_IN || BookingStatus.IN_HOUSE,
                  "text-red":
                    status === BookingStatus.CHECKED_OUT ||
                    BookingStatus.MISSED_CHECKED_OUT ||
                    BookingStatus.NO_SHOW ||
                    BookingStatus.CANCELLED,
                })}
                text={lang.noOfCheckedIn}
                items={[<span className="font-semibold text-sm">{noOfCheckedIn}</span>]}
              />
              <lang.Translate
                className="text-sm text-gray"
                text={lang.noOfCheckedOut}
                items={[<span className="font-semibold text-sm">{noOfCheckedOut}</span>]}
              />
              <Text></Text>
            </div>
          ),
        };
      });
    },
    [bookingListImprovement]
  );

  const bookings = useMemo(() => {
    return prepareProductList(mappedData);
  }, [mappedData, prepareProductList]);

  return (
    <ModuleWrapper
      header={<HeaderA title={lang.bookingList} description={lang.bookingDescription} />}
    >
      <>
        <BookingFilter
          filterState={filterState}
          requestState={requestState}
          modifyFilter={modifyFilter}
          modifyFilters={modifyFilters}
          fetchBookings={fetchBookings}
          applyFilter={applyFilterCb}
          clearFilter={clearFilterCb}
        />
        <BookingSummary
          totalBookings={searchBookingsResult?.metadata?.count}
          toBeCheckedInCount={searchBookingsResult?.metadata?.toBeCheckedIn}
          toBeCheckedOutCount={searchBookingsResult?.metadata?.toBeCheckedOut}
        />
        <DataTable
          error={error}
          loading={loading}
          total={searchBookingsResult?.metadata?.count || 0}
          columns={[
            ...columns,
            {
              key: "bookingStatus",
              text: (
                <div className="flex items-center gap-2">
                  <Text fontWeight="font-semibold" color="text-washed-dark">
                    {bookingListImprovement ? lang.bookingStatus : lang.status}
                  </Text>

                  {bookingListImprovement && (
                    <Tooltip
                      title={
                        <Text className="text-center" color="text-white">
                          {lang.theBookingStatus}
                        </Text>
                      }
                      placement="top"
                    >
                      <span>
                        <Icon name="info" fontSize="14px" color="text-gray-light"></Icon>
                      </span>
                    </Tooltip>
                  )}
                </div>
              ),
            },
            bookingListImprovement && {
              key: "guestsStatus",
              text: (
                <div className="flex items-center gap-2">
                  <Text fontWeight="font-semibold" color="text-washed-dark">
                    {lang.guestsStatus}
                  </Text>
                  <Tooltip
                    title={
                      <Text className="text-center" color="text-white">
                        {lang.theActualNumberOfGuests}
                      </Text>
                    }
                    arrowPointAtCenter
                    placement="topRight"
                  >
                    <span>
                      <Icon name="info" fontSize="14px" color="text-gray-light" paddingless></Icon>
                    </span>
                  </Tooltip>
                </div>
              ),
            },
          ]}
          hasAppliedFilter={hasAppliedFilter}
          customNoResultError={lang.noResults}
          customNoResultErrorDesc={lang.cantFindDesc}
          renderEmpty={{ title: lang.noBookingsYet, description: lang.youllFindYourBookingsHere }}
          data={bookings}
          onChangePage={modifyFilters}
          fetchList={fetchBookings}
          page={filterState.page}
          pageSize={filterState.pageSize}
          minWidth="800px"
        />
      </>
    </ModuleWrapper>
  );
};

export default BookingList;
