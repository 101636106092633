import React from "react";
import lang from "translations";
import { HeaderC } from "components/headers";
import { Text, Avatar, Title, TabBar, Skeleton, ButtonNavigation, Pill } from "components/commons";
import { useRouter } from "hooks";
import { Path } from "paths";
import { formatNumberToOrdinal, redirectTo } from "services";
import { PillType } from "enums";

const GuestDetailsHeader = ({ mappedData, loading, mappedGuestCheckin }) => {
  const { query } = useRouter();
  const { id } = query;
  const {
    name = null,
    firstName = "",
    lastName,
    guestSince,
    totalVisits,
    imageUrl,
    nextGuestId,
    prevGuestId,
  } = mappedData;

  const {
    pairedTagOnCheckin: tagUid,
    useCountOfPairedTag: useCount,
    checkedIn,
    guestTagUids = [],
  } = mappedGuestCheckin || {};

  const avatar = {
    className: "m-auto bg-pelorous",
    phrases: [firstName, lastName],
    loading: loading,
    image: imageUrl ?? false,
    defaultIconAvatar: "account-settings",
    size: 35,
  };

  const headerTitleContent = !loading && (
    <div className="flex mt-md">
      <div className="self-center">
        <Avatar height="h-20" width="w-20" textSize="text-4xl" {...avatar}></Avatar>
      </div>
      <div className="self-center ml-md">
        <div className="flex items-center mb-sm">
          <Title xl>
            {name ??
              (tagUid === undefined && useCount === undefined ? (
                <Skeleton single />
              ) : (
                tagUid ?? guestTagUids[0]?.tagUid
              ))}
          </Title>
          {!name && (
            <Pill className="ml-sm" type={checkedIn ? PillType.Blueish : PillType.White}>
              {formatNumberToOrdinal(useCount ?? guestTagUids[0]?.useCount)} use
            </Pill>
          )}
        </div>

        <div className="grid grid-cols-5 gap-4">
          <Text className="text-gray-lighter col-span-2">
            <lang.Translate
              text={lang.guestSince}
              items={[<b className="text-black">{guestSince}</b>]}
            />
          </Text>
          <Text className="text-gray-lighter col-span-2">
            <lang.Translate
              text={lang.totalVisits}
              items={[<b className="text-black">{totalVisits}</b>]}
            />
          </Text>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <HeaderC
        title={headerTitleContent}
        className="mb-sm"
        returnText={lang.guests}
        returnPath={Path.GUEST}
        action={
          <div>
            <ButtonNavigation
              loading={loading}
              disabledLeft={!prevGuestId}
              disabledRight={!nextGuestId}
              onClickLeft={() => {
                if (prevGuestId) {
                  redirectTo(Path.GUEST_DETAILS_ID(prevGuestId));
                }
                console.log("onclick left", prevGuestId);
              }}
              onClickRight={() => {
                if (nextGuestId) {
                  redirectTo(Path.GUEST_DETAILS_ID(nextGuestId));
                }
              }}
            />
          </div>
        }
      />
      <TabBar
        className="mb-md"
        items={[
          { to: Path.GUEST_DETAILS_ID(id), text: lang.overview },
          { to: Path.GUEST_DETAILS_PROFILE_ID(id), text: lang.profile },
        ]}
      ></TabBar>
    </>
  );
};

export default GuestDetailsHeader;
