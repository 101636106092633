import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Path } from "paths";

const AuthorizedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return localStorage.getItem("accessToken") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `${Path.AUTH}`,
              state: {
                next: rest.location.pathname,
                venue: rest.location.search || localStorage.getItem("venueId"),
              },
            }}
          />
        );
      }}
    />
  );
};

export default AuthorizedRoute;
