import { getGlobalDateTimeFilter } from "services";

export const voucherReportFilterState = (venueId) => {
  const dateTime = getGlobalDateTimeFilter();
  return {
    venueId,
    page: 1,
    pageSize: 20,
    searchKey: "",
    dateRange: [dateTime[0], dateTime[1]],
    locations: [],
    voucher: null,
    sort: { key: "supplyValue", value: "desc" },
  };
};
