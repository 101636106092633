import moment from "moment";

export const voucherMovementFilterState = (venueId) => ({
  venueId,
  page: 1,
  pageSize: 20,
  searchKey: "",
  dateRange: [moment().startOf("month"), moment().endOf("day")],
  locations: [],
  staffs: [],
  voucherTypes: [],
  tapStatus: null,
  sort: { key: "dateCreated", value: "desc" },
});
