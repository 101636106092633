import React from "react";
import { TotalSalesChart, TotalSalesLineChart } from "./chart";
import classnames from "classnames";

const SalesCharts = ({ graphOfTotalSales, lineChartTotalSales }) => {
  return (
    <div className={classnames("md:grid grid-cols-12")}>
      <div className="lg:col-span-4 md:col-span-4 col-span-8">
        <TotalSalesChart {...graphOfTotalSales} />
      </div>
      <div className="lg:col-span-8 md:col-span-8 col-span-12">
        <TotalSalesLineChart className="py-4" {...lineChartTotalSales} />
      </div>
    </div>
  );
};

export default SalesCharts;
