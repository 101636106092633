import { Field, Filter, RangePicker, Text, TimePicker, MultipleSelect } from "components/commons";
import React, { useCallback, useEffect } from "react";
import lang from "translations";
import { useApi, useMount, useFilter } from "hooks";
import { dashboardFilterState } from "./filters";
import { debounced, modifyFromTimeRange, modifyToTimeRange } from "services";
import { searchLocationWithTransaction } from "apis";
import { toApiDateTimeFormat } from "services/date.service";

const DashboardFilter = ({ venueId, fetchCharts }) => {
  const { modifyFilters, modifyFilter, clearFilter, filterState } = useFilter(
    dashboardFilterState(venueId)
  );

  const { request: searchLocationRequest, loading, mappedData: locationOptions, error } = useApi({
    api: searchLocationWithTransaction,
    params: {
      venueId,
    },
    mapper: {
      value: {
        key: "locationId",
      },
      text: {
        key: "locationName",
      },
    },
    isArray: true,
    handleOwnError: true,
  });

  const submit = useCallback(
    (filter) => {
      fetchCharts({
        ...filter,
        locationIds: filter.locationIds.map((l) => {
          return l.value;
        }),
      });
    },
    [fetchCharts]
  );

  const applyFilterCb = useCallback(() => {
    const { requestState } = modifyFilters({ page: 1 });
    submit(requestState);
  }, [modifyFilters, submit]);

  const clearFilterCb = useCallback(() => {
    const { requestState } = clearFilter();
    submit(requestState);
  }, [clearFilter, submit]);

  useMount(() => {
    const dateRange = filterState.dateRange;
    searchLocationRequest({
      venueId,
      startDateTime: toApiDateTimeFormat(dateRange[0]),
      endDateTime: toApiDateTimeFormat(dateRange[1]),
    });
    submit(filterState);
  });

  //eslint-disable-next-line
  useEffect(
    debounced(1000, () => {
      const dateRange = filterState.dateRange;
      searchLocationRequest({
        venueId,
        startDateTime: toApiDateTimeFormat(dateRange[0]),
        endDateTime: toApiDateTimeFormat(dateRange[1]),
      });
    }),
    [filterState.dateRange]
  );

  return (
    <Filter
      className="my-lg"
      onClear={clearFilterCb}
      onApply={applyFilterCb}
      searchBox={false}
      filterState={filterState}
      actionsSpan={3}
    >
      <Field className="col-span-3" filterLabel={lang.dateRange}>
        <RangePicker
          value={filterState.dateRange}
          name="dateRange"
          onChange={(name, value) => {
            if (value) {
              modifyFilter(name, {
                value,
              });
            }
          }}
        />
      </Field>
      <Field className="col-span-3" filterLabel={lang.timeRange}>
        <div className="flex items-center">
          <TimePicker
            value={filterState.dateRange[0]}
            onChange={(val) => {
              modifyFromTimeRange(val, filterState, modifyFilter);
            }}
          />
          <Text className="mx-sm" color="text-black-light">
            to
          </Text>
          <TimePicker
            value={filterState.dateRange[1]}
            onChange={(val) => {
              modifyToTimeRange(val, filterState, modifyFilter);
            }}
          />
        </div>
      </Field>
      <Field className="col-span-3" filterLabel={lang.location}>
        <MultipleSelect
          name="location"
          error={error}
          disabled={error || !locationOptions.length}
          emptyPlaceHolder={lang.noLocationAvailable}
          selectAllText={lang.allLocations}
          loading={loading}
          options={locationOptions}
          value={filterState.locationIds}
          onChange={(name, { value }) => {
            modifyFilters({
              locationIds: value,
            });
          }}
          placeholder={lang.selectLocation}
        />
      </Field>
    </Filter>
  );
};

export default DashboardFilter;
