import { getGlobalDateTimeFilter } from "services";

export const salesReportFilterState = (venueId) => {
  const dateTime = getGlobalDateTimeFilter();
  return {
    venueId,
    page: 1,
    pageSize: 10,
    searchKey: "",
    dateRange: [dateTime[0], dateTime[1]],
  };
};
