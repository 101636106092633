import lang from "translations";
import {
  formatName,
  prepareChart,
  denormalizePlotDateTimeFromApiResponse,
  getApiDateTimeFormatByTimeUnit,
  getDateTimeFormatByTimeUnit,
  formatNumberWithComma,
  formatNumberToOrdinal,
  timeAgo,
  prettifyTransactionType,
  prettifyPaymentMethod,
} from "services";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import * as moment from "moment";
import {
  dateTimeRequest,
  dateToTimeAgo,
  filterRequest,
  selectToObjectRequest,
} from "./common.mapper";
import { DateTime, PaymentMethod, TransactionType } from "enums";
import { add, subtract, multiply, divide, toAmount } from "services/number.service";
import { getCurrentTimeWithTimezone } from "./common.mapper";
import { Text } from "components";

export const transactionListResponse = {
  transactionId: { key: "transactionId" },
  dateCreated: dateToTimeAgo("deviceUpdatedDate"),
  guestId: { key: "guestId" },
  guestName: {
    transform: ({ src }) => {
      return formatName(src.userFirstName, src.userLastName);
    },
  },
  userTagUid: { key: "userTagUid" },
  locationName: { key: "locationName" },
  locationId: { key: "locationId" },
  staffName: {
    transform: ({ src }) => {
      return formatName(src.staffFirstName, src.staffLastName);
    },
  },
  staffTagUid: { key: "staffTagUid" },
  staffProfileId: { key: "staffProfileId" },
  transactionStatus: { key: "transactionStatus" },
  transactionType: { key: "type" },
  tapStatus: { key: "nfcStatus" },
  paidAmount: {
    transform: ({ self }) => {
      return formatNumberToMoneyWithCurrencySymbol(self);
    },
  },
  items: {
    transform: ({ src }) => {
      if (src.paymentMethod && src.type === TransactionType.TOPUP) {
        return [prettifyPaymentMethod(src.paymentMethod)];
      }
      if (src?.transactionItems) {
        return src.transactionItems.map((item) => {
          const variants = item.productVariants?.length
            ? item.productVariants?.map((variant) => {
                return variant.value;
              })
            : [];

          if (variants?.length) {
            return `${item.productName} · ${variants.join(" · ")}`;
          }
          return item.productName;
        });
      }
      return [""];
    },
  },
  qty: {
    transform: ({ src }) => {
      let qty = 0;
      if (src?.transactionItems.length) {
        src.transactionItems.forEach((item) => {
          qty = qty + item.quantity;
        });
        return toAmount(qty, "0,0");
      }

      return "";
    },
  },
  paymentMethod: {
    key: "paymentMethod",
  },
};

export const transactionListFilterRequest = {
  ...filterRequest,
  ...dateTimeRequest,
  orders: {
    transform: ({ src }) => {
      return { [`t.deviceCreatedDate`]: src.sort.value };
    },
  },
  locationIds: selectToObjectRequest("locations"),
  staffIds: selectToObjectRequest("staffs"),
  shiftIds: {
    transform: ({ src }) => {
      if (src.shifts.length > 0) {
        return src.shifts.split(",").map((s) => parseInt(s));
      }
      return [];
    },
  },
  transactionStatus: { key: "transactionStatus" },
  transactionTypes: {
    transform: ({ self }) => {
      if (!self || self.length === 0 || self[0] === undefined) {
        return [
          TransactionType.SALE,
          TransactionType.TOPUP,
          TransactionType.RETURN,
          TransactionType.REDEEM,
          TransactionType.MIXED,
          TransactionType.ISSUE_FOC,
          TransactionType.REMOVE_FOC,
        ];
      } else {
        return self.map((transactionType) => {
          return transactionType.value;
        });
      }
    },
  },
  nfcStatus: { key: "tapStatus" },
};

export const graphLineChartOfTotalSale = {
  data: {
    transform: ({ src, params }) => {
      const plot = denormalizePlotDateTimeFromApiResponse(src.plot, params.splitType);
      return prepareChart(plot);
    },
  },
  total: {
    transform: ({ src }) => {
      return formatNumberToMoneyWithCurrencySymbol(src.totalSales);
    },
  },
  _keys: ["totalSales"],
};

export const graphMultiLineChartOfTotalSale = {
  data: {
    transform: ({ src, params }) => {
      let labels = [];
      const data = src.locations.map((location) => {
        const plot = prepareChart(
          denormalizePlotDateTimeFromApiResponse(location.plot, params.splitType)
        );
        labels = plot.labels;
        return {
          label: location.location.locationName,
          ...plot,
        };
      });
      return {
        data,
        labels,
      };
    },
  },
  total: {
    transform: ({ src }) => {
      let total = 0;
      src.locations.forEach((location) => {
        total = location.totalSales + total;
      });
      return formatNumberToMoneyWithCurrencySymbol(total);
    },
  },
  totalSale: {
    transform: ({ src }) => {
      let total = 0;
      src.locations.forEach((location) => {
        total = location.totalSales + total;
      });
      return total;
    },
  },
};

export const salesReportListRequest = {
  ...filterRequest,
  ...dateTimeRequest,
  totalSales: { key: "totalSales" },
  requestedAt: getCurrentTimeWithTimezone(),
};

export const graphPieChartOfTopLocationSales = {
  name: {
    transform: ({ src }) => {
      return src.locationName;
    },
  },
  value: {
    transform: ({ src }) => {
      return src.totalSale;
    },
  },
  _keys: ["totalSale"],
};

const barSetting = {
  CASH: {
    label: lang.cash,
    color: "#FDC02F",
  },
  CREDIT: {
    label: lang.card,
    color: "#FED45B",
  },
  OTHERS: {
    label: lang.others,
    color: "#FAE3A1",
  },
};

const barSettingTotalSales = {
  SALES_CREDITS: {
    label: lang.credits,
    color: "#FDC02F",
  },
  SALES_FREE_CREDITS: {
    label: lang.freeCredits,
    color: "#FED45B",
  },
};

export const graphTotalSales = {
  sales: { key: "sales" },
  salesCredits: { key: "salesCredits" },
  salesFreeCredits: { key: "salesFreeCredits" },
  grossProfit: { key: "grossProfit" },
  supplyPrice: { key: "supplyPrice" },
  taxes: { key: "taxes" },
  discounts: { key: "discounts" },
  voidedItems: { key: "voidedItems" },
  voidedValue: { key: "voidedValue" },
  itemsSold: { key: "itemsSold" },
  barChartData: {
    transform: ({ src }) => {
      let credits = [
        {
          label: barSettingTotalSales.SALES_CREDITS.label,
          color: barSettingTotalSales.SALES_CREDITS.color,
          value: src.salesCredits,
        },
        {
          label: barSettingTotalSales.SALES_FREE_CREDITS.label,
          color: barSettingTotalSales.SALES_FREE_CREDITS.color,
          value: src.salesFreeCredits,
        },
      ];
      return credits;
    },
  },
};

export const lineGraphTotalSales = {
  data: {
    transform: ({ src, params }) => {
      let creditSalesPlot = [];
      let freeCreditSalesPlot = [];

      src.plot.forEach((plot) => {
        const keys = Object.keys(plot);
        keys.forEach((key, index) => {
          creditSalesPlot.push({
            [key]: plot[key].creditSales,
          });
          freeCreditSalesPlot.push({
            [key]: plot[key].freeCreditSales,
          });
        });
      });

      const chartForCreditSales = prepareChart(
        denormalizePlotDateTimeFromApiResponse(creditSalesPlot, params.splitType)
      );
      const chartForFreeCreditSales = prepareChart(
        denormalizePlotDateTimeFromApiResponse(freeCreditSalesPlot, params.splitType)
      );
      let labels = chartForCreditSales.labels;
      return {
        data: [
          { ...chartForCreditSales, label: lang.credits },
          { ...chartForFreeCreditSales, label: lang.freeCredits },
        ],
        labels,
      };
    },
  },
  creditSales: {
    transform: ({ src }) => {
      return {
        text: formatNumberToMoneyWithCurrencySymbol(src.creditSales),
        value: src.creditSales,
      };
    },
  },
  freeCreditSales: {
    transform: ({ src }) => {
      return {
        text: formatNumberToMoneyWithCurrencySymbol(src.freeCreditSales),
        value: src.freeCreditSales,
      };
    },
  },
};

export const graphTopUpTotal = {
  total: {
    key: "totalAmount",
  },
  barChartData: {
    transform: ({ src }) => {
      let topUps = [];
      const others = {
        value: 0,
        label: barSetting.OTHERS.label,
        color: barSetting.OTHERS.color,
      };
      src.topUpTotals?.forEach((total) => {
        const t = barSetting[total.paymentMethod];
        if (["CREDIT", "CASH"].includes(total.paymentMethod)) {
          topUps.push({
            label: t.label,
            color: t.color,
            value: total.amount,
          });
        } else {
          others.value = others.value + Number(total.amount);
        }
      });
      topUps = others.value ? [...topUps, others] : topUps;
      return topUps;
    },
  },
  lineChartData: {
    transform: ({ src, params }) => {
      const plot = src.topUpPlots?.map((tu) => {
        const items = tu.paymentMethods
          ?.map((pm) => {
            return {
              name: ["CREDIT", "CASH"].includes(pm.paymentMethod)
                ? barSetting[pm.paymentMethod]?.label
                : barSetting.OTHERS.label,
              value: pm.amount,
            };
          })
          .filter(({ value }) => {
            return Boolean(value);
          });
        if (tu) {
          return {
            name: moment(tu.timestamp, getApiDateTimeFormatByTimeUnit(params.splitType)).format(
              getDateTimeFormatByTimeUnit(params.splitType)
            ),
            value: tu.totalAmount,
            items,
          };
        }
        return null;
      });
      return prepareChart(plot);
    },
  },
};

export const graphLineChartOfTotalCheckIns = {
  data: {
    transform: ({ src, params }) => {
      const plot = denormalizePlotDateTimeFromApiResponse(
        src.plot.map((p) => {
          const key = Object.keys(p)[0];
          return {
            [key]: p[key].newGuest,
          };
        }),
        params.splitType
      );

      return prepareChart(plot);
    },
  },
  total: {
    transform: ({ src }) => {
      return {
        text: formatNumberWithComma(src.totalGuest),
        value: src.totalGuest,
      };
    },
  },
};

export const graphLineChartOfTotalReturns = {
  data: {
    transform: ({ src, params }) => {
      const plot = denormalizePlotDateTimeFromApiResponse(src.plot, params.splitType);
      return prepareChart(plot);
    },
  },
  total: {
    transform: ({ src }) => {
      return {
        text: formatNumberToMoneyWithCurrencySymbol(src.totalSales),
        value: src.totalSales,
      };
    },
  },
};

export const viewTransactions = {
  _keys: ["location", "type", "voidReason"],
  transactionDate: {
    transform: ({ src }) => {
      return timeAgo(src.deviceUpdatedDate);
    },
  },
  isVoided: {
    transform: ({ src }) => {
      return Boolean(src.voidAuthorizationProfileId || src.voidDeviceDate);
    },
  },
  paymentMethod: {
    key: "paymentMethod",
  },
  isFailed: {
    transform: ({ src }) => {
      return Boolean(src.nfcStatus === "FAILED");
    },
  },
  registerInfo: {
    transform: ({ src }) => {
      const { deviceName, deviceSerialNumber } = src.device || {};
      return {
        shiftId: src.shiftId,
        deviceImei: src.deviceImei,
        deviceMacAddress: src.deviceMacAddress,
        deviceSerialNumber: deviceSerialNumber,
        deviceName,
      };
    },
  },
  guest: {
    transform: ({ src }) => {
      const { guest = {}, userTagUseCount = 0, userTagUid } = src;
      const { firstName, lastName, email, dob, phone, phoneCountryCode } = guest || {};
      const guestName = formatName(firstName, lastName);
      const name =
        guestName ||
        (userTagUseCount
          ? lang.populate(lang.nthGuestWhoUseThisTag, [formatNumberToOrdinal(userTagUseCount)])
          : "Unavailable");

      let postpaidCreditLimit = null;
      guest?.guestCheckins?.forEach((guestCheckin) => {
        guestCheckin.guestTags?.forEach((guestTag) => {
          postpaidCreditLimit = guestTag.postpaidCreditLimit;
        });
      });

      return {
        name,
        postpaidCreditLimit,
        tagUid: userTagUid,
        hasName: Boolean(guestName),
        email,
        phone: `${phoneCountryCode ? `+${phoneCountryCode.replace("+", "")}` : ""} ${
          phone || ""
        }`.trim(),
        dob: dob ? moment(dob).format(DateTime.A) : "",
      };
    },
  },
  previousBalance: {
    transform: ({ src }) => {
      let previousBalance = 0;
      let credits = src.userTagPreviousBalance;
      let freeCredits = src.userTagPreviousFreeCredits;

      if (credits >= 0) {
        previousBalance = credits + freeCredits;
      } else {
        previousBalance = credits;
      }

      let postPaidCreditsUsed = 0;
      if (credits < 0) {
        postPaidCreditsUsed = Math.abs(freeCredits + credits);
      }

      return [
        [
          lang.previousBalance,
          formatNumberToMoneyWithCurrencySymbol(previousBalance),
          { strongTitle: false },
          "mt-md",
          Boolean(src.nfcStatus === "FAILED")
            ? "mt-md text-gray-300"
            : previousBalance < 0
            ? "mt-md text-red"
            : "mt-md text-black",
        ],
        [
          lang.credits,
          formatNumberToMoneyWithCurrencySymbol(credits < 0 ? 0 : credits),
          {},
          "text-gray-400",
          Boolean(src.nfcStatus === "FAILED") ? "text-gray-300" : "text-gray-400",
        ],
        [
          lang.freeCredits,
          formatNumberToMoneyWithCurrencySymbol(freeCredits),
          {},
          "text-gray-400",
          Boolean(src.nfcStatus === "FAILED") ? "text-gray-300" : "text-gray-400",
        ],
        [
          lang.postPaidCreditsUsed,
          formatNumberToMoneyWithCurrencySymbol(postPaidCreditsUsed),
          {},
          "text-gray-400",
          Boolean(src.nfcStatus === "FAILED") ? "text-gray-300" : "text-gray-400",
        ],
      ];
    },
  },
  newBalance: {
    transform: ({ src }) => {
      let previousBalance = 0;
      let credits = src.userTagNewBalance;
      let freeCredits = src.userTagNewFreeCredits;
      // const isVoided = Boolean(src.voidAuthorizationProfileId);

      // if (isVoided) {
      //   credits = src.userTagPreviousBalance;
      //   freeCredits = src.userTagPreviousFreeCredits;
      // }

      if (credits >= 0) {
        previousBalance = credits + freeCredits;
      } else {
        previousBalance = credits;
      }

      let postPaidCreditsUsed = 0;
      if (credits < 0) {
        postPaidCreditsUsed = Math.abs(freeCredits + credits);
      }

      return [
        [
          lang.guestsNewBalance,
          formatNumberToMoneyWithCurrencySymbol(previousBalance),
          { strongTitle: true },
          "mt-md font-semibold",
          Boolean(src.nfcStatus === "FAILED")
            ? "mt-md text-md text-gray-300"
            : previousBalance < 0
            ? "mt-md text-md text-red"
            : "mt-md text-md text-black",
        ],
        [
          lang.credits,
          formatNumberToMoneyWithCurrencySymbol(credits < 0 ? 0 : credits),
          {},
          "text-gray-400",
          Boolean(src.nfcStatus === "FAILED") ? "text-gray-300" : "text-gray-400",
        ],
        [
          lang.freeCredits,
          formatNumberToMoneyWithCurrencySymbol(freeCredits),
          {},
          "text-gray-400",
          Boolean(src.nfcStatus === "FAILED") ? "text-gray-300" : "text-gray-400",
        ],
        [
          lang.postPaidCreditsUsed,
          formatNumberToMoneyWithCurrencySymbol(postPaidCreditsUsed),
          { superscript: src.type === TransactionType.ISSUE_FOC ? "1" : null },
          "text-gray-400",
          Boolean(src.nfcStatus === "FAILED") ? "text-gray-300" : "text-gray-400",
        ],
      ];
    },
  },
  nfcStatus: {
    transform: ({ src }) => {
      return src.nfcStatus;
    },
  },
  totalPaid: {
    transform: ({ src }) => {
      const isReturn = src.type === TransactionType.RETURN;
      const paymentMethod = src.paymentMethod;
      let label = isReturn
        ? lang.totalReturnedCredits
        : src.nfcStatus === "FAILED" && src.transactionStatus !== "VOID"
        ? lang.total
        : lang.totalPaid;
      if (src.type === TransactionType.ISSUE_FOC) {
        label = lang.totalFreeCreditsIssued;
      } else if (src.type === TransactionType.REMOVE_FOC) {
        label = lang.totalFreeCreditsRemoved;
      }

      let items = [
        [
          label,
          formatNumberToMoneyWithCurrencySymbol(src.paidAmount),
          {
            fontSize: "text-lg",
            danger: isReturn,
            strong: true,
            lineThrough: Boolean(src.voidAuthorizationProfileId),
          },
          "mt-md font-semibold text-md",
          Boolean(src.nfcStatus === "FAILED")
            ? "mt-md text-gray-300 font-semibold"
            : "mt-md text-black font-semibold",
        ],
      ];

      let remainingAmount = src.paidAmount;
      let paidCredits = 0;
      let paidFreeCredits = 0;
      let paidPostpaidCredits = 0;

      if (remainingAmount > 0 && src.userTagPreviousFreeCredits > 0) {
        if (src.userTagPreviousFreeCredits > remainingAmount) {
          paidFreeCredits = remainingAmount;
        } else {
          paidFreeCredits = src.userTagPreviousFreeCredits;
        }
        remainingAmount = remainingAmount - paidFreeCredits;
      }

      if (remainingAmount > 0) {
        if (src.userTagPreviousBalance <= 0) {
          paidCredits = 0;
          paidPostpaidCredits = remainingAmount;
        } else {
          if (src.userTagPreviousBalance > remainingAmount) {
            paidPostpaidCredits = 0;
            paidCredits = remainingAmount;
          } else {
            paidCredits = src.userTagPreviousBalance;
            remainingAmount = remainingAmount - paidCredits;
            paidPostpaidCredits = remainingAmount;
          }
        }
      }
      const isPaidByCashOrCard = [
        PaymentMethod.Cash,
        PaymentMethod.CreditDebit,
        PaymentMethod.Credit,
        PaymentMethod.Others,
      ].includes(paymentMethod);

      if (
        (src.type === TransactionType.SALE || src.type === TransactionType.MIXED) &&
        !isPaidByCashOrCard
      ) {
        items.push([
          lang.credits,
          formatNumberToMoneyWithCurrencySymbol(paidCredits),
          {
            // lineThrough: Boolean(src.voidAuthorizationProfileId),
          },
          "text-gray-400",
          Boolean(src.nfcStatus === "FAILED") ? "text-gray-300" : "text-gray-400",
        ]);

        items.push([
          lang.freeCredits,
          formatNumberToMoneyWithCurrencySymbol(paidFreeCredits),
          {
            // lineThrough: Boolean(src.voidAuthorizationProfileId),
          },
          "text-gray-400",
          Boolean(src.nfcStatus === "FAILED") ? "text-gray-300" : "text-gray-400",
        ]);

        // let postPaidCreditsUsed = 0;
        // if (src.userTagNewBalance < 0) {
        //   postPaidCreditsUsed = Math.abs(src.userTagNewFreeCredits + src.userTagNewBalance);
        // }

        items.push([
          lang.postPaidCreditsUsed,
          formatNumberToMoneyWithCurrencySymbol(paidPostpaidCredits),
          {
            // lineThrough: Boolean(src.voidAuthorizationProfileId),
          },
          "text-gray-400",
          Boolean(src.nfcStatus === "FAILED") ? "text-gray-300" : "text-gray-400",
        ]);
      }

      if (src.type === TransactionType.SALE && paymentMethod === PaymentMethod.Cash) {
        let totalCashPaid = src.paidAmount + src.cashChange;
        items.push([
          lang.cash,
          formatNumberToMoneyWithCurrencySymbol(totalCashPaid),
          "text-black",
          "text-black",
        ]);

        items.push([
          lang.change,
          formatNumberToMoneyWithCurrencySymbol(src.cashChange),
          "text-black",
          "text-black",
        ]);
      }

      if (
        src.type === TransactionType.SALE &&
        (paymentMethod === PaymentMethod.CreditDebit ||
          paymentMethod === PaymentMethod.Credit ||
          paymentMethod === PaymentMethod.Others)
      ) {
        items.push([
          paymentMethod === PaymentMethod.Others ? lang.others : lang.creditDebit,
          formatNumberToMoneyWithCurrencySymbol(src.paidAmount),
          "text-black",
          "text-black",
        ]);

        items.push([
          lang.referenceNo,
          src.referenceNumber ? src.referenceNumber : "-",
          "text-black",
          "text-black",
        ]);
      }

      return items;
    },
  },
  voidTotalPaid: {
    transform: ({ src }) => {
      const isReturn = src.type === TransactionType.RETURN;
      const paymentMethod = src.paymentMethod;
      const isPaidByCashOrCard = [
        PaymentMethod.Cash,
        PaymentMethod.CreditDebit,
        PaymentMethod.Credit,
        PaymentMethod.Others,
      ].includes(paymentMethod);
      let label = isReturn
        ? lang.totalReturnedCredits
        : src.nfcStatus === "FAILED"
        ? lang.total
        : lang.totalReturnedCredits;
      if (src.type === TransactionType.ISSUE_FOC) {
        label = lang.totalFreeCreditsIssued;
      } else if (src.type === TransactionType.REMOVE_FOC) {
        label = lang.totalFreeCreditsRemoved;
      } else if (src.type === TransactionType.SALE && isPaidByCashOrCard) {
        label = lang.totalRefunded;
      }

      let items = [
        [
          label,
          formatNumberToMoneyWithCurrencySymbol(src.paidAmount),
          {
            fontSize: "text-lg",
            danger: isReturn,
            strong: true,
            lineThrough: Boolean(src.voidAuthorizationProfileId),
          },
          "mt-md font-semibold text-md",
          Boolean(src.nfcStatus === "FAILED")
            ? "mt-md text-gray-300 font-semibold"
            : "mt-md text-black font-semibold",
        ],
      ];

      if (src.type === TransactionType.SALE && paymentMethod === PaymentMethod.Cash) {
        let totalCashPaid =
          src.transactionStatus === "VOID" ? src.paidAmount : src.paidAmount + src.cashChange;
        items.push([
          lang.cash,
          formatNumberToMoneyWithCurrencySymbol(totalCashPaid),
          "text-black",
          "text-black",
        ]);
      }

      if (
        src.type === TransactionType.SALE &&
        (paymentMethod === PaymentMethod.CreditDebit ||
          paymentMethod === PaymentMethod.Credit ||
          paymentMethod === PaymentMethod.Others)
      ) {
        items.push([
          paymentMethod === PaymentMethod.Others ? lang.others : lang.creditDebit,
          formatNumberToMoneyWithCurrencySymbol(src.paidAmount),
          "text-black",
          "text-black",
        ]);
        items.push([
          lang.referenceNo,
          src.referenceNumber ? src.referenceNumber : "-",
          "text-black",
          "text-black",
        ]);
      }

      return items;
    },
  },
  voidedNewBalance: {
    transform: ({ src }) => {
      let postPaidCreditsUsed = 0;
      if (src.voidUserTagNewBalance < 0) {
        postPaidCreditsUsed = Math.abs(src.voidUserTagNewFreeCredits + src.voidUserTagNewBalance);
      }

      let credits = src.voidUserTagNewBalance + src.voidUserTagNewFreeCredits;

      return [
        [
          lang.guestsNewBalance,
          formatNumberToMoneyWithCurrencySymbol(credits),
          "mt-md text-black-600 font-semibold",
          Boolean(src.nfcStatus === "FAILED")
            ? "mt-md text-gray-300 text-md font-semibold"
            : credits < 0
            ? "mt-md text-red text-md font-semibold"
            : "mt-md text-black text-md font-semibold",
        ],
        [
          lang.credits,
          formatNumberToMoneyWithCurrencySymbol(
            src.voidUserTagNewBalance > 0 ? src.voidUserTagNewBalance : 0
          ),
          "text-gray-400",
          Boolean(src.nfcStatus === "FAILED") ? "text-gray-300" : "text-gray-400",
        ],
        [
          lang.freeCredits,
          formatNumberToMoneyWithCurrencySymbol(
            src.voidUserTagNewFreeCredits < 0 ? 0 : src.voidUserTagNewFreeCredits
          ),
          "text-gray-400",
          Boolean(src.nfcStatus === "FAILED") ? "text-gray-300" : "text-gray-400",
        ],
        [
          lang.postPaidCreditsUsed,
          formatNumberToMoneyWithCurrencySymbol(postPaidCreditsUsed),
          "text-gray-400",
          Boolean(src.nfcStatus === "FAILED") ? "text-gray-300" : "text-gray-400",
        ],
      ];
    },
  },
  voidedPreviousBalance: {
    transform: ({ src }) => {
      let postPaidCreditsUsed = 0;
      if (src.voidUserTagPreviousBalance < 0) {
        postPaidCreditsUsed = Math.abs(
          src.voidUserTagPreviousFreeCredits + src.voidUserTagPreviousBalance
        );
      }

      let credits = src.voidUserTagPreviousBalance + src.voidUserTagPreviousFreeCredits;

      return [
        [
          lang.previousBalance,
          formatNumberToMoneyWithCurrencySymbol(credits),
          "mt-md text-black-600",
          Boolean(src.nfcStatus === "FAILED")
            ? "mt-md text-gray-300 font-semibold"
            : credits < 0
            ? "mt-md text-red font-semibold"
            : "mt-md text-black font-semibold",
        ],
        [
          lang.credits,
          formatNumberToMoneyWithCurrencySymbol(
            src.voidUserTagPreviousBalance > 0 ? src.voidUserTagPreviousBalance : 0
          ),
          "text-gray-400",
          Boolean(src.nfcStatus === "FAILED") ? "text-gray-300" : "text-gray-400",
        ],
        [
          lang.freeCredits,
          formatNumberToMoneyWithCurrencySymbol(src.voidUserTagPreviousFreeCredits),
          "text-gray-400",
          Boolean(src.nfcStatus === "FAILED") ? "text-gray-300" : "text-gray-400",
        ],
        [
          lang.postPaidCreditsUsed,
          formatNumberToMoneyWithCurrencySymbol(postPaidCreditsUsed),
          "text-gray-400",
          Boolean(src.nfcStatus === "FAILED") ? "text-gray-300" : "text-gray-400",
        ],
      ];
    },
  },
  hasItems: {
    transform: ({ src }) => {
      return Boolean(src.transactionItems?.length);
    },
  },
  items: {
    transform: ({ src }) => {
      let row = [];
      const taxes = {};
      let totalItems = 0;
      let totalDeductions = {};
      let totalTaxAmount = 0;
      let quantity = 0;
      let amount = 0;
      const cartDiscount = {};
      const itemDiscounts = [];
      const type = src.type;

      if ([TransactionType.TOPUP, TransactionType.RETURN].includes(src.type)) {
        const isReturn = src.type === TransactionType.RETURN;
        row.push({
          type: "item",
          name: isReturn ? lang.credits : lang.topupCredits,
          info: prettifyPaymentMethod(src.paymentMethod),
          amount: formatNumberToMoneyWithCurrencySymbol(src.paidAmount),
        });
      }
      if (src.type === TransactionType.ISSUE_FOC) {
        row.push({
          type: "item",
          name: lang.freeCredits,
          amount: formatNumberToMoneyWithCurrencySymbol(
            src.userTagNewFreeCredits - src.userTagPreviousFreeCredits
          ),
          size: "text-lg",
        });
      } else if (src.type === TransactionType.REMOVE_FOC) {
        row.push({
          type: "item",
          name: lang.freeCredits,
          amount: formatNumberToMoneyWithCurrencySymbol(
            src.userTagPreviousFreeCredits - src.userTagNewFreeCredits
          ),
          size: "text-lg",
        });
      }

      src.transactionItems.forEach((transaction) => {
        let totalPrice = transaction.totalAmount;
        const deductions = [];
        if (transaction.transactionVoucherItem) {
          const voucher = transaction.transactionVoucherItem;
          voucher.name = `${voucher.quantity} x ${voucher.itemName}`;
          deductions.push({ label: "Voucher", value: voucher.name });
          const voucherAmount = multiply(transaction.retailPrice, voucher.quantity);
          totalDeductions.voucher =
            totalDeductions && totalDeductions.voucher
              ? add(totalDeductions.voucher, voucherAmount)
              : voucherAmount;
          totalPrice = subtract(totalPrice, voucherAmount);
        }

        if (transaction.discountAmount) {
          const discount = {
            discountName: transaction.discountName || "-",
            discountId: transaction.discountId,
            discountIdiscountAmountd: transaction.discountId,
            discountAmount: divide(transaction.discountAmount, transaction.discountQuantity),
            totalDiscountAmount: transaction.discountAmount,
            discountPercentage: transaction.discountPercentage,
            discountType: transaction.discountType,
            discountQuantity: transaction.discountQuantity,
            discountAmountRaw: transaction.discountAmount,
          };
          if (transaction.transactionDiscountType === "CART_DISCOUNT") {
            cartDiscount[discount.discountId] = {
              ...discount,
              totalQuantity: cartDiscount[discount.discountId]
                ? add(cartDiscount[discount.discountId].discountQuantity, discount.discountQuantity)
                : discount.discountQuantity,
            };
          } else if (transaction.transactionDiscountType === "ITEM_DISCOUNT") {
            let existingDiscount = itemDiscounts.find(
              (d) =>
                d.discountId === discount.discountId && d.discountName === discount.discountName
            );
            if (existingDiscount) {
              if (existingDiscount.discountAmountRaw < discount.discountAmountRaw) {
                existingDiscount.discountAmountRaw = discount.discountAmountRaw;
              }
              if (existingDiscount.discountPercentage < discount.discountPercentage) {
                existingDiscount.discountPercentage = discount.discountPercentage;
              }
              existingDiscount.totalDiscountAmount = add(
                existingDiscount.totalDiscountAmount,
                discount.totalDiscountAmount
              );
            } else {
              itemDiscounts.push(discount);
            }
          } else {
            deductions.push({
              label: lang.discount,
              value: `${discount.discountName} ${
                discount.discountType === "PERCENTAGE"
                  ? `(${discount.discountPercentage}%)`
                  : `(${formatNumberToMoneyWithCurrencySymbol(discount.discountAmount)} off)`
              }`,
              text: `( ${discount.discountQuantity} x ${formatNumberToMoneyWithCurrencySymbol(
                discount.discountAmount
              )} )`,
            });
          }
          totalPrice = subtract(totalPrice, discount.totalDiscountAmount);
        }

        const itemRedeem = transaction.transactionVoucherItem
          ? transaction.quantity === transaction.transactionVoucherItem.quantity
          : false;
        if (transaction.taxId && type !== TransactionType.Redeemed && !itemRedeem) {
          const tax = {
            taxId: transaction.taxId,
            taxName: transaction.taxName,
            taxAmount: transaction.taxAmount,
            taxPercentage: transaction.taxPercentage,
          };
          deductions.push({
            label: lang.tax,
            value: `${tax.taxName} (${tax.taxPercentage}%)`,
            text: formatNumberToMoneyWithCurrencySymbol(tax.taxAmount),
          });
          totalTaxAmount = (Number(totalTaxAmount) + Number(tax.taxAmount)).toFixed(2);
          taxes[tax.taxId] = taxes[tax.taxId]
            ? {
                ...taxes[tax.taxId],
                amount: tax.taxAmount + taxes[tax.taxId].amount,
              }
            : {
                rate: tax.taxPercentage,
                amount: tax.taxAmount,
                name: tax.taxName,
              };
        }
        totalItems = add(totalItems, transaction.quantity);
        quantity = quantity + transaction.quantity;
        amount = amount + transaction.retailPrice;

        row.push({
          type: "productItem",
          product: {
            productId: transaction.productId,
            productName: transaction.productName || "",
            productVariants: transaction.productVariants,
            info: transaction.productSkuDeleted ? `(${lang.deleted})` : "",
            isDeleted: transaction.productSkuDeleted,
          },
          quantity: `${transaction.quantity} x ${formatNumberToMoneyWithCurrencySymbol(
            transaction.retailPrice
          )}`,
          total: formatNumberToMoneyWithCurrencySymbol(totalPrice < 0 ? 0 : totalPrice),
          originalPrice:
            totalPrice !== transaction.totalAmount
              ? formatNumberToMoneyWithCurrencySymbol(transaction.totalAmount)
              : null,
          deductions,
          hasDiscounts: transaction.discountAmount > 0,
          discounts: {
            text: transaction.discountQuantity ? lang.discount : "",
            prices: transaction.discountQuantity
              ? `${
                  transaction.discountType === "PERCENTAGE"
                    ? `${transaction.discountPercentage}%`
                    : `${formatNumberToMoneyWithCurrencySymbol(
                        divide(transaction.discountAmount, transaction.discountQuantity)
                      )}`
                }`
              : "",
            quantity: transaction.discountQuantity ? transaction.discountQuantity : "",
            discountName: transaction.discountName,
            percentage: transaction.discountQuantity
              ? `${
                  transaction.discountType === "PERCENTAGE"
                    ? `(${transaction.discountPercentage}%)`
                    : `(${formatNumberToMoneyWithCurrencySymbol(
                        divide(transaction.discountAmount, transaction.discountQuantity)
                      )})`
                }`
              : "",
            quantityTimesPrice: transaction.discountQuantity
              ? `(${transaction.discountQuantity} x ${
                  transaction.discountType === "PERCENTAGE"
                    ? `${transaction.discountPercentage}%`
                    : `${formatNumberToMoneyWithCurrencySymbol(
                        divide(transaction.discountAmount, transaction.discountQuantity)
                      )}`
                })`
              : "",
            discountAmountRaw: transaction.discountQuantity
              ? `${
                  transaction.discountType === "PERCENTAGE"
                    ? `(${transaction.discountPercentage}%)`
                    : `(${formatNumberToMoneyWithCurrencySymbol(transaction.discountAmount)})`
                }`
              : "",
          },
        });
      });

      let summaryPrices = [
        {
          value: formatNumberToMoneyWithCurrencySymbol(src.paidAmount),
        },
      ];

      if (src.paidAmount !== src.originalAmount) {
        summaryPrices.push({
          value: formatNumberToMoneyWithCurrencySymbol(src.originalAmount),
          deduction: true,
          lineThrough: src.paidAmount !== src.originalAmount,
        });
      }

      row.push({
        type: "summary",
        text: lang.subTotal,
        quantity: ` ${quantity} item${quantity > 1 ? "s" : ""}`,
        prices: summaryPrices,
        classNames: "font-semibold",
      });

      if (src.cartDiscount) {
        const d = src.cartDiscount;
        row.push({
          type: "summary",
          text: `${lang.discount} ${
            d.discountType === "PERCENTAGE"
              ? `(${d.discountPercentage}%)`
              : `(${formatNumberToMoneyWithCurrencySymbol(d.discountAmount)} off)`
          }`,
          description:
            `${d.discountName} (${formatNumberToMoneyWithCurrencySymbol(d.discountAmount)})` || "-",
          prices: [
            {
              value: `- ${formatNumberToMoneyWithCurrencySymbol(d.discountAmount)}`,
              discount: true,
            },
          ],
        });
      }

      itemDiscounts.forEach((d) => {
        row.push({
          type: "summary",
          text: `${lang.discount} ${
            d.discountType === "PERCENTAGE"
              ? `(${d.discountPercentage}%)`
              : `(${formatNumberToMoneyWithCurrencySymbol(d.discountAmountRaw)} off)`
          }`,
          description: `${d.discountName}` || "-",
          prices: [
            {
              value: `(${formatNumberToMoneyWithCurrencySymbol(d.totalDiscountAmount)})`,
            },
          ],
        });
      });

      row.push({
        type: "summary",
        text: lang.total,
        prices: [
          {
            value: formatNumberToMoneyWithCurrencySymbol(src.paidAmount),
          },
        ],
        classNames: "font-semibold",
      });

      if (Object.keys(taxes)?.length) {
        const totalTaxCount = Object.keys(taxes).length;
        row.push({
          type: "tax",
          text: lang.tax,
          description: `${totalTaxCount} Tax${totalTaxCount > 1 ? "es" : ""}`,
          value: formatNumberToMoneyWithCurrencySymbol(totalTaxAmount),
          taxes: Object.keys(taxes).map((tax) => {
            const t = taxes[tax];
            return {
              name: "",
              taxName: `${t.name} (${t.rate}%)`,
              rate: formatNumberToMoneyWithCurrencySymbol(t.amount),
            };
          }),
          classNames: "font-semibold",
        });
      }
      return row;
    },
  },
  historyTable: {
    transform: ({ src }) => {
      const { voidAuthorizationProfileId, voidAuthorizationTagUid, type, paymentMethod } =
        src || {};
      const isVoided = Boolean(voidAuthorizationProfileId || voidAuthorizationTagUid);
      const data = [];
      const isPaidByCashOrCard = [
        PaymentMethod.Cash,
        PaymentMethod.CreditDebit,
        PaymentMethod.Credit,
        PaymentMethod.Others,
      ].includes(paymentMethod);
      if (isVoided) {
        const {
          voidUserTagTapCount = 0,
          voidDeviceDate = null,
          voidStaffTagUid,
          voidAuthorizationTagUid = null,
          voidStaff = {},
          voidAuthorization = {},
        } = src;
        const { firstName, lastName } = voidStaff || {};
        const { firstName: authFirstName, lastName: authLastName } = voidAuthorization || {};
        const dateTime = timeAgo(voidDeviceDate, true);
        data.push({
          tapCount:
            isPaidByCashOrCard && src.type === TransactionType.SALE ? (
              <Text color="text-gray">{lang.none}</Text>
            ) : (
              formatNumberToOrdinal(voidUserTagTapCount)
            ),
          dateTime: {
            date: dateTime.date || "-",
            time: dateTime.time || "-",
          },
          staff: {
            name: !isPaidByCashOrCard
              ? formatName(firstName, lastName)
              : formatName(authFirstName, authLastName) || "-",
            tagUid: !isPaidByCashOrCard ? voidStaffTagUid : voidAuthorizationTagUid,
          },
          authorization: {
            name: formatName(authFirstName, authLastName) || "-",
            tagUid: voidAuthorizationTagUid,
          },
          status:
            (src.nfcStatus === "FAILED" ? lang.failed : "") +
            " " +
            prettifyTransactionType(src.type) +
            " " +
            lang.voided,
        });
      }
      const { userTagTapCount = 0, deviceCreatedDate, staff = {}, staffTagUid } = src;
      const { firstName, lastName } = staff || {};
      const d = timeAgo(deviceCreatedDate, true);
      const dateTime = {
        date: d.date || "-",
        time: d.time || "-",
      };
      const staffName = {
        name: formatName(firstName, lastName) || "-",
        tagUid: staffTagUid,
      };
      const historySale = {
        tapCount:
          isPaidByCashOrCard && src.type === TransactionType.SALE ? (
            <Text color="text-gray">{lang.none}</Text>
          ) : (
            formatNumberToOrdinal(userTagTapCount)
          ),
        dateTime,
        staff: staffName,
      };
      if (type === TransactionType.MIXED) {
        data.push({
          ...historySale,
          status:
            (src.nfcStatus === "FAILED" ? lang.failed : "") +
            " " +
            prettifyTransactionType(TransactionType.SALE),
        });
        data.push({
          ...historySale,
          status:
            (src.nfcStatus === "FAILED" ? lang.failed : "") +
            " " +
            prettifyTransactionType(TransactionType.REDEEM),
        });
      } else {
        data.push({
          ...historySale,
          status:
            (src.nfcStatus === "FAILED" ? lang.failed : "") + " " + prettifyTransactionType(type),
        });
      }

      return {
        data,
        columns: [
          {
            text: lang.tapCount,
            key: "tapCount",
          },
          {
            text: lang.date,
            key: "dateTime",
            custom: true,
          },
          {
            text: lang.staff,
            key: "staff",
            custom: true,
          },
          // ...(isVoided
          //   ? [
          //       {
          //         text: lang.voidAuthorization,
          //         key: "authorization",
          //         custom: true,
          //       },
          //     ]
          //   : []),
          {
            text: lang.type,
            key: "status",
          },
        ],
      };
    },
  },
};
