import { getGlobalDateTimeFilter } from "services";

export const shiftListFilterState = (venueId, eod) => {
  const dateTime = getGlobalDateTimeFilter();
  return {
    venueId,
    page: 1,
    pageSize: 20,
    searchKey: "",
    dateRange: [dateTime[0], dateTime[1]],
    locations: [],
    staffs: [],
    devices: [],
    sort: { key: "end", value: "desc" },
    eod: eod,
  };
};
