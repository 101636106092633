import { getGlobalDateTimeFilter } from "services";

export const dashboardFilterState = (venueId) => {
  const dateTime = getGlobalDateTimeFilter();
  return {
    venueId,
    searchKey: "",
    status: "",
    pageable: true,
    page: 1,
    pageSize: 20,
    dateRange: dateTime,
    locationIds: [],
  };
};
