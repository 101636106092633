import { DateTime, TransactionType, VoucherType } from "enums";
import { Path } from "paths";
import {
  addQueryParam,
  formatDate,
  formatName,
  formatNumberToOrdinal,
  mapObject,
  prettifyStaffRole,
} from "services";
import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import { formatNumberWithComma, subtract } from "services/number.service";
import {
  dateTimeRequest,
  dateToTimeAgo,
  filterRequest,
  getCurrentTimeWithTimezone,
  selectToObjectRequest,
} from "./common.mapper";
import { location } from "./location.mapper";
import lang from "translations";

export const shiftListResponse = {
  id: { key: "shiftId" },
  deviceName: {
    transform: ({ src }) => {
      return src.device?.deviceName;
    },
  },
  deviceImei: { key: "deviceImei" },
  deviceSerialNumber: { key: "deviceSerialNumber" },
  staffName: {
    transform: ({ src }) => {
      if (src.staffProfile) {
        const { firstName, lastName } = src.staffProfile;
        const name = formatName(firstName, lastName);
        if (name.length > 0) {
          return name;
        }
      } else {
        return "Unavailable";
      }
      // return src
    },
  },
  staffProfileId: {
    transform: ({ src }) => {
      if (src.staffProfile) {
        return src.staffProfile.profileId;
      }
    },
  },
  start: dateToTimeAgo("openDate"),
  end: dateToTimeAgo("closeDate"),
  startDateTime: {
    transform: ({ src }) => {
      return src.openDate?.replaceAll("+", encodeURIComponent("+"));
    },
  },
  endDateTime: {
    transform: ({ src }) => {
      return src.closeDate?.replaceAll("+", encodeURIComponent("+"));
    },
  },
  location: {
    transform: ({ src }) => {
      return src.location?.locationName || "-";
    },
  },
  locationId: {
    key: "locationId",
  },
  transactions: { key: "totalTransactions" },
};

export const shiftListFilterRequest = {
  ...filterRequest,
  ...dateTimeRequest,
  transactionTypes: [TransactionType.TOPUP, TransactionType.SALE, TransactionType.RETURN],
  transactionVoucherTypes: [VoucherType.Issue, VoucherType.Usage],
  locationIds: selectToObjectRequest("locations"),
  staffIds: selectToObjectRequest("staffs"),
  deviceIds: selectToObjectRequest("devices"),
  orders: {
    transform: ({ src }) => {
      return { [`s.closeDate`]: src.sort.value };
    },
  },
  eod: { key: "eod" },
  requestedAt: getCurrentTimeWithTimezone(),
};

export const viewShiftResponse = {
  location: {
    transform: ({ src }) => {
      return src.location ? mapObject(src.location, location) : {};
    },
  },
  shift: {
    transform: ({ src }) => {
      const { openDate, closeDate } = src || {};
      return `${formatDate(openDate, DateTime.G)} - ${
        closeDate ? formatDate(closeDate, DateTime.G) : "Now"
      }`;
    },
  },
  redirectUrl: {
    transform: ({ src }) => {
      const { openDate, closeDate, shiftId } = src || {};
      return addQueryParam(Path.TRANSACTION, {
        _startDateTime: openDate,
        _endDateTime: closeDate,
        shifts: shiftId,
      });
    },
  },
  summary: {
    transform: ({ src }) => {
      const {
        expectedCloseBalance = 0,
        closeBalance = 0,
        openDate,
        closeDate,
        notes,
        totalTransactions,
      } = src;
      const discrepancy = subtract(closeBalance, expectedCloseBalance);
      const amounts = {
        ...src,
        discrepancy,
      };
      const obj = {};
      const amountKeys = [
        "totalSale",
        "totalTax",
        "totalDiscount",
        "totalVoidValue",
        "totalVoidValuePouchPay",
        "totalVoidValueCash",
        "totalVoidValueCard",
        "totalVoidValueOthers",
        "totalVouchersRedeemedRetailValue",
        "totalVouchersRedeemedSupplyValue",
        "openBalance",
        "expectedCloseBalance",
        "closeBalance",
        "discrepancy",
        "totalTopup",
        "totalRefunded",
        "totalFreeCreditsUsed",
        "totalCreditsUsed",
        "totalPostpaidUsed",
        "totalSaleCash",
        "totalSaleCard",
        "totalSaleOthers",
        "totalFreeCreditsIssued",
        "totalFreeCreditsRemoved",
      ];

      obj.topUpMethodTotals = {
        cash: formatNumberToMoneyWithCurrencySymbol(src.totalTopupCash.toFixed(2)),
        credit: formatNumberToMoneyWithCurrencySymbol(src.totalTopupCredit.toFixed(2)),
        other: formatNumberToMoneyWithCurrencySymbol(src.totalTopupOther.toFixed(2)),
      };

      amountKeys.forEach((key) => {
        obj[key] = formatNumberToMoneyWithCurrencySymbol(
          amounts[key] ? amounts[key].toFixed(2) : 0
        );
      });

      obj.hasDiscrepancy = Boolean(discrepancy);
      obj.hasTransactions = Boolean(totalTransactions);

      const numberKeys = [
        "totalTransactions",
        "totalGuestsCheckedIn",
        "totalGuestsCheckedOut",
        "totalReturnedTag",
        "totalClearedTag",
        "totalIssuedTag",
        "totalVoidedItems",
        "totalVouchersIssued",
        "totalVouchersRedeemed",
        "totalVouchersRemoved",
      ];

      numberKeys.forEach((key) => {
        obj[key] = formatNumberWithComma(amounts[key]);
      });

      obj.shiftStart = formatDate(openDate, DateTime.G);
      obj.shiftEnd = closeDate ? formatDate(closeDate, DateTime.G) : null;
      obj.notes = notes;
      return obj;
    },
  },
  staff: {
    transform: ({ src = {} }) => {
      const { firstName, lastName, profileNfcTags = [], roles = [] } = src.staffProfile || {};
      return {
        name:
          formatName(firstName, lastName) ||
          lang.populate(lang.nthStaffWhoUsedThisTag, [
            formatNumberToOrdinal(src.openedByStaffTagUseCount || 0),
          ]),
        tagUid: src.openedByStaffTagUid || profileNfcTags?.[0]?.tagUid || lang.noTagUid,
        hasTagUid: Boolean(src.openedByStaffTagUid || profileNfcTags?.[0]?.tagUid),
        roles: roles.map(({ role }) => {
          return prettifyStaffRole(role);
        }),
      };
    },
  },
  deviceInfo: {
    transform: ({ src }) => {
      const { deviceName } = src.device || {};
      return {
        shiftId: src.shiftId,
        deviceImei: src.deviceImei,
        deviceMacAddress: src.deviceMacAddress,
        deviceSerialNumber: src.deviceSerialNumber,
        deviceName,
      };
    },
  },
};
