import React from "react";
import { Image, UserNav, UserNavB, VerticalLine } from "components/commons";
import { PouchnationLogo } from "images";
import { redirectTo } from "services";
import classNames from "classnames";
import styles from "./page-header.module.scss";
// import { useRouter } from "hooks";
import { mixpanel, TrackEvent } from "mixpanel";
import ProcessStatus from "./process-status";
import lang from "translations";
import LangSelector from "./lang-selector";
import SupportLinkDropdown from "../support-link-dropdown/support-link-dropdown";

const PageHeader = ({
  logoRedirectUrl,
  hasUserNav = true,
  notSubscribed = true,
  hasProcessingStatus = false,
  hasSupportLinks = false,
  // hasNotificationBell = false,
}) => {
  return (
    <div
      className={classNames(
        "bg-washed h-15 min-h-15 z-20 w-full flex items-center justify-between fixed",
        styles.container
      )}
    >
      <div className="flex flex-row items-center">
        <div className="px-4 md:px-8 ">
          <Image
            className="cursor-pointer max-w-r10 sm:max-w-r12"
            src={PouchnationLogo}
            onClick={() => {
              mixpanel.track(TrackEvent.ClickedButton, {
                button: lang.pouchLogo,
              });
              if (logoRedirectUrl) {
                redirectTo(logoRedirectUrl);
              } else {
                redirectTo("/");
              }
            }}
          />{" "}
        </div>
        <div>
          <LangSelector />
        </div>
      </div>
      <div className="flex items-center">
        {hasProcessingStatus && (
          <div className="col-end-1 mx-xs md:col-start-1 ">
            <ProcessStatus />
          </div>
        )}
        {/* {hasNotificationBell && (
          <div className="col-end-1 mx-xs md:col-start-1 ">
            <NotificationBell />
          </div>
        )} */}

        {hasSupportLinks && (
          <div className="col-end-1 mx-xs md:col-start-1 ">
            <SupportLinkDropdown />
          </div>
        )}

        <div className="ml-sm col-end-2 md:col-start-2">
          <VerticalLine />
        </div>
        <div className="col-end-4 md:col-end-4">
          {hasUserNav}
          {notSubscribed ? <UserNav /> : <UserNavB />}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
