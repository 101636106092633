import React, { useState, useMemo } from "react";
import { Text, Dropdown, Icon } from "components/commons";
import { useMount } from "hooks";
import lang from "translations";
import { UserLanguage } from "enums";
import { redirectTo } from "services";
import classnames from "classnames";
const LangSelector = () => {
  const { _language } = lang;
  const [active, setActive] = useState(_language);

  const languagePack = [
    {
      text: "EN - English",
      key: "en",
    },
    {
      text: "ES - Spanish",
      key: "es",
    },
    {
      text: "PT - Portuguese",
      key: "pt",
    },
    {
      text: "TH - Thai",
      key: "th",
    },
  ];

  const initLanguage = useMemo(() => {
    if (_language === "en") {
      setActive("en");
      return UserLanguage.en;
    } else if (_language === "es") {
      setActive("es");
      return UserLanguage.es;
    } else if (_language === "pt") {
      setActive("pt");
      return UserLanguage.pt;
    } else if (_language === "th") {
      setActive("th");
      return UserLanguage.th;
    }
  }, [_language]);

  const [language, setLocale] = useState(initLanguage);

  useMount(() => {
    localStorage.setItem("locale", _language);
  });

  const languageOptions = useMemo(() => {
    return languagePack.map((language) => {
      return {
        ...language,
        text: (
          <Text
            size="text-base"
            className={classnames(
              "hover:text-pelorous",
              "leading-6",
              "w-28",
              active === language.key ? "text-pelorous" : "text-gray"
            )}
          >
            {language.text}
          </Text>
        ),
        value: language.text,
        onClick: () => {
          setLocale(language.value);
          setActive(language.key);
          const query = new URLSearchParams(window.location.search);
          query.delete("lang");
          const currentPath = window.location.pathname;
          localStorage.setItem("locale", language.key);
          redirectTo(`${currentPath}${query.toString() === "" ? "" : `?${query.toString()}`} `);
        },
      };
    });
    // eslint-disable-next-line
  }, [active]);

  return (
    <Dropdown
      trigger="click"
      placement="topLeft"
      className="-mr-1 cursor-pointer text-xs"
      options={languageOptions}
    >
      <div className="flex items-center justify-start">
        <Text size="text-sm">{language}</Text>
        <Icon name="sort-descending" className="ml-0.5 mb-2.5" fontSize="12px" />
      </div>
    </Dropdown>
  );
};

export default LangSelector;
