import React, { useCallback, useContext } from "react";
import { LayoutA } from "components/layouts";
import { Icon, Panel, Skeleton, Text, Title } from "components/commons";
import { ProfileContext, VenuesContext } from "contexts";
import { RightOutlined } from "@ant-design/icons";
import styles from "./venue-select.module.scss";
import classnames from "classnames";
import { redirectTo } from "services";
import lang from "translations";
import { Path } from "paths";
import { mixpanel, TrackEvent } from "mixpanel";

const VenueSelect = () => {
  const { venues, loading } = useContext(VenuesContext);
  const { profile } = useContext(ProfileContext);

  const selectVenueCb = useCallback((v) => {
    const { venueId } = v;
    localStorage.setItem("venueId", venueId);
    redirectTo("/");
  }, []);

  return (
    <LayoutA logoRedirectUrl={Path.VENUE_SELECT}>
      {venues.length === 0 ? (
        <div className="w-full h-full flex items-center">
          <Icon loading className="m-auto" fontSize="30px" />
        </div>
      ) : (
        <div className="w-full flex flex-col items-center justify-center pt-20">
          <Title className="mb-md" xl>
            {lang.hello} {profile.firstName}
          </Title>
          <Text size="text-md" className="mb-xl">
            {lang.chooseAVenue}
          </Text>
          <Panel className="w-full max-w-md overflow-auto">
            {loading && <Skeleton />}
            {venues.map((venue) => {
              const { venueId, venueName } = venue;

              return (
                <div
                  key={venueId}
                  className={classnames(
                    "first:pt-0 last:pb-0 last:border-0 flex items-center justify-between border-b py-md px-lg -mx-lg cursor-pointer",
                    styles.venueRow
                  )}
                  onClick={() => {
                    mixpanel.track(TrackEvent.ClickedButton, {
                      Button: lang.selectVenue,
                    });
                    selectVenueCb(venue);
                  }}
                >
                  <div>{venueName}</div>
                  <RightOutlined style={{ color: "#0a3637" }} className="mr-md" />
                </div>
              );
            })}
          </Panel>
        </div>
      )}
    </LayoutA>
  );
};

export default VenueSelect;
