import { BookingItemType, DateTime, IntegrationType } from "enums";
import { dateTimeRequest, filterRequest, selectToObjectRequest } from "mappers";
// import moment from "moment";
import { formatDate } from "services";
import { getNightsCount } from "services/date.service";

export const bookingDetailsResponse = {
  bookingNumber: { key: "bookingNumber" },
  bookingId: { key: "bookingId" },
  startTime: {
    transform: ({ src }) => {
      if (src.source === IntegrationType.CLOUDBEDS) {
        return formatDate(src.startTime, DateTime.A);
      }
      return formatDate(src.startTime, DateTime.H);
    },
  },
  endTime: {
    transform: ({ src }) => {
      if (src.source === IntegrationType.CLOUDBEDS) {
        return formatDate(src.endTime.split("T")[0], DateTime.A);
      }
      return formatDate(src.endTime, DateTime.H);
    },
  },
  bookedAt: { key: "bookedAt" },
  bookingItems: {
    transform: ({ src }) => {
      return src.bookingItems.sort((a, b) => (a.type > b.type ? 1 : -1));
    },
  },
  totalBillAmount: {
    transform: ({ src }) => {
      if (src.bookingItems && src.bookingItems.length > 0) {
        var amount = 0;
        src.bookingItems.forEach((bookingItem) => {
          amount = amount + bookingItem.totalAmount;
        });
        return amount;
      } else {
        return 0;
      }
    },
  },
  currency: {
    transform: ({ src }) => {
      if (src.bookingItems && src.bookingItems.length > 0 && src.bookingItems[0].currency) {
        return src.bookingItems[0].currency;
      } else {
        return "";
      }
    },
  },
  bookedByCustomer: {
    transform: ({ src }) => {
      if (src.bookingCustomers) {
        const booker = src.bookingCustomers?.filter(function (e) {
          return e.isBooker;
        });
        if (booker && booker.length > 0) {
          return booker[0].customer;
        } else {
          const owner = src.bookingCustomers?.filter(function (e) {
            return e.isOwner;
          });
          if (owner && owner.length > 0) {
            return owner[0].customer;
          }
        }
      }
      return null;
    },
  },
  bookedBy: {
    transform: ({ src }) => {
      if (src.bookingCustomers) {
        const booker = src.bookingCustomers?.filter(function (e) {
          return e.isBooker;
        });
        if (booker && booker.length > 0) {
          var name = "";
          if (booker[0].customer.lastName) {
            name = booker[0].customer.lastName;
          }
          if (booker[0].customer.firstName) {
            name = booker[0].customer.firstName + " " + name;
          }
          return name;
        } else {
          const owner = src.bookingCustomers?.filter(function (e) {
            return e.isOwner;
          });
          if (owner && owner.length > 0) {
            var ownerName = "";
            if (owner[0].customer.lastName) {
              ownerName = owner[0].customer.lastName;
            }
            if (owner[0].customer.firstName) {
              ownerName = owner[0].customer.firstName + " " + ownerName;
            }
            return ownerName;
          }
        }
      }
      return null;
    },
  },
  bookedOn: {
    transform: ({ src }) => {
      return src.bookedOn ? formatDate(src.bookedOn, DateTime.D) : "-";
    },
  },
  company: {
    transform: ({ src }) => {
      return !src.company || src.company === "" ? "-" : src.company;
    },
  },
  mobileNumber: {
    transform: ({ src }) => {
      if (!src.countryCode || !src.mobileNumber) {
        return "-";
      }
      return `(${src.countryCode}) ${src.mobileNumber}`;
    },
  },
  notes: {
    transform: ({ src }) => {
      return src.bookingNotes;
    },
  },
  noOfGuests: {
    transform: ({ src }) => {
      return src.adultCount + src.childCount;
    },
  },
  noOfNights: {
    transform: ({ src }) => {
      let count = 0;
      let endTime = src.endTime.split("T")[0];
      if (src.source === IntegrationType.MEWS && src.bookingItems) {
        let placeBookings = src.bookingItems.filter(
          (bookingItem) => BookingItemType.PLACE === bookingItem.type
        );
        if (placeBookings.length > 0) {
          count = placeBookings[0].quantity;
        }
        if (count > 0) {
          return count;
        }
      }

      return getNightsCount(src.startTime, endTime);
    },
  },
  reasonsForStay: { key: "reasonsForStay" },
  status: { key: "status" },
  integrationName: { key: "source" },
  customers: {
    transform: ({ src }) => {
      if (src.bookingCustomers) {
        let array1 = src.bookingCustomers
          .filter((bookingCustomer) => {
            return bookingCustomer.isOwner;
          })
          .map((bookingCustomer) => {
            var name = "";
            if (bookingCustomer.customer.lastName) {
              name = bookingCustomer.customer.lastName;
            }
            if (bookingCustomer.customer.firstName) {
              name = bookingCustomer.customer.firstName + " " + name;
            }
            if (!name && bookingCustomer.tagUid) {
              name = bookingCustomer.tagUid + " (" + bookingCustomer.useCount + ")";
            }

            return {
              firstName: bookingCustomer.customer.firstName,
              lastName: bookingCustomer.customer.lastName,
              name: name,
              isOwner: bookingCustomer.isOwner,
              checkedInAt: bookingCustomer.checkedInAt,
            };
          });

        let array2 = src.bookingCustomers
          .filter((bookingCustomer) => {
            return !bookingCustomer.isBooker && !bookingCustomer.isOwner;
          })
          .map((bookingCustomer) => {
            var name = "";
            if (bookingCustomer.customer.lastName) {
              name = bookingCustomer.customer.lastName;
            }
            if (bookingCustomer.customer.firstName) {
              name = bookingCustomer.customer.firstName + " " + name;
            }
            if (!name && bookingCustomer.tagUid) {
              name = bookingCustomer.tagUid + " (" + bookingCustomer.useCount + ")";
            }
            return {
              firstName: bookingCustomer.customer.firstName,
              lastName: bookingCustomer.customer.lastName,
              name: name,
              isOwner: bookingCustomer.isOwner,
              checkedInAt: bookingCustomer.checkedInAt,
            };
          })
          .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0));

        return array1.concat(array2);
      } else {
        return [];
      }
    },
  },
  accommodations: {
    transform: ({ src }) => {
      if (src.bookingItems) {
        return src.bookingItems.filter((bookingItem) => BookingItemType.PLACE === bookingItem.type);
      } else {
        return [];
      }
    },
  },
  products: {
    transform: ({ src }) => {
      if (src.bookingItems) {
        return src.bookingItems.filter(
          (bookingItem) => BookingItemType.PRODUCT === bookingItem.type
        );
      } else {
        return [];
      }
    },
  },
};

export const bookingSummaryResponse = {
  items: { key: "items" },
  paymentMethod: { key: "paymentMethod" },
  paymentStatus: { key: "paymentStatus" },
  totalBillAmount: { key: "totalBillAmount" },
};

// export const bookingDetailsHeader = {
//   bookingId: { key: "bookingId" },
//   bookedBy: { key: "bookedBy" },
//   bookedOn: { key: "bookedOn" },
//   integrationName: { key: "integrationName" },
//   status: {
//     transform: ({ src }) => {
//       return "CHECKED_IN";
//     },
//   },
// };

export const bookingListResponse = {
  id: { key: "id" },
  bookingNumber: { key: "bookingNumber" },
  source: {
    transform: ({ src }) => {
      return src.source;
    },
  },
  startTime: {
    transform: ({ src }) => {
      if (src.source === IntegrationType.CLOUDBEDS) {
        return src.startTime.split("T")[0];
      } else {
        return src.startTime;
      }
    },
  },
  endTime: {
    transform: ({ src }) => {
      if (src.source === IntegrationType.CLOUDBEDS) {
        return src.endTime.split("T")[0];
      } else {
        return src.endTime;
      }
    },
  },
  bookedBy: {
    transform: ({ src }) => {
      if (src.bookingCustomers) {
        const booker = src.bookingCustomers?.filter(function (e) {
          return e.isBooker;
        });
        if (booker && booker.length > 0) {
          var name = "";
          if (booker[0].customer.lastName) {
            name = booker[0].customer.lastName;
          }
          if (booker[0].customer.firstName) {
            name = booker[0].customer.firstName + " " + name;
          }
          return name;
        } else {
          const owner = src.bookingCustomers?.filter(function (e) {
            return e.isOwner;
          });
          if (owner && owner.length > 0) {
            var ownerName = "";
            if (owner[0].customer.lastName) {
              ownerName = owner[0].customer.lastName;
            }
            if (owner[0].customer.firstName) {
              ownerName = owner[0].customer.firstName + " " + ownerName;
            }
            return ownerName;
          }
        }
      }
      return "";
    },
  },
  emailAddress: {
    transform: ({ src }) => {
      if (src.bookingCustomers) {
        const booker = src.bookingCustomers?.filter(function (e) {
          return e.isBooker;
        });
        if (booker && booker.length > 0) {
          return booker[0].customer.email;
        } else {
          const owner = src.bookingCustomers?.filter(function (e) {
            return e.isOwner;
          });
          if (owner && owner.length > 0) {
            return owner[0].customer.email;
          }
        }
      }
      return "";
    },
  },
  noOfGuests: {
    transform: ({ src }) => {
      return src.adultCount + src.childCount;
    },
  },
  noOfNights: {
    transform: ({ src }) => {
      let count = 0;
      if (src.source === IntegrationType.MEWS && src.bookingItems) {
        let placeBookings = src.bookingItems.filter(
          (bookingItem) => BookingItemType.PLACE === bookingItem.type
        );
        if (placeBookings.length > 0) {
          count = placeBookings[0].quantity;
        }
        if (count > 0) {
          return count;
        }
      }

      return getNightsCount(src.startTime, src.endTime);
    },
  },
  status: { key: "status" },
  rawStatus: { key: "rawStatus" },
  noOfCheckedIn: { key: "checkedInGuest" },
  noOfCheckedOut: { key: "checkedOutGuest" },
};

export const bookingListFilterRequest = {
  ...filterRequest,
  ...dateTimeRequest,
  status: selectToObjectRequest("statusList"),
  orders: {
    endTime: "DESC",
  },
};
