import { GuestStatus, CreditStatus } from "enums";
import { getGlobalDateTimeFilter } from "services";
import lang from "translations";

export const statusFilter = [
  { text: lang.all, value: null },
  { text: lang.checkedIn, value: GuestStatus.CHECKED_IN },
  { text: lang.checkedOut, value: GuestStatus.CHECKED_OUT },
];

export const creditFilter = [
  { text: lang.all, value: null },
  { text: lang.noRemainingBalance, value: CreditStatus.NO_REMAINING_BALANCE },
  { text: lang.withRemainingBalance, value: CreditStatus.WITH_REMAINING_BALANCE },
  { text: lang.withPendingPayments, value: CreditStatus.WITH_PENDING_PAYMENTS },
];

export const guestListFilterState = (venueId) => {
  const dateTime = getGlobalDateTimeFilter();
  return {
    venueId,
    searchKey: "",
    status: "",
    pageable: true,
    page: 1,
    pageSize: 20,
    staffs: [],
    guestProfiles: [],
    dateRange: [dateTime[0], dateTime[1]],
    guestTagStatus: null,
    creditStatus: null,
  };
};
